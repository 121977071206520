enum EStatus {
    All = 0,
    New = 1,
    UnderConsideration = 2,
    UnderRevision = 3,
    Corrected = 4,
    Agreed = 5,
    Rejected = 6,
    UserCanceled = 7,
}

const statusMap = new Map<EStatus, string>([
    [EStatus.All, 'Все'],
    [EStatus.New, 'Новая'],
    [EStatus.UnderConsideration, 'На рассмотрении'],
    [EStatus.UnderRevision, 'На доработке'],
    [EStatus.Corrected, 'Исправлена'],
    [EStatus.Agreed, 'Согласована'],
    [EStatus.Rejected, 'Отклонено'],
    [EStatus.UserCanceled, 'Отменена пользователем'],
])

enum EEnergyType {
    All = 0,
    SolarEnergy = 1,
    WindEnergy = 2,
    WaterEnergy = 3,
    TidalEnergy = 4,
    WavePowerStation = 5,
    GeothermalPowerStation = 6,
    NpPowerStation = 7,
    Biomass = 8,
    Biogas = 9,
    SvGas = 10,
    UgGas = 11,
    MSZ = 12,
    EnergyAtom = 13,
    Coal = 14,
    Mazout = 15,
    Diesel = 16,
    NaturalGas = 17,
}

const energyTypeMap = new Map<EEnergyType, string>([
    [EEnergyType.All, 'Все'],
    [EEnergyType.SolarEnergy, 'Энергия солнца'],
    [EEnergyType.WindEnergy, 'Энергия ветра'],
    [EEnergyType.WaterEnergy, 'Энергия воды'],
    [EEnergyType.TidalEnergy, 'Энергия приливов'],
    [EEnergyType.WavePowerStation, 'ВолнЭС'],
    [EEnergyType.GeothermalPowerStation, 'ГеоТЭС'],
    [EEnergyType.NpPowerStation, 'НпТЭС'],
    [EEnergyType.Biomass, 'Биомасса'],
    [EEnergyType.Biogas, 'Биогаз'],
    [EEnergyType.SvGas, 'Свгаз'],
    [EEnergyType.UgGas, 'Уггаз'],
    [EEnergyType.MSZ, 'МС3'],
    [EEnergyType.EnergyAtom, 'Энергия атома'],
    [EEnergyType.Coal, 'Уголь'],
    [EEnergyType.Mazout, 'Мазут'],
    [EEnergyType.Diesel, 'Дизель'],
    [EEnergyType.NaturalGas, 'Природный газ'],
])

enum EActionType {
    All = 0,
    Edit = 1,
    ChangeRole = 2,
    ContinueRegistration = 3,
}

const actionTypeMap = new Map<EActionType, string>([
    [EActionType.All, 'Все'],
    [EActionType.Edit, 'Редактирование'],
    [EActionType.ChangeRole, 'Смена роли'],
    [EActionType.ContinueRegistration, 'Регистрация'],
])

enum EUserRole {
    All = 0,
    UserWithouPA = 1,
    Client = 2,
    GOOwner = 3,
    TSO = 4,
    Operator = 5,
    Administrator = 6,
    SuperAdmin = 7,
}

export const isUserAdmin = (role: number) =>
    [EUserRole.Administrator, EUserRole.Operator, EUserRole.Operator].includes(role)

const userRoleMap = new Map<EUserRole, string>([
    [EUserRole.All, 'Все'],
    [EUserRole.UserWithouPA, 'Пользователь без ЛС'],
    [EUserRole.Client, 'Клиент'],
    [EUserRole.GOOwner, 'Владелец ГО'],
    [EUserRole.TSO, 'ТСО/ГП'],
    [EUserRole.Operator, 'Оператор'],
    [EUserRole.Administrator, 'Администратор'],
    [EUserRole.SuperAdmin, 'Суперадмин'],
])

enum ECitizenshipType {
    RF = 1,
    Other = 2,
}

const citizenshipTypeMap = new Map<ECitizenshipType, string>([
    [ECitizenshipType.RF, 'РФ'],
    [ECitizenshipType.Other, 'другое'],
])

enum EDocumentType {
    RF = 1,
    Other = 2,
}

const documentTypeMap = new Map<EDocumentType, string>([
    [EDocumentType.RF, 'паспорт РФ'],
    [EDocumentType.Other, 'другой'],
])

enum EApproveType {
    Approve = 1,
    SendRevision = 2,
    Canceled = 3,
}

enum EUserType {
    FizLico = 0,
    IP = 1,
    UrLico = 2,
}

enum EGoStatusType {
    All = 0,
    Blocked = 1,
    NotBlocked = 2,
}

const goStatusTypeMap = new Map<EGoStatusType, string>([
    [EGoStatusType.All, 'Все'],
    [EGoStatusType.Blocked, 'Заблокированные'],
    [EGoStatusType.NotBlocked, 'Не заблокированные'],
])

enum EGoType {
    All = 0,
    KGO = 1,
    ZGO = 2,
}

const goTypeMap = new Map<EGoType, string>([
    [EGoType.All, 'Любой'],
    [EGoType.KGO, 'КГО'],
    [EGoType.ZGO, 'ЗГО'],
])

enum ELocationType {
    All = 0,
    RepublicAdygeya = 1,
    RepublicBashkortostan = 2,
    RepublicBuryatia = 3,
    RepublicAltai = 4,
    RepublicDaghestan = 5,
    RepublicIngushetia = 6,
    KabardinoBalkarianRepublic = 7,
    RepublicKalmykia = 8,
    KarachayevoCircassianRepublic = 9,
    RepublicKarelia = 10,
    KomiRepublic = 11,
    RepublicMariEl = 12,
    RepublicMordovia = 13,
    RepublicSakha = 14,
    RepublicNorthOssetia = 15,
    RepublicTatarstan = 16,
    RepublicTuva = 17,
    UdmurtianRepublic = 18,
    RepublicKhakassia = 19,
    ChechenRepublic = 20,
    ChuvashRepublic = 21,
    AltaiTerritory = 22,
    KrasnodarTerritory = 23,
    KrasnoyarskTerritory = 24,
    PrimoryeTerritory = 25,
    StavropolTerritory = 26,
    KhabarovskTerritory = 27,
    AmurRegion = 28,
    ArkhangelskRegion = 29,
    AstrakhanRegion = 30,
    BelgorodRegion = 31,
    BryanskRegion = 32,
    VladimirRegion = 33,
    VolgogradRegion = 34,
    VologdaRegion = 35,
    VoronezhRegion = 36,
    IvanovoRegion = 37,
    IrkutskRegion = 38,
    KaliningradRegion = 39,
    KalugaRegion = 40,
    KamchatkaTerritory = 41,
    KemerovoRegion = 42,
    KirovRegion = 43,
    KostromaRegion = 44,
    KurganRegion = 45,
    KurskRegion = 46,
    LeningradRegion = 47,
    LipetskRegion = 48,
    MagadanRegion = 49,
    MoscowRegion = 50,
    MurmanskRegion = 51,
    NizhnyNovgorodRegion = 52,
    NovgorodRegion = 53,
    NovosibirskRegion = 54,
    OmskRegion = 55,
    OrenburgRegion = 56,
    OrelRegion = 57,
    PenzaRegion = 58,
    PermTerritory = 59,
    PskovRegion = 60,
    RostovRegion = 61,
    RyazanRegion = 62,
    SamaraRegion = 63,
    SaratovRegion = 64,
    SakhalinRegion = 65,
    SverdlovskRegion = 66,
    SmolenskRegion = 67,
    TambovRegion = 68,
    TverRegion = 69,
    TomskRegion = 70,
    TulaRegion = 71,
    TyumenRegion = 72,
    UlyanovskRegion = 73,
    ChelyabinskRegion = 74,
    TransBaikalTerritory = 75,
    YaroslavlRegion = 76,
    Moscow = 77,
    StPetersburg = 78,
    JewisAutonomousRegion = 79,
    NenetsAutonomousArea = 83,
    KhantyMansiAutonomousArea = 86,
    ChukotkaAutonomousArea = 87,
    YamalNenetsAutonomousArea = 89,
    RepublicCrimea = 91,
    Sevastopol = 92,
}

const locationTypeMap = new Map<ELocationType, string>([
    [ELocationType.All, 'Любой'],
    [ELocationType.RepublicAdygeya, 'Республика Адыгея (Адыгея)'],
    [ELocationType.RepublicBashkortostan, 'Республика Башкортостан'],
    [ELocationType.RepublicBuryatia, 'Республика Бурятия'],
    [ELocationType.RepublicAltai, 'Республика Алтай'],
    [ELocationType.RepublicDaghestan, 'Республика Дагестан'],
    [ELocationType.RepublicIngushetia, 'Республика Ингушетия'],
    [ELocationType.KabardinoBalkarianRepublic, 'Кабардино-Балкарская Республика'],
    [ELocationType.RepublicKalmykia, 'Республика Калмыкия'],
    [ELocationType.KarachayevoCircassianRepublic, 'Карачаево-Черкесская Республика'],
    [ELocationType.RepublicKarelia, 'Республика Карелия'],
    [ELocationType.KomiRepublic, 'Республика Коми'],
    [ELocationType.RepublicMariEl, 'Республика Марий Эл'],
    [ELocationType.RepublicMordovia, 'Республика Мордовия'],
    [ELocationType.RepublicSakha, 'Республика Саха (Якутия)'],
    [ELocationType.RepublicNorthOssetia, 'Республика Северная Осетия - Алания'],
    [ELocationType.RepublicTatarstan, 'Республика Татарстан (Татарстан)'],
    [ELocationType.RepublicTuva, 'Республика Тыва'],
    [ELocationType.UdmurtianRepublic, 'Удмуртская Республика'],
    [ELocationType.RepublicKhakassia, 'Республика Хакасия'],
    [ELocationType.ChechenRepublic, 'Чеченская Республика'],
    [ELocationType.ChuvashRepublic, 'Чувашская Республика - Чувашия'],
    [ELocationType.AltaiTerritory, 'Алтайский край'],
    [ELocationType.KrasnodarTerritory, 'Краснодарский край'],
    [ELocationType.KrasnoyarskTerritory, 'Красноярский край'],
    [ELocationType.PrimoryeTerritory, 'Приморский край'],
    [ELocationType.StavropolTerritory, 'Ставропольский край'],
    [ELocationType.KhabarovskTerritory, 'Хабаровский край'],
    [ELocationType.AmurRegion, 'Амурская область'],
    [ELocationType.ArkhangelskRegion, 'Архангельская область'],
    [ELocationType.AstrakhanRegion, 'Астраханская область'],
    [ELocationType.BelgorodRegion, 'Белгородская область'],
    [ELocationType.BryanskRegion, 'Брянская область'],
    [ELocationType.VladimirRegion, 'Владимирская область'],
    [ELocationType.VolgogradRegion, 'Волгоградская область'],
    [ELocationType.VologdaRegion, 'Вологодская область'],
    [ELocationType.VoronezhRegion, 'Воронежская область'],
    [ELocationType.IvanovoRegion, 'Ивановская область'],
    [ELocationType.IrkutskRegion, 'Иркутская область'],
    [ELocationType.KaliningradRegion, 'Калининградская область'],
    [ELocationType.KalugaRegion, 'Калужская область'],
    [ELocationType.KamchatkaTerritory, 'Камчатский край'],
    [ELocationType.KemerovoRegion, 'Кемеровская область - Кузбасс'],
    [ELocationType.KirovRegion, 'Кировская область'],
    [ELocationType.KostromaRegion, 'Костромская область'],
    [ELocationType.KurganRegion, 'Курганская область'],
    [ELocationType.KurskRegion, 'Курская область'],
    [ELocationType.LeningradRegion, 'Ленинградская область'],
    [ELocationType.LipetskRegion, 'Липецкая область'],
    [ELocationType.MagadanRegion, 'Магаданская область'],
    [ELocationType.MoscowRegion, 'Московская область'],
    [ELocationType.MurmanskRegion, 'Мурманская область'],
    [ELocationType.NizhnyNovgorodRegion, 'Нижегородская область'],
    [ELocationType.NovgorodRegion, 'Новгородская область'],
    [ELocationType.NovosibirskRegion, 'Новосибирская область'],
    [ELocationType.OmskRegion, 'Омская область'],
    [ELocationType.OrenburgRegion, 'Оренбургская область'],
    [ELocationType.OrelRegion, 'Орловская область'],
    [ELocationType.PenzaRegion, 'Пензенская область'],
    [ELocationType.PermTerritory, 'Пермский край'],
    [ELocationType.PskovRegion, 'Псковская область'],
    [ELocationType.RostovRegion, 'Ростовская область'],
    [ELocationType.RyazanRegion, 'Рязанская область'],
    [ELocationType.SamaraRegion, 'Самарская область'],
    [ELocationType.SaratovRegion, 'Саратовская область'],
    [ELocationType.SakhalinRegion, 'Сахалинская область'],
    [ELocationType.SverdlovskRegion, 'Свердловская область'],
    [ELocationType.SmolenskRegion, 'Смоленская область'],
    [ELocationType.TambovRegion, 'Тамбовская область'],
    [ELocationType.TverRegion, 'Тверская область'],
    [ELocationType.TomskRegion, 'Томская область'],
    [ELocationType.TulaRegion, 'Тульская область'],
    [ELocationType.TyumenRegion, 'Тюменская область'],
    [ELocationType.UlyanovskRegion, 'Ульяновская область'],
    [ELocationType.ChelyabinskRegion, 'Челябинская область'],
    [ELocationType.TransBaikalTerritory, 'Забайкальский край'],
    [ELocationType.YaroslavlRegion, 'Ярославская область'],
    [ELocationType.Moscow, 'г. Москва'],
    [ELocationType.StPetersburg, 'г. Санкт-Петербург'],
    [ELocationType.JewisAutonomousRegion, 'Еврейская автономная область'],
    [ELocationType.NenetsAutonomousArea, 'Ненецкий автономный округ'],
    [ELocationType.KhantyMansiAutonomousArea, 'Ханты-Мансийский автономный округ - Югра'],
    [ELocationType.ChukotkaAutonomousArea, 'Чукотский автономный округ'],
    [ELocationType.YamalNenetsAutonomousArea, 'Ямало-Ненецкий автономный округ'],
    [ELocationType.RepublicCrimea, 'Республика Крым'],
    [ELocationType.Sevastopol, 'г. Севастополь'],
])

enum GeneratorStatus {
    New = 1,
    UnderConsideration = 2,
    Approved = 3,
    Canceled = 4, // "не согласована"
    Deleted = 5, // ГО "удален"
    WaitForPayment = 6,
}

enum UsetTypes {
    Phisical = 0,
    Individual = 1,
    Legal = 2,
}

const UserTypesMap = new Map<UsetTypes, string>([
    [UsetTypes.Phisical, 'Физическое лицо'],
    [UsetTypes.Individual, 'Индивидуальный предприниматель'],
    [UsetTypes.Legal, 'Юридическое лицо'],
])

export {
    EStatus,
    actionTypeMap,
    EActionType,
    userRoleMap,
    EUserRole,
    ECitizenshipType,
    citizenshipTypeMap,
    EDocumentType,
    documentTypeMap,
    EApproveType,
    statusMap,
    EUserType,
    EEnergyType,
    energyTypeMap,
    EGoType,
    goTypeMap,
    ELocationType,
    locationTypeMap,
    EGoStatusType,
    goStatusTypeMap,
    GeneratorStatus,
    UserTypesMap,
    UsetTypes,
}
