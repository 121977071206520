import { PowerTypeTag } from '../../../ui'
import { FC } from 'react'

import style from './style.m.less'

interface IQuestionnaireItem {
    title: string
    isTag?: boolean
}

export const QuestionnaireItem: FC<IQuestionnaireItem> = ({ title, isTag, children }) => {
    if (children === null || children === undefined || children === "") {
        return null
    }

    const newChildren = children === 0 ? '0' : children

    return (
        <div className={style.questionnaireItemWrapper}>
            <div className={style.questionnaireItemName}>{title}</div>
            <div>{(isTag && newChildren) ? <PowerTypeTag value="">{newChildren}</PowerTypeTag> : newChildren}</div>
        </div>
    )
}
