import { FC, useState } from 'react'
import moment, { Moment } from 'moment'
import { Divider, Tooltip, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import { Calendar, EmptyWallet, Lock } from '../../../assets/icons'
import { PowerTypeTag, GreenToolsModal, Box, IconButton } from '../'
import { energyTypePicker } from '../../helpers/generatorHelpers'
import { StatusTag } from '../StatusTag'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../store/hooks'
import { EUserRole } from '../../../constants/profile'
import { currencyFormat } from '../../helpers'
import useRequest, { RequestTypes } from '../../hooks/useRequest'

import style from './style.m.less'

interface IContract {
    isDetailed?: boolean
    isRegistered?: boolean
    isOwner: boolean
    refreshData?: () => void
    onBlock?: () => void
    contract: {
        contract_id: string
        buyer_name: string
        buyer_uid: string
        buyer_account: string
        buyer_account_id: string
        seller_account: string
        seller_account_id: string
        account_number: string
        renewable_energy_type_id: number
        contract_number: string
        status_id: number
        volume_consume?: number
        volume_new?: number
        seller_name: string
        ts_registration: Moment
        ts_created: Moment
        ts_delivery: Moment
        buyer_codes: string[]
        seller_uid: string
        seller_codes: string[]
        tax_payer_type_id: number
        invisible_in_the_list: boolean
        is_buyer_tax_payer: boolean
        sdd_start_begin_date: Moment
    }
}

const ContractCard: FC<IContract> = ({
    isDetailed,
    isRegistered,
    isOwner,
    refreshData,
    onBlock,
    contract: {
        contract_id,
        contract_number,
        account_number,
        buyer_account,
        seller_account,
        renewable_energy_type_id,
        status_id,
        volume_consume,
        volume_new,
        buyer_uid,
        buyer_name,
        buyer_codes,
        seller_uid,
        seller_name,
        seller_codes,
        tax_payer_type_id,
        invisible_in_the_list,
        ts_registration,
        ts_created,
        ts_delivery,
        is_buyer_tax_payer,
        sdd_start_begin_date,
    },
}) => {
    const { t } = useTranslation()

    const [isGreenToolsModalVisible, setGreenToolsModalVisible] = useState(false)
    const convertDate = (date: Moment) =>
        date ? moment(date).format('DD.MM.YYYY') : 'Не определена'
    const [isInvisible, setIsInvisible] = useState<boolean>(invisible_in_the_list)
    const { fetch } = useRequest({
        link: `/api/green-tools/v1/contracts/set-contract-invisible`,
        method: RequestTypes.post,
    })

    const role = useAppSelector((state) => state?.user?.data?.user_role)
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(role)
    const isOwnerOrAdmin = (isOwner && isDetailed) || (isAdminType && isDetailed)
    const user_id = useAppSelector((state) => state?.user?.data?.uid)
    const disableChangeAccounts = isAdminType || (user_id !== buyer_uid && user_id !== seller_uid)

    const onChangeIsInvisible = (e: any) => {
        const body = { contract_id: contract_number, is_invisible: e.target.checked }
        fetch({
            body,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Настройки видимости успешно применены',
            },
            onSuccess: setIsInvisible(e.target.checked),
            errorCodeMessage: true,
        })
        setIsInvisible(e.target.checked)
    }

    const dateBlocks = [
        { title: 'Дата регистрации', value: convertDate(ts_registration) },
        { title: 'divider' },
        { title: 'Дата создания в системе', value: convertDate(ts_created) },
        { title: 'divider' },
        { title: 'Дата начала поставки по ЗД', value: convertDate(sdd_start_begin_date) },
    ]

    const detailedDateBlocks = [
        ...dateBlocks,
        { title: 'divider' },
        {
            title: 'Комиссию за реализацию АГ по ЗД платит',
            value: is_buyer_tax_payer ? buyer_name : seller_name,
            hideIcon: true,
        },
    ]

    const blocksConfig = isDetailed ? detailedDateBlocks : dateBlocks

    const cardLabel = 'Зеленый договор'

    const createCodes = (codesArray: string[]) => `(${(codesArray || []).toString()})`

    return (
        <div className={isDetailed ? style.certificateCardMainFull : style.certificateCardMain}>
            <div
                className={
                    isDetailed ? style.certificateHeaderWrapperFull : style.certificateHeaderWrapper
                }
            >
                {isRegistered ? (
                    <Link
                        to={{
                            pathname: `/user-contract-card/${contract_number}`,
                        }}
                        state={{
                            cardLabel,
                            id: contract_number,
                            isOwner,
                        }}
                        className={style.certificateHeader}
                    >
                        {cardLabel}
                    </Link>
                ) : (
                    <div className={style.certificateHeader}>
                        {isDetailed ? contract_number : cardLabel}
                    </div>
                )}
                <div className={style.tagsWrapper}>
                    <PowerTypeTag value={energyTypePicker(renewable_energy_type_id)}>
                        {energyTypePicker(renewable_energy_type_id)}
                    </PowerTypeTag>
                    <StatusTag statusId={status_id} />
                </div>
                {isOwnerOrAdmin && (
                    <Link
                        to={{
                            pathname: `/profile`,
                        }}
                        state={{
                            account_number,
                        }}
                        className={style.personalAccount}
                    >
                        <div className={style.accountsRow}>
                            {isAdminType ? (
                                <>
                                    <Link
                                        to={{
                                            pathname: `/user/${buyer_uid}`,
                                        }}
                                    >
                                        <div>ЛС Покупателя №{buyer_account}</div>
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: `/user/${seller_uid}`,
                                        }}
                                    >
                                        <div>ЛС Продавца №{seller_account}</div>
                                    </Link>
                                </>
                            ) : (
                                <div>Лицевой счет №{account_number}</div>
                            )}
                        </div>
                    </Link>
                )}
            </div>
            {!isDetailed && <div className={style.greyText}>{contract_number}</div>}
            <div className={style.volume}>
                {(volume_consume || volume_consume === 0) && (
                    <Tooltip
                        placement="top"
                        color="#434343"
                        overlayInnerStyle={{ color: 'white' }}
                        title="Всего реализовано"
                    >
                        <div
                            className={`${style.volumeBadge} ${style.volumeConsume}`}
                        >{`${currencyFormat(volume_consume)} кВт*ч`}</div>
                    </Tooltip>
                )}
                {volume_new && (
                    <Tooltip
                        placement="bottom"
                        color="#434343"
                        overlayInnerStyle={{ color: 'white' }}
                        title="Добавлен новый объем"
                    >
                        <div
                            className={`${style.volumeBadge} ${style.volumeNew}`}
                        >{`+ ${currencyFormat(volume_new)} кВт*ч`}</div>
                    </Tooltip>
                )}
            </div>
            <div>
                <div className={style.row}>
                    <div className={style.rowName}>Покупатель</div>
                    <div className={style.rowValue}>
                        {buyer_name} {isDetailed && createCodes(buyer_codes)}
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.rowName}>Продавец</div>
                    <div>
                        <Link
                            className={style.link}
                            to={isAdminType ? `/user/${seller_uid}` : `/owner/${seller_uid}`}
                        >
                            {seller_name}
                        </Link>
                        {isDetailed && (
                            <span className={style.rowValue}>{createCodes(seller_codes)}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className={style.dates}>
                {blocksConfig.map(({ title, value, hideIcon }, index) =>
                    title === 'divider' ? (
                        <Divider
                            key={`divider-${index}`}
                            type="vertical"
                            className={style.divider}
                        />
                    ) : (
                        <div key={title} className={style.dateBlock}>
                            <div className={style.lightGreyText}>{title}</div>
                            <div className={style.dateBlockValue}>
                                {!hideIcon && <Calendar />}
                                <div>{value}</div>
                            </div>
                        </div>
                    ),
                )}
            </div>
            {isDetailed && (
                <>
                    <Checkbox
                        className={style.checkbox}
                        checked={isInvisible}
                        onClick={onChangeIsInvisible}
                    >
                        {t('certificateCard.noShowInReestr')}
                    </Checkbox>
                    <Box direction="row" justify="space-between" align="flex-end">
                        <div className={style.buttonsBlock}>
                            <div>
                                <IconButton
                                    disabled={disableChangeAccounts}
                                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                                    icon={<EmptyWallet />}
                                    onClick={() => setGreenToolsModalVisible(true)}
                                >
                                    {t('certificateCard.changePersAccount')}
                                </IconButton>
                            </div>
                            <div>
                                <IconButton
                                    className={style.blockButton}
                                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                                    icon={<Lock color="currentColor" />}
                                    onClick={() => onBlock?.()}
                                >
                                    {t(
                                        status_id === 3
                                            ? 'certificateCard.unblock'
                                            : 'certificateCard.block',
                                    )}
                                </IconButton>
                            </div>
                        </div>
                    </Box>
                </>
            )}
            <GreenToolsModal
                isModalVisible={isGreenToolsModalVisible}
                setIsModalVisible={setGreenToolsModalVisible}
                certificateId={contract_number}
                refreshData={refreshData}
                user_id={user_id}
                isContract
            />
        </div>
    )
}

export default ContractCard
