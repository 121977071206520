import { FC } from 'react'
import { Input } from 'antd'

import style from './style.m.less'
import { TimeRangePicker } from '../../../ui'
import { useTranslation } from 'react-i18next'

interface IFilter {
    onChangeGOOwner: (inn: string) => void
    onChangeGoName: (inn: string) => void
    onChangeInn: (inn: string) => void
    onChangeDate: (date: [moment.Moment, moment.Moment] | null) => void
}

const Filter: FC<IFilter> = (props) => {
    const { t } = useTranslation()

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>{t('gOApproval.submissionDate')}</div>
                <TimeRangePicker onChange={props.onChangeDate} />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('gOApproval.gOOwner')}</div>
                <Input
                    placeholder={t('gOApproval.typeGOOwner')}
                    className={style.input}
                    onChange={(event) => props.onChangeGOOwner(event.target.value)}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('gOApproval.gOName')}</div>
                <Input
                    placeholder={t('gOApproval.typeGo')}
                    className={style.input}
                    onChange={(event) => props.onChangeGoName(event.target.value)}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('gOApproval.inn')}</div>
                <Input
                    placeholder={t('gOApproval.typeInn')}
                    className={style.input}
                    onChange={(event) => props.onChangeInn(event.target.value)}
                />
            </div>
        </div>
    )
}

export default Filter
