import {
    Card,
    SortButton,
    InputSearch,
    TimeRangePicker,
    NewsItem,
    Popover,
    SortToggle,
    IconButtonSmall,
    GreyButton,
} from '../ui'
import { FC, useState, useEffect, } from 'react'

import style from './style.m.less'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SubscribeModal, TagToggle } from './components'
// import { Tick } from '../../assets/icons'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { EUserRole } from '../../constants/approval'
import { useAppSelector } from '../../store/hooks'
import useGetFiles from '../hooks/useGetFiles'
import { useForm } from 'antd/es/form/Form'
import { Form, Space, Pagination, PaginationProps, Spin } from 'antd'


const PAGE_SIZE = 12

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page: number
    pageSize: number
    f?: any
}

const NewsAndEvents: FC = () => {
    const { t } = useTranslation()
    const [form] = useForm()

    const navigate = useNavigate()
    const [sortOpen, setSortOpen] = useState(false)
    const [selectNews, setSelectNews] = useState(true)
    const [selectEvents, setSelectEvents] = useState(true)
    const [sortDirection, setSortDirection] = useState(true)
    const { files, downloadFileList, loadingIds: loadingFileIds } = useGetFiles()

    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)

    const onShowSizeChange = (a: any, pageSize: number) => {
        setPagination(lastPagination => {
            const pageSizeChange = lastPagination.pageSize !== pageSize;
            if (pageSizeChange) {
                return {
                    page: 1,
                    pageSize: pageSize,
                }
            } else {
                return { ...lastPagination }
            }

        })
    }

    const handleClickNews = (id: string) => {
        navigate(`/news/${id}`)
    }

    const handleClickCreate = () => {
        navigate('/news/deal/create')
    }

    const userRole = useAppSelector((state) => state.user.data.user_role)

    const isAdmin =
    userRole === EUserRole.SuperAdmin ||
    userRole === EUserRole.Administrator

    const {
        fetch,
        result,
        isLoading: newsLoading,
    } = useRequest({
        link: `/api/content/v1/news`,
        method: RequestTypes.post,
    })

    const dowloadAllDocs = () => {
        const imgIdsList = (result?.news || []).map((el: any) => el.document.id)
        downloadFileList(imgIdsList)
    }

    const getNews = (isSearch: boolean | undefined) => {
        const values = form.getFieldsValue()
        const type_id = () => {
            if(selectNews && !selectEvents) {
                return 1
            }
            if(!selectNews && selectEvents) {
                return 2
            }
            return 0
        }
        const req = {
            body: {
                limit: pagination.pageSize,
                // при поиске offset 0 т.к. не знаем сколько чего прийдет
                // при работе с пагинацией расчитываем offset
                offset: isSearch ? 0 : (pagination.page - 1) * pagination.pageSize,
                filters: {
                    text_filter: values.searchText || null, // Поиск текста по заголовку и контексту
                    ts_from:  values.rangeSearch?.[0] || null, // "2023-01-01T00:00:00Z",
                    ts_to:    values.rangeSearch?.[1] || "2100-01-01T00:00:00Z", // "2025-01-01T00:00:00Z",
                    news_type_id: type_id()  // Тип Все - 0, Новость - 1 (значение по умолчанию), Событие - 2
                },
                sort: {
                    type_id: 1, // 0, Сортировка 1 - по Дате
                    is_descending: sortDirection // false - ascending; true - descending
                }
            }
        }
        fetch(req)
    }
    useEffect(() => {
        getNews(false)
    }, [sortDirection, pagination.page, pagination.pageSize])

    useEffect(() => {
        if (result?.news !== null) {
            dowloadAllDocs()
        }
    }, [result])

    const [subscModalOpen, setSubscModalOpen] = useState(false)
    const [subscribed, setSubscribed] = useState(false)

    const onChangePage: PaginationProps['onChange'] = (page) => {
      setPagination((lastPagination: any) => ({
        ...lastPagination,
        page,
      }))
    };

    const handleSetStateSortToggle = (state: boolean) => {
        setSortDirection(state)
        setSortOpen(false)
    }

    return (
        <div className={style.newsAndEvents}>
            <div className={style.mainScreen}>
                <div className={style.container}>
                    <h1 className={style.title}>{t('newsAndEvents.title')}</h1>
                    {/* {!isAdmin && <div>
                    {subscribed ? (
                        <div className={style.subscribed}>
                            <Tick />
                            <p className={style.subscribedText}>
                                {t('newsAndEvents.youSubscribed')}
                                <br />
                                {t('newsAndEvents.unsubscribe')}{' '}
                                <a href="ya.ru" className={style.unsubscribeLink}>
                                    {t('newsAndEvents.link')}
                                </a>
                            </p>
                        </div>
                    ) : (
                        <>
                            <p className={style.subscribeText}>
                                {t('newsAndEvents.subscribeText1')}
                                <br />
                                {t('newsAndEvents.subscribeText2')}
                            </p>
                            <button
                                className={style.subscribeButton}
                                onClick={() => setSubscModalOpen(true)}
                            >
                                {t('newsAndEvents.subscribe')}
                            </button>
                        </>
                    )}
                    </div>} */}
                    {isAdmin && <GreyButton
                            onClick={() => handleClickCreate()}
                            value={t('newsAndEvents.createNews')}
                            customStyle={{ marginTop: '32px' }}
                        />}
                    <SubscribeModal
                        isVisible={subscModalOpen}
                        onCancel={() => setSubscModalOpen(false)}
                        onSubscribe={() => {
                            setSubscribed(true)
                            setSubscModalOpen(false)
                        }}
                    />
                </div>
            </div>
            <div className={style.content}>
                <Spin spinning={newsLoading}>
                <div className={style.container}>
                    <Card>
                        <Form
                            form={form}
                            name="newsSearchForm"
                        >
                        <div className={style.filters}>
                            <Popover
                                onClickOut={setSortOpen}
                                open={sortOpen}
                                content={
                                    <div>
                                        <div className={style.sortTitle}>
                                            {t('newsAndEvents.sortBy')}
                                        </div>
                                        <div className={style.sortItem}>
                                            <span className={style.sortItemLabel}>
                                                {t('newsAndEvents.addDate')}
                                            </span>
                                            <SortToggle
                                                disabled={newsLoading}
                                                state={sortDirection}
                                                setState={handleSetStateSortToggle}
                                            />
                                        </div>
                                    </div>
                                }
                            >
                                <SortButton type='small' onClick={() => setSortOpen((open) => !open)}/>
                            </Popover>
                            <Form.Item 
                                name="searchText"
                            >
                                <InputSearch />
                            </Form.Item>
                            <Form.Item
                                name="rangeSearch"
                            >
                            <TimeRangePicker
                                onChange={() => {}}
                                />
                            </Form.Item>
                            <Space className={style.wrapTagToggle}> 
                                <TagToggle
                                    text={'newsAndEvents.news'}
                                    color={'blue'}
                                    select={selectNews}
                                    setSelect={setSelectNews}
                                    needSetOtherSelect={!selectEvents && selectNews}
                                    setOtherSelect={setSelectEvents}
                                />
                                <TagToggle
                                    text={'newsAndEvents.events'}
                                    color={'green'}
                                    select={selectEvents}
                                    setSelect={setSelectEvents}
                                    needSetOtherSelect={selectEvents && !selectNews}
                                    setOtherSelect={setSelectNews}                                    
                                />
                                </Space> 
                                {<IconButtonSmall
                                        disabled={newsLoading}
                                        onClick={() => getNews(true)}
                                    >
                                        {t('newsAndEvents.searchButton')}
                                    </IconButtonSmall>}
                            </div>
                        </Form>
                    </Card>
                </div>
                </Spin>
                <div className={style.container}>
                    <div className={style.grid}>
                    {(result?.news || []).map((item: any) =>
                        <NewsItem
                            key={item.id}
                            title={item.title}
                            date={item.ts_created}
                            isNews={item.news_type_id === 1}
                            isEvent={item.news_type_id === 2}
                            description={item.body}
                            image={!files[item.document.id] && require('../../assets/images/newsBig.jpg')}
                            fileLoading={loadingFileIds[item.document.id] && !files[item.document.id]}
                            file={files[item.document.id]}
                            onClick={() => handleClickNews(item.id)}
                        />)}
                    </div>
                    <Card style={{ marginTop: '24px' }}>
                        <Pagination
                            current={pagination.page}
                            onChange={onChangePage}
                            showSizeChanger={result?.total}
                            defaultPageSize={12}
                            pageSizeOptions={[12, 30, 60, 120]}
                            onShowSizeChange={onShowSizeChange}
                            defaultCurrent={1}
                            total={result?.total}
                            disabled={newsLoading || result?.total === 0}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default NewsAndEvents
