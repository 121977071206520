import { FC, useState } from 'react'
import { Divider } from 'antd'
import { Card, PrimaryButton, IFile, LoadingContainer } from '../ui'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import DocumentsBlock from '../AdminUploading/components/DocumentsBlock/DocumentsBlock'
import { postUpload, postRedeem } from './config'

import style from './style.m.less'

interface IThirdPartyToolsOperations {
    isAdding: boolean
}

const ThirdPartyToolsOperations: FC<IThirdPartyToolsOperations> = ({ isAdding }) => {
    const navigate = useNavigate()
    const [files, setFiles] = useState<IFile[]>([])
    const { fetch: sendUpload, isLoading: isLoadingUpload } = useRequest(postUpload)
    const { fetch: sendRedeem, isLoading: isLoadingRedeem } = useRequest(postRedeem)

    const fileHandler = (list: any) => {
        if (!files.length) {
            return setFiles(list)
        }
    }

    const handleConfirm = async () => {
        const body = {
            id: files[0].id,
        }
        isAdding
            ? sendUpload({
                  body,
                  successMessage: {
                      title: 'Успешно',
                      description: 'Данные направлены Владельцу ЗГО',
                  },
                  onSuccess: () => navigate('/green-tools'),
                  errorCodeMessage: true,
              })
            : sendRedeem({
                  body,
                  successMessage: {
                      title: 'Успешно',
                      description: 'Сторонние инструменты погашены',
                  },
                  onSuccess: () => navigate('/green-tools'),
                  errorCodeMessage: true,
              })
    }

    const isLoading = isAdding ? isLoadingUpload : isLoadingRedeem

    return (
        <LoadingContainer isLoading={isLoading}>
            <div className={style.container}>
                <div className={style.titleWrapper}>
                    <div>
                        <h1 className={style.title}>
                            {isAdding
                                ? 'Добавление сторонних инструментов'
                                : 'Погашение сторонних инструментов'}
                        </h1>
                    </div>
                </div>
                <Card>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={4}
                        description={
                            files.length >= 1
                                ? ''
                                : 'Загрузите файл, из которого хотите импортировать данные     (не более 1)'
                        }
                        customStyle={{ width: '440px' }}
                    />
                    <Divider />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            onClick={() => navigate('/green-tools')}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={handleConfirm}
                            value={isAdding ? 'Подать данные' : 'Погасить'}
                            disabled={!files.length}
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default ThirdPartyToolsOperations
