import { FC, useCallback, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { CenteredSpin, Pagination, Empty } from '../ui'
import { getGenerators } from '../../store/generators'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { ESortType } from './components/Controls/Controls'
import debounce from 'lodash/debounce'
import request from '../../services/request'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getDictionaries } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'
import { getUserInfo } from '../../store/user'
import { Filters, Controls, GeneratorCard, ControlsAdmin } from './components'
import moment from 'moment'
import { IFilterValue } from './components/Filters/Filters'
import { EGoStatusType, EUserRole } from '../../constants/approval'
import { IGeneratingObject } from './components/GeneratorCard/GeneratorCard'
import { useNavigate, useParams } from 'react-router-dom'
import { EIsActive } from './components/ControlsAdmin/ControlsAdmin'

const PAGE_SIZE = 10

interface IData {
    total: number
    gos_registry?: IGeneratingObject[]
    power_max: number
}

interface IGenerators {
    isAuth: boolean
}

const Generators: FC<IGenerators> = ({ isAuth }) => {
    const { t } = useTranslation()

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)

    useEffect(() => {
        if (isAuth) {
            dispatch(getGenerators({ page }))
        }
    }, [page])

    const [data, setData] = useState<IData>()
    const [loading, setLoading] = useState(true)

    const { isactive: isActive = EIsActive.Active } = useParams<{ isactive?: EIsActive }>()
    const navigate = useNavigate()
    const [userNameFilter, setUserNameFilter] = useState('')
    const [sortType, setSortType] = useState<ESortType>()
    const [descending, setDescending] = useState<boolean>()
    const [filters, setFilters] = useState<IFilterValue>({
        renewableEnergyType: [],
        totalPowerFrom: 0,
        totalPowerTo: 0,
        qualificationDate: null,
        lastCheckDate: null,
        regionType: 0,
        statusType: EGoStatusType.All,
    })

    const loadOwners = useCallback(
        async (
            page: number,
            pageSize: number,
            filters: IFilterValue,
            name?: string,
            type_id?: ESortType,
            is_descending?: boolean,
            isActive?: EIsActive,
        ) => {
            try {
                setLoading(true)
                const res = await request.post(`/api/generators/v1/gos-registry`, {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        is_active: isActive === EIsActive.Active,
                        name,
                        renewable_energy_type_ids: filters.renewableEnergyType,
                        power_from: filters.totalPowerFrom,
                        power_to: filters.totalPowerTo,
                        qualification_date_from: filters.qualificationDate
                            ? moment(filters.qualificationDate[0]).startOf('day')
                            : undefined,
                        qualification_date_to: filters.qualificationDate
                            ? moment(filters.qualificationDate[1]).endOf('day')
                            : undefined,
                        last_check_date_from: filters.lastCheckDate
                            ? moment(filters.lastCheckDate[0]).startOf('day')
                            : undefined,
                        last_check_date_to: filters.lastCheckDate
                            ? moment(filters.lastCheckDate[1]).endOf('day')
                            : undefined,
                        region_id: filters.regionType,
                        has_blocks:
                            filters.statusType === EGoStatusType.Blocked
                                ? true
                                : filters.statusType === EGoStatusType.NotBlocked
                                ? false
                                : null,
                    },
                    sort: {
                        type_id,
                        is_descending,
                    },
                })
                setData(res.data)
            } finally {
                setLoading(false)
            }
        },
        [],
    )

    const loadOwnersDebounced = useCallback(debounce(loadOwners, 300), [])

    useEffect(() => {
        loadOwnersDebounced(page, pageSize, filters, userNameFilter, sortType, descending, isActive)
    }, [page, pageSize, filters, userNameFilter, sortType, descending])

    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
        if (isAuth) {
            dispatch(getUserInfo())
        }
    }, [])

    const userRole: EUserRole = isAuth && useAppSelector((state) => state.user.data.user_role)

    return (
        <div className={style.generatorsMain}>
            {(loading || isLoading !== ELoading.Loaded) && <CenteredSpin />}
            <div className={style.mainHeader}>{t('generators.reestr')}</div>
            <div className={style.mainWrapper}>
                {userRole === EUserRole.Administrator ||
                userRole === EUserRole.SuperAdmin ||
                userRole === EUserRole.Operator ? (
                    <ControlsAdmin
                        isActive={isActive}
                        onChangeActive={(isActive) => {
                            loadOwners(
                                page,
                                pageSize,
                                filters,
                                userNameFilter,
                                sortType,
                                descending,
                                isActive,
                            )
                            setPage(1)
                            setPageSize(PAGE_SIZE)
                            navigate(`/generators/${isActive}`)
                        }}
                    />
                ) : (
                    <Controls
                        name={userNameFilter}
                        sortType={sortType}
                        desc={descending}
                        onChangeUserName={setUserNameFilter}
                        onChangeSortType={setSortType}
                        onDescendingType={setDescending}
                    />
                )}
                <div className={style.mainBlock}>
                    <div className={style.cardsBlock}>
                        {isLoading === ELoading.Loaded &&
                            (data?.gos_registry && data?.gos_registry?.length > 0 ? (
                                (data?.gos_registry || []).map((item, index) => {
                                    const { id } = item
                                    return (
                                        <div key={id}>
                                            <GeneratorCard generatorData={item} />
                                            {index !== data.gos_registry.length - 1 && <Divider />}
                                        </div>
                                    )
                                })
                            ) : (
                                <Empty
                                    imageStyle={{
                                        height: 60,
                                        marginTop: 60,
                                    }}
                                    descriptionText={'По вашему запросу ничего не найдено'}
                                />
                            ))}
                    </div>
                    <Filters
                        values={filters}
                        onChange={setFilters}
                        name={userNameFilter}
                        onChangeUserName={setUserNameFilter}
                        isAuth={isAuth}
                    />
                </div>
                <Pagination
                    current={page}
                    pageSize={pageSize}
                    paginationChange={(receivedPage: number, pageSize?: number) => {
                        setPage(receivedPage)
                        if (pageSize) {
                            setPageSize(pageSize)
                        }
                    }}
                    total={data?.total}
                    showSizeChanger
                />
            </div>
        </div>
    )
}

export default Generators
