import { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CenteredSpin, HeaderBlock } from '../ui'
import { useAppDispatch } from '../../store/hooks'
import { useAuditData } from '../hooks'
import useRequest from '../hooks/useRequest'
import { getDictionaries } from '../../store/dictionaries'
import { getEventDetails, getEventCustom, getEventCertCustom, getAuditEventTypes } from './config'
import { ContentBlock } from './components'

import style from './style.m.less'

const AuditItem: FC = () => {
    const [actualType, setActualType] = useState()
    const { fetch, result, isLoading } = useRequest(getEventDetails)
    const {
        fetch: fetchCustom,
        result: resultCustom,
        isLoading: isLoadingCustom,
    } = useRequest(getEventCustom)
    const {
        fetch: fetchCertCustom,
        result: resultCertCustom,
        isLoading: isLoadingCertCustom,
    } = useRequest(getEventCertCustom)

    const { fetch: fetchTypes } = useRequest(getAuditEventTypes)

    const { state } = useLocation()
    const { id } = useParams<{ id: string }>()

    const dispatch = useAppDispatch()
    const data = useAuditData(id)
    const eventId = data?.eventId
    const eventType = data?.eventType

    const getActualType = async (eventId: number) => {
        if (!state) {
            fetchTypes({
                onSuccess(data) {
                    const obj = data.find((item) => item.id === eventId)
                    setActualType(obj)
                },
            })
        }
    }

    const isCustom = [20, 30].includes(eventId)

    const PAGE_SIZE = eventId === 20 ? 5 : 10

    const chooseFetchType = ({ page = 1, pageSize = PAGE_SIZE }) => {
        if (!state) {
            return {
                method: fetchCertCustom,
                body: {
                    body: {
                        limit: pageSize,
                        offset: (page - 1) * pageSize,
                    },
                    addToLink: id,
                    errorCodeMessage: true,
                },
                isLoad: isLoadingCertCustom,
                res: resultCertCustom,
            }
        } else if (isCustom) {
            return {
                method: fetchCustom,
                body: {
                    body: {
                        limit: pageSize,
                        offset: (page - 1) * pageSize,
                    },
                    addToLink: id,
                    errorCodeMessage: true,
                },
                isLoad: isLoadingCustom,
                res: resultCustom,
            }
        } else {
            return {
                method: fetch,
                body: {
                    id,
                    errorCodeMessage: true,
                },
                isLoad: isLoading,
                res: result,
            }
        }
    }

    const conditions = chooseFetchType({})
    const { method, body } = conditions

    const getCustomData = () => method(body)

    useEffect(() => {
        if (eventId) {
            getActualType(eventId)
            method(body)
            if (!conditions.isLoad && [20, 65, 21, 23, 22, 24, 79, 18].includes(eventId)) {
                dispatch(getDictionaries())
            }
        }
    }, [id, eventId])

    if (conditions.isLoad || !conditions.res || !eventId) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <HeaderBlock label={state ? eventType : actualType?.name} />
            <div className={style.content}>
                <ContentBlock
                    eventDetails={conditions.res}
                    eventData={{ eventId }}
                    getCustomData={getCustomData}
                />
            </div>
        </div>
    )
}

export default AuditItem
