import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const ArrowSwap: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="8" fill="#F5F5F5" />
                <path
                    d="M21.6667 17.9932L18.3267 21.3398"
                    stroke="#595959"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.3335 17.9932H21.6668"
                    stroke="#595959"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.3335 14.0068L13.6735 10.6602"
                    stroke="#595959"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M21.6668 14.0068H10.3335"
                    stroke="#595959"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )}
    />
)
