import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { Box, DatePicker, DropdownSelector2, ImportButton } from '../../../ui'
import style from './style.m.less'
import { Import } from '../../../../assets/icons'
import { Input } from 'antd'
import { Filters } from '../GenerationAttributes'
import { Moment } from 'moment'
import { GOListItem } from '../../types'

interface IControlsBlock {
    onChange: (value: string) => void
    onChangeFilters: Dispatch<SetStateAction<Filters>>
    currentOption: string
    options: { label: string; id: string }[]
    goList: GOListItem[]
}

const ControlsBlock: FC<IControlsBlock> = ({
    options,
    currentOption,
    goList,
    onChange,
    onChangeFilters,
}) => {
    const isOverdue = currentOption === 'Истек срок действия'
    const isBlocked = currentOption === 'Заблокированные/неоплаченные'
    const filterName = isOverdue ? 'expires_at' : isBlocked ? 'blocked_at' : 'registered_at'

    useEffect(() => {
        onChangeFilters((filters: Filters) => {
            const newFilters = { ...filters }
            delete newFilters['expires_at']
            delete newFilters['blocked_at']
            delete newFilters['registered_at']
            return {
                ...newFilters,
            }
        })
    }, [currentOption])

    return (
        <>
            <div className={style.controlsBlock}>
                <div className={style.controlsBlockInner}>
                    <div className={style.inputsRow}>
                        <div className={style.selectorWrapper}>
                            <div className={style.selector}>
                                {options?.map(({ id, label }) => (
                                    <Box direction="row" key={id}>
                                        <div
                                            className={
                                                currentOption === label
                                                    ? style.agToolSelected
                                                    : style.agTool
                                            }
                                            onClick={() => onChange(label)}
                                        >
                                            {label}
                                        </div>
                                        <div className={style.spaceBar}></div>
                                    </Box>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={style.importButtonContrainer}>
                        <ImportButton
                            value={'Выгрузить данные'}
                            isDisabled={true}
                            icon={<Import />}
                            customIconStyle={{ marginTop: '3px' }}
                        />
                    </div>
                </div>
                <div className={style.filters}>
                    <div className={style.leftGapBlock}>
                        <div>Период выработки</div>
                        <div className={style.inputBlock}>
                            <DatePicker
                                customStyle={{ width: '100%' }}
                                onChange={(value: Moment) => {
                                    onChangeFilters((filters: Filters) => ({
                                        ...filters,
                                        period: value?.format('YYYY-MM-DD'),
                                    }))
                                }}
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>Объем, кВт*ч</div>
                        <div className={style.inputBlock}>
                            <Input
                                style={{ width: '100%', borderRadius: '8px' }}
                                className={style.inputBlockInput}
                                placeholder={'Введите объем'}
                                onChange={(event) =>
                                    onChangeFilters((filters: Filters) => ({
                                        ...filters,
                                        volume: event.target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>ЗГО</div>
                        <div className={style.inputBlock}>
                            <DropdownSelector2
                                customStyle={{ width: '100%' }}
                                options={(goList || []).map((item) => ({
                                    text: item.name,
                                    value: item.generator_id,
                                    id: item.generator_id,
                                }))}
                                placeholder={'Все'}
                                allowClear
                                onChange={(value) =>
                                    onChangeFilters((filters: Filters) => ({
                                        ...filters,
                                        generator_id: value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>
                            {isOverdue
                                ? 'Дата окончания срока действия'
                                : isBlocked
                                ? 'Дата блокировки'
                                : 'Дата занесения в реестр'}
                        </div>
                        <div className={style.inputBlock}>
                            <DatePicker
                                key={isOverdue ? '111111' : isBlocked ? '222222' : '333333'}
                                customStyle={{ width: '100%' }}
                                onChange={(value: Moment) => {
                                    onChangeFilters((filters: Filters) => ({
                                        ...filters,
                                        [filterName]: value?.format('YYYY-MM-DD'),
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ControlsBlock
