import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
    BackArrow,
    CenteredSpin,
    ThirdPartyToolsCard,
    IconButton,
    OperationHistoryMapper,
} from '../ui'
import { EHistoryType, IOperationConfig } from '../ui/OperationHistoryMapper/types'
import { useContractData } from '../hooks/useContractData'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { getMyContractData, getPublicContractData, getRedeemData } from './config'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { getDictionaries } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'

import style from './style.m.less'

const ThirdPartyTools: FC = () => {
    const { t } = useTranslation()

    const contractData = useContractData()
    const { isUserAdmin } = useCurrentUser()

    const { fetch, result, isLoading } = useRequest(
        contractData?.isOwner ? getMyContractData : getPublicContractData,
    )

    const { fetch: fetchRedeemData, result: redeemResult } = useRequest(getRedeemData)

    const isLoadingDict = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()

    const { fetch: fetchContractHistory, result: contractHistory } = useRequest({
        link: '/api/audit/v1/event-history-ext-instrs?id=',
        method: RequestTypes.post,
    })
    const contractHistoryParsed: IOperationConfig[] = []

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const getContractHistory = () => {
        if (contractData?.id) {
            fetchContractHistory({
                addToLink: contractData?.id,
            })
        }
    }

    const fetchContractData = () => {
        if (contractData?.id) {
            fetch({
                addToLink: contractData?.id,
            })
        }
    }

    useMount(() => {
        if (isLoadingDict !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
        fetchContractData()
    })

    useEffect(() => {
        if (contractData) {
            fetchContractData()
            getContractHistory()
        }
    }, [contractData])

    useEffect(() => {
        if (result?.status_id === 2) {
            fetchRedeemData({
                addToLink: contractData?.id,
            })
        }
    }, [result])

    if (isLoading || isLoadingDict === ELoading.Loading || !result) {
        return <CenteredSpin />
    }

    const {
        id,
        number,
        owner_account_id,
        owner,
        renewable_energy_type_id,
        status_id,
        total_volume,
        generator_id,
        generator_name,
        month,
        year,
        created_at,
        expires_at,
        external_registry_name,
        external_registry_authority,
        redeem_date,
        owner_account_number,
    } = result

    if (contractHistory?.total > 0) {
        const today = moment().startOf(dayUnit)
        const eh = contractHistory?.events?.map(
            (phe: { ts_event: string; payload: any[]; type_event_id: number }) => {
                const parsedDate = moment(phe.ts_event).format(shortFormat)
                const eventDay = moment(parsedDate, shortFormat)

                let title = eventDay.format(extraFormat)

                if (eventDay.isSame(today)) {
                    title = `${t('history.today')}, ${title}`
                }

                if (eventDay.isSame(today.subtract(1, dayUnit))) {
                    title = `${t('history.yesterday')}, ${title}`
                }

                const operation = {
                    id: phe.type_event_id,
                    time: moment(phe.ts_event).format('HH:MM'),
                    value: phe?.payload?.volume_consume,
                }

                return {
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: [operation],
                }
            },
        )

        contractHistoryParsed.push(...eh)
    }

    const historyContent = (
        <div className={style.historyBlock}>
            <div className={style.header}>{t('myProfile.operationHistory')}</div>
            <OperationHistoryMapper
                config={contractHistoryParsed}
                settings={{ historyType: EHistoryType.Certs }}
                isThirdParty
            />
            {contractHistory?.total > 0 && (
                <Link to={`/other-history-operations/${contractData?.id}`}>
                    <IconButton customStyle={{ marginTop: '40px' }}>Все операции</IconButton>
                </Link>
            )}
        </div>
    )

    const historyPlace = contractData?.isOwner || isUserAdmin ? historyContent : <></>

    return (
        <div className={style.certificateMain}>
            <div className={style.container}>
                <div className={style.headerBlock}>
                    <div className={style.backBlock}>
                        <BackArrow />
                        <div className={style.mainHeader}>{contractData?.cardLabel}</div>
                    </div>
                </div>
                <ThirdPartyToolsCard
                    isDetailed
                    isUserType={contractData?.isUserType}
                    isOwner={contractData?.isOwner}
                    refreshData={fetchContractData}
                    tool={{
                        id,
                        number,
                        owner_account_id,
                        owner,
                        renewable_energy_type_id,
                        status_id,
                        total_volume,
                        generator_id,
                        generator_name,
                        month,
                        year,
                        created_at,
                        expires_at,
                        external_registry_name,
                        external_registry_authority,
                        redeem_date,
                        owner_account_number,
                    }}
                />
                <div className={style.wrapper}>
                    {status_id === 2 ? (
                        <>
                            <div className={style.repaymentWrapper}>
                                <div className={style.repaymentBlock}>
                                    <div className={style.repaymentHeader}>
                                        Погашение стороннего инструмента
                                    </div>
                                    <div className={style.objectBlock}>
                                        <div className={style.infoBlock}>
                                            {t('certificate.paymentDate')}
                                        </div>
                                        <div className={style.dateBlock}>
                                            {moment(redeemResult?.redeem_date).format('DD.MM.YYYY')}
                                        </div>
                                    </div>
                                    <div className={style.objectBlock}>
                                        <div className={style.infoBlock}>
                                            Количество потребителей
                                        </div>
                                        <div>{redeemResult?.redeemers}</div>
                                    </div>
                                </div>
                            </div>
                            {historyPlace}
                        </>
                    ) : (
                        historyPlace
                    )}
                </div>
            </div>
        </div>
    )
}

export default ThirdPartyTools
