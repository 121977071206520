import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment/moment'
import debounce from 'lodash/debounce'
import useMount from '../hooks/useMount'
import { HeaderBlock, Pagination, CenteredSpin, OperationHistoryMapper } from '../ui'
import { getContractTypes } from '../../services/dictionaries'
import OperationContractsFilters from '../Profile/pages/AllOperations/OperationContractsFilters'
import {
    EOperationTypeID,
    getOperationContractsHistory,
    operationTypeIdMap,
} from '../Profile/pages/AllOperations/configurations'
import { EHistoryType } from '../ui/OperationHistoryMapper/types'
import style from './style.m.less'
import useRequest from '../hooks/useRequest'

interface ILoadCertHistory {
    id: string
    page: number
    pageSize: number
    eventDateFilter: [moment.Moment, moment.Moment] | null
    typeEventIDsFilter: number[]
    volumeMinFilter: number | null
    volumeMaxFilter: number | null
    operationTypeIDFilter: EOperationTypeID | undefined
}

const PAGE_SIZE = 10
const debounceWaitMs = 300

const UserContractHistoryOperations: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string | undefined }>()
    const { fetch, result, isLoading } = useRequest(getOperationContractsHistory)

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const [typeEventIDs, setTypeEventIDs] = useState({
        options: [],
        dictionary: {},
    })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [eventDateFilter, setEventDateFilter] = useState<[moment.Moment, moment.Moment] | null>(
        null,
    )
    const [typeEventIDsFilter, setTypeEventIDsFilter] = useState<number[]>([])
    const [volumeMinFilter, setVolumeMinFilter] = useState(0)
    const [volumeMaxFilter, setVolumeMaxFilter] = useState(0)
    const [operationTypeIDFilter, setOperationTypeIDFilter] = useState<
        EOperationTypeID | undefined
    >(EOperationTypeID.OpTypeAll)

    const operationTypeIdsOptions = Array.from(operationTypeIdMap, ([key, value]) => ({
        label: value,
        value: key,
        text: value,
    }))

    const getDict = async () => {
        const typeEventsResponse = await getContractTypes()
        const eventOptions = typeEventsResponse?.data?.map((item) => {
            return {
                id: item.id,
                value: item.id,
                text: item.name,
            }
        })

        setTypeEventIDs({
            dictionary: eventOptions?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.text })) ?? {},
            options: [...eventOptions],
        })
    }

    useMount(() => {
        getDict()
    })

    const today = moment().startOf(dayUnit)

    const fetchData = useCallback(
        ({
            id,
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            volumeMinFilter,
            volumeMaxFilter,
            operationTypeIDFilter,
        }: ILoadCertHistory) => {
            fetch({
                body: {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        event_date_from: eventDateFilter
                            ? moment(eventDateFilter[0]).startOf(dayUnit)
                            : null,
                        event_date_to: eventDateFilter
                            ? moment(eventDateFilter[1]).endOf(dayUnit)
                            : null,
                        type_event_ids: typeEventIDsFilter.length > 0 ? typeEventIDsFilter : null,
                        volume_min: volumeMinFilter,
                        volume_max: volumeMaxFilter,
                        operation_type_id: operationTypeIDFilter,
                    },
                },
                getParams: {
                    id,
                },
            })
        },
        [],
    )

    const list = result?.events?.map(
        (phe: { ts_event: string; payload: any[]; type_event_id: number }) => {
            const parsedDate = moment(phe.ts_event).format(shortFormat)
            const eventDay = moment(parsedDate, shortFormat)

            let title = eventDay.format(extraFormat)

            if (eventDay.isSame(today)) {
                title = `${t('history.today')}, ${title}`
            }

            if (eventDay.isSame(today.subtract(1, dayUnit))) {
                title = `${t('history.yesterday')}, ${title}`
            }

            const operation = {
                id: phe.type_event_id,
                time: moment(phe.ts_event).format('HH:MM'),
                value: phe?.payload?.volume_consume,
            }

            return {
                flexStart: false,
                iconMargin: false,
                date: title,
                operations: [operation],
            }
        },
    )

    const loadHistoryDebounced = useCallback(debounce(fetchData, debounceWaitMs), [])

    useEffect(() => {
        loadHistoryDebounced({
            id,
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            volumeMinFilter,
            volumeMaxFilter,
            operationTypeIDFilter,
        })
    }, [
        id,
        page,
        pageSize,
        eventDateFilter,
        typeEventIDsFilter,
        volumeMinFilter,
        volumeMaxFilter,
        operationTypeIDFilter,
    ])

    return (
        <div className={style.certificateMain}>
            <div className={style.editMain}>
                <div className={style.headerWrapper}>
                    <HeaderBlock label={t('greenTools.allOperations')} />
                </div>
                {isLoading && !result && <CenteredSpin />}
                <div className={style.editFrame}>
                    <div className={style.controlsBlock}>
                        <OperationContractsFilters
                            settings={{ historyType: EHistoryType.Certs }}
                            typeEventIDsOptions={typeEventIDs.options}
                            typeEventIDsFilter={typeEventIDsFilter}
                            operationTypeIdsOptions={operationTypeIdsOptions}
                            onChangeTypeEventIDs={(values) => {
                                setTypeEventIDsFilter(values)
                                setOperationTypeIDFilter(undefined)
                            }}
                            onChangeAmountMin={(value) => {
                                setPage(1)
                                setVolumeMinFilter(value)
                            }}
                            onChangeAmountMax={(value) => {
                                setPage(1)
                                setVolumeMaxFilter(value)
                            }}
                            onChangeDate={(range) => {
                                setPage(1)
                                setEventDateFilter(range)
                            }}
                            onChangeOperationTypeId={(value: any) => {
                                setPage(1)
                                setOperationTypeIDFilter(value)
                            }}
                        />
                    </div>
                    <OperationHistoryMapper
                        settings={{ historyType: EHistoryType.Certs }}
                        config={list}
                        isContract
                    />
                    <Pagination
                        showSizeChanger
                        customStyle={{ padding: 0, marginTop: '32px', boxShadow: 'none' }}
                        current={page}
                        paginationChange={(receivedPage: number, receivedPageSize?: number) => {
                            setPage(receivedPage)
                            setPageSize(receivedPageSize ?? PAGE_SIZE)
                        }}
                        pageSize={pageSize}
                        total={result?.total}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserContractHistoryOperations
