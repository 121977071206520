import useRequest from '../../hooks/useRequest'
import PageContainer from '../../ui/PageElements/PageContainer/PageContainer'
import { userRolePicker, createFIO } from '../../helpers/profileHelpers'
import { getUserAppeal, getDictHelpdesk } from '../config'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Card from '../../ui/Card/Card'
import { Dropdown, Spin, Tabs, Typography } from 'antd'
import { Box } from '../../ui/Box'
import AppealChat from './AppealChat'
import { renderDate } from '../../../utils/formatter'
import AppealMessage from './AppealMessage'
import PageTabs from '../../ui/PageElements/PageTabs/PageTabs'
import Document from '../../ui/Document/Document'
import AppealOperatorChat from './AppealOperatorChat'
import { UserStatusTag } from './Helpdesk'
import style from '../style.m.less'
import CancelAppealModal from './CancelAppealModal'
import { EllipsisOutlined } from '@ant-design/icons'
import useDownLoadFile from '../../hooks/useDownloadFile'
import useDownloadSignedRequest from '../../hooks/useDownloadSignedRequest'
import DateTimeLine from '../../ui/DateTimeLine/DateTimeLine'
import IconButton from '../../ui/IconButton/IconButton'
import useMount from '../../hooks/useMount'
import { useAppSelector } from '../../../store/hooks'
import { EUserRole } from '../../../constants/approval'
import BackLinkArrowButton from '../../ui/BackLinkArrowButton/BackLinkArrowButton'

const { TabPane } = Tabs

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const AppealInfo = ({ title, description }: any) => (
    <Box direction="row" margin="0px 0px 12px 0px">
        <Typography.Text type="secondary" style={{ width: '160px' }}>
            {title}
        </Typography.Text>
        <Typography.Text strong style={{ marginLeft: '24px' }}>
            {description}
        </Typography.Text>
    </Box>
)

export const ReadAppeal = () => {
    const navigate = useNavigate()
    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isAdmin = [
        EUserRole.Administrator,
        userRole === EUserRole.SuperAdmin,
        EUserRole.Operator,
    ].includes(userRole)

    const { fetch, result: appeal, isLoading, isError } = useRequest(getUserAppeal)
    const { downloadFile, downloadFileNamedList, downloadedId } = useDownLoadFile()
    const { downloadSignedRequest } = useDownloadSignedRequest()
    const { fetch: fetchDict, result: dict } = useRequest(getDictHelpdesk)
    const [result, setResult] = useState<any>(undefined)
    const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false)
    const isClient = !result?.inquire.user

    const openCancelModal = (value: boolean) => {
        setIsCancelModalVisible(value)
    }

    const { id } = useParams<{ id: string }>()

    const getAppeal = () => {
        fetch({ getParams: { id } })
    }

    useMount(scrollToTop)

    useEffect(() => {
        if (!id) {
            navigate('my-appeal')
        }
        fetchDict({})
        getAppeal()
    }, [id])

    useEffect(() => {
        if (!appeal) {
            return
        }
        setResult(appeal)
    }, [appeal])

    const chatDocuments = (result?.chats || []).filter((el: any) => el.documents.length)
    const documents = result?.inquire.documents || []

    const dowloadAll = () => {
        const chd = chatDocuments.map((el: any) => el.documents).flat()
        const allDocs = [...chd, ...documents].map((el: any) => {
            return { id: el.id, file_name: el.file_name }
        })
        downloadFileNamedList(allDocs)
    }

    const payment = result?.chats[result?.chats.length - 1]?.payment || null

    const isPayWait = payment?.payment_status || 0
    const topic =
        dict && result
            ? dict.find((l: any) => l.id === result.inquire.topic_id)?.topic || 'Неизвестно'
            : 'Неизвестно'

    if (isLoading && !result) {
        return (
            <PageContainer>
                <Card>
                    <Box align="center" justify="center" height={300}>
                        <Spin />
                    </Box>
                </Card>
            </PageContainer>
        )
    }

    if (isError) {
        return (
            <PageContainer>
                <Card>
                    <Typography.Text type="secondary">Заявка отсутствует в системе</Typography.Text>
                </Card>
            </PageContainer>
        )
    }

    return (
        <PageContainer>
            <>
                <CancelAppealModal
                    isModalVisible={isCancelModalVisible}
                    setIsModalVisible={openCancelModal}
                    id={result?.inquire?.id}
                    getAppeal={getAppeal}
                />
                <BackLinkArrowButton
                    title={`Заявка №${id}`}
                    extra={<UserStatusTag status={result?.inquire.status_id} />}
                    link={isAdmin ? '/user-appeals' : '/my-appeals'}
                />
                <PageTabs>
                    <Tabs
                        defaultActiveKey="1"
                        style={{ padding: '2px', paddingTop: '0px', borderColor: 'red' }}
                    >
                        <TabPane tab="Описание заявки" key="1">
                            <Card zeroSidePadding>
                                {!isClient && (
                                    <Box
                                        padding="32px 32px 0px 32px"
                                        direction="row"
                                        align="center"
                                    >
                                        <Box className={style.appeal}>
                                            <Typography.Link strong style={{ fontSize: '16px' }}>
                                                {result.inquire.user.company_name_short
                                                    ? result.inquire.user.company_name_short
                                                    : `${createFIO(result.inquire.user)}`}
                                            </Typography.Link>
                                        </Box>
                                        <Box className={style.clientType}>
                                            <Typography.Text type="secondary">
                                                {userRolePicker(result.inquire.user.user_role)}
                                            </Typography.Text>
                                        </Box>
                                    </Box>
                                )}
                                <Box
                                    direction="row"
                                    padding="32px 32px 0px 32px"
                                    justify="space-between"
                                >
                                    {isClient ? (
                                        <Box direction="row">
                                            <Typography.Text strong>{topic}</Typography.Text>
                                            <Typography.Text
                                                type="secondary"
                                                style={{ marginLeft: '20px' }}
                                            >
                                                {`от ${renderDate(
                                                    result?.inquire.ts_created || '',
                                                )}`}
                                            </Typography.Text>
                                        </Box>
                                    ) : (
                                        <Box direction="column">
                                            <AppealInfo title="Тема заявки" description={topic} />
                                            <AppealInfo
                                                title="Дата/время заявки"
                                                description={`от ${renderDate(
                                                    result?.inquire.ts_created || '',
                                                )}`}
                                            />
                                            {payment && (
                                                <AppealInfo
                                                    title="Сумма для оплаты"
                                                    description={`${payment.amount} P`}
                                                />
                                            )}
                                        </Box>
                                    )}
                                    {isClient && (
                                        <Dropdown
                                            placement="bottomRight"
                                            trigger={['click']}
                                            overlayStyle={{
                                                background: '#fff',
                                                borderRadius: '12px',
                                                padding: 8,
                                                boxShadow:
                                                    '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
                                            }}
                                            overlay={
                                                <Box>
                                                    {result?.inquire.status_id < 7 && (
                                                        <Typography.Text
                                                            className={style.dropDownEl}
                                                            onClick={() => openCancelModal(true)}
                                                        >
                                                            Отозвать заявку
                                                        </Typography.Text>
                                                    )}
                                                    <Typography.Text
                                                        className={style.dropDownEl}
                                                        onClick={() =>
                                                            downloadSignedRequest(
                                                                result?.inquire.request_id,
                                                            )
                                                        }
                                                    >
                                                        Скачать запрос и подпись
                                                    </Typography.Text>
                                                </Box>
                                            }
                                        >
                                            <Box>
                                                <EllipsisOutlined
                                                    style={{
                                                        transform: 'rotate(90deg)',
                                                        fontSize: '20px',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Box>
                                        </Dropdown>
                                    )}
                                </Box>
                                <Box margin="20px 0 0 0" width={'80%'} padding="0px 32px">
                                    <Typography.Text>{result?.inquire.message}</Typography.Text>
                                </Box>
                                {}
                                {!!result?.chats.length && (
                                    <AppealChat
                                        chats={result?.chats}
                                        isClient={isClient}
                                        inquire={result.inquire}
                                    />
                                )}
                                {isClient ? (
                                    <>
                                        {result?.inquire.status_id === 4 || isPayWait === 1 ? (
                                            <AppealMessage
                                                id={id || '0'}
                                                getAppeal={getAppeal}
                                                isPayWait={isPayWait}
                                                payment={payment}
                                            />
                                        ) : (
                                            <Box height={32} />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {![4, 7, 9, 254].includes(result?.inquire.status_id) ? (
                                            <AppealOperatorChat
                                                inquire={result.inquire}
                                                getAppeal={getAppeal}
                                                payment={payment}
                                            />
                                        ) : (
                                            <Box height={32} />
                                        )}
                                    </>
                                )}
                            </Card>
                        </TabPane>
                        <TabPane
                            tab="Документы"
                            key="2"
                            disabled={!documents.length && !chatDocuments.length}
                        >
                            <Card>
                                <Box direction="column">
                                    {!!documents.length && (
                                        <DateTimeLine date={result?.inquire.ts_created} />
                                    )}
                                    {documents.map((item: any) => (
                                        <Box margin="20px 0px 10px 20px">
                                            <Document
                                                document={{
                                                    ...item,
                                                    name: item.file_name,
                                                    size: item.file_size,
                                                }}
                                                nameMaxLength={200}
                                                downloading={downloadedId === item.id}
                                                prefix="file_name"
                                                onClick={(doc: any) =>
                                                    downloadFile(doc.id, doc.name)
                                                }
                                            />
                                        </Box>
                                    ))}
                                    {chatDocuments.map((cid: any) => (
                                        <>
                                            <DateTimeLine date={cid.ts_created} />
                                            {(cid.documents || []).map((item: any) => (
                                                <Box margin="20px 0px 10px 20px">
                                                    <Document
                                                        document={{
                                                            ...item,
                                                            name: item.file_name,
                                                            size: item.file_size,
                                                        }}
                                                        downloading={downloadedId === item.id}
                                                        nameMaxLength={300}
                                                        prefix="file_name"
                                                        onClick={(doc: any) =>
                                                            downloadFile(doc.id, doc.name)
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                        </>
                                    ))}
                                </Box>
                                <Box width={100} padding="20px 0px 0px 0px">
                                    <IconButton onClick={dowloadAll} className={style.buttonMargin}>
                                        Скачать все
                                    </IconButton>
                                </Box>
                            </Card>
                        </TabPane>
                    </Tabs>
                </PageTabs>
            </>
        </PageContainer>
    )
}
