import { FC, useRef } from 'react'
import { Input } from 'antd'
import { DropdownSelector2 } from '../../../../ui'
import { userTypeIdPicker } from '../../../../helpers'
import { userTypes } from '../../../../configuration/systemTypesConfig'
import style from './style.m.less'
import { Box } from '../../../../ui/Box'

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    userType: number
    setUserType: (value: number) => void
}

const fieldNamer = (ut: number) => {
    switch (ut) {
        case 1: // ИП
            return {
                l: 'ИНН',
                ln: 'personal_inn',
                lMaxLen: 12, // Укажите ИНН организации: 10 цифр для юридических лиц || 12 цифр для ИП
                lkey: '05979071-632c-44fd-8fa8-dfec5848fd94',
                m: 'ОГРНИП',
                mn: 'ogrn',
                mMaxLen: 15, // Укажите ОГРН (13 цифр) или ОГРНИП (15 цифр)
                mkey: 'fd0e8684-4524-4e9d-aa31-180386e92ef4',
                r: 'Номер ЛС',
                rn: 'personal_account_number',
                rMaxLen: 10,
                rkey: '520fa7b0-b619-48e3-96ad-01717dcb4776',
            }
        case 2:
            return {
                l: 'КПП',
                ln: 'kpp',
                lMaxLen: 9, // КПП - 9
                lkey: '9f458cd9-16ab-4c70-b3da-43ce2c894cdd',
                m: 'ОГРН',
                mn: 'ogrn',
                mMaxLen: 13, // Укажите ОГРН (13 цифр) или ОГРНИП (15 цифр)
                mkey: '9d9ae87b-7da8-4f49-9a63-fcf14f55e461',
                r: 'Номер ЛС',
                rn: 'personal_account_number',
                rMaxLen: 10,
                rkey: '16875c48-2dde-4ba6-9347-fa04ac4cbba7',
            }
        default:
            // todo: find out это кто?
            return {
                l: '',
                ln: '',
                lMaxLen: 30,
                lkey: '',
                m: '',
                mn: '',
                mMaxLen: 30,
                mkey: '',
                r: '',
                rn: '',
                rMaxLen: 30,
                rkey: '',
            }
    }
}

const OwnerFilters: FC<IFilter> = ({ onChange, userType, setUserType }: IFilter) => {
    const { l, ln, lMaxLen, lkey, r, rn, rMaxLen, rkey, m, mn, mMaxLen, mkey } =
        fieldNamer(userType)
    const divBlock: any = useRef(null)
    const isIndividual = userType === 0
    const isLegal = userType === 2

    const updateFilters = (data: any, key: string) => {
        onChange(data, key)
    }

    return (
        <div className={style.filter} ref={divBlock}>
            <Box margin="24px 0px 0px 0px">
                <div className={style.label}>Тип владельца</div>
                <DropdownSelector2
                    className={style.dropdown}
                    options={userTypes}
                    onChange={(value) => {
                        updateFilters(value, 'user_type')
                        setUserType(userTypeIdPicker(value))
                    }}
                />
            </Box>
            <Box direction="row" justify="space-between" margin="15px 0px 0px 0px">
                {isIndividual && (
                    <div className={style.filterItem}>
                        <div className={style.label}>Номер лицевого счета</div>
                        <Input
                            placeholder="Введите номер ЛС"
                            key="ab2ca401-cd08-455a-94e9-8be0e2f47a00"
                            className={style.inputLarge}
                            maxLength={10}
                            onChange={(event) =>
                                updateFilters(event.target.value, 'personal_account_number')
                            }
                        />
                    </div>
                )}

                {isLegal && (
                    <>
                        <div className={style.filterItem}>
                            <div className={style.label}>Сокращенное название организации</div>
                            <Input
                                placeholder="Введите cокращенное название организации"
                                key="ead35950-6987-480b-9270-e2464e540970"
                                maxLength={30}
                                className={style.inputMiddle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'company_name_short')
                                }
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>ИНН</div>
                            <Input
                                placeholder="Введите ИНН"
                                key="fa90f898-21d0-4353-99ff-6fc4587dea2c"
                                maxLength={10}
                                className={style.inputMiddle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'company_inn')
                                }
                            />
                        </div>
                    </>
                )}

                {!isIndividual && !isLegal && (
                    <>
                        <div className={style.filterItem}>
                            <div className={style.label}>Фамилия</div>
                            <Input
                                placeholder="Введите фамилию"
                                key="4bf4dc8d-e175-4ad2-99d8-212eb0477a53"
                                className={style.inputLittle}
                                maxLength={30}
                                onChange={(event) => updateFilters(event.target.value, 'last_name')}
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>Имя</div>
                            <Input
                                placeholder="Введите имя"
                                key="9855f743-9f2f-44f2-a5cb-32da737abed2"
                                className={style.inputLittle}
                                maxLength={30}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'first_name')
                                }
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>Отчество</div>
                            <Input
                                placeholder="Введите отчество"
                                key="69f979b8-4f4e-48af-9b98-322ad76bbe03"
                                maxLength={30}
                                className={style.inputLittle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'middle_name')
                                }
                            />
                        </div>
                    </>
                )}
            </Box>
            {!isIndividual && (
                <Box direction="row" justify="space-between" margin="15px 0px 0px 0px">
                    <div className={style.filterItem}>
                        <div className={style.label}>{l}</div>
                        <Input
                            placeholder={`Введите ${l}`}
                            key={lkey}
                            className={style.inputLittle}
                            maxLength={lMaxLen}
                            onChange={(event) => updateFilters(event.target.value, ln)}
                        />
                    </div>
                    <div className={style.filterItem}>
                        <div className={style.label}>{m}</div>
                        <Input
                            placeholder={`Введите ${m}`}
                            key={mkey}
                            maxLength={mMaxLen}
                            className={style.inputLittle}
                            onChange={(event) => updateFilters(event.target.value, mn)}
                        />
                    </div>
                    <div className={style.filterItem}>
                        <div className={style.label}>{r}</div>
                        <Input
                            placeholder={`Введите ${r}`}
                            key={rkey}
                            maxLength={rMaxLen}
                            className={style.inputLittle}
                            onChange={(event) => updateFilters(event.target.value, rn)}
                        />
                    </div>
                </Box>
            )}
        </div>
    )
}

export default OwnerFilters
