import { FC } from 'react'
import style from './style.m.less'

export interface IImportButton {
    value: string
    icon?: React.ReactNode
    customStyle?: React.CSSProperties
    customIconStyle?: React.CSSProperties
    noMargin?: boolean
    isDisabled?: boolean
    onClick?: () => void
}

const ImportButton: FC<IImportButton> = ({
    value,
    icon,
    customStyle,
    customIconStyle,
    onClick,
    noMargin,
    isDisabled,
    ...rest
}) => {
    return (
        <button
            onClick={onClick}
            style={customStyle}
            className={isDisabled ? style.importDisabled : style.importButton}
            {...rest}
        >
            <div style={customIconStyle} className={style.icon}>
                {icon}
            </div>
            <div className={noMargin ? undefined : style.content}>{value}</div>
        </button>
    )
}

export default ImportButton
