import { forwardRef, useEffect, useState, useImperativeHandle, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ConfigProvider, Table as UiTable } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import useRequest from '../../../hooks/useRequest'
import { useDebounce } from '../../../hooks'
import { Box } from '../../../ui'
import { getColumns } from '../../helpers'
import { getGenericUsersList, getAdminsUsersList } from '../../config'
import { TableRef, ITypeOption, ELabels, PaginationType, ITable, Filters } from '../../types'
import Filter from '../Filter/Filter'

import style from './style.m.less'

const typeOptions: ITypeOption[] = [
    { label: ELabels.FIZ, id: 0 },
    { label: ELabels.YUR, id: 1 },
    { label: ELabels.IP, id: 2 },
]

const defaultType = typeOptions[0].label

const typeIndex = {
    'Физические лица': 0,
    'Юридические лица': 2,
    ИП: 1,
    1: ELabels.IP,
    2: ELabels.YUR,
    0: ELabels.FIZ,
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

const defaultFilters = {
    email: '',
    full_name: '',
    inn: '',
    login: '',
    snils: '',
    user_role: 0,
    user_type: typeIndex[defaultType],
}

const Table = forwardRef<TableRef, ITable>(({ currentUsers, subTabKey }, ref) => {
    const [typeOption, setTypeOption] = useState(defaultType)
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<Filters>(defaultFilters)

    const location = useLocation()
    const navigate = useNavigate()

    const isGeneric = currentUsers !== 'Административные пользователи'
    const shouldDisplayExtraFilters = currentUsers === 'Пользователи'

    useEffect(() => {
        if (subTabKey) {
            if (subTabKey === '0') {
                setTypeOption('Физические лица')
            } else if (subTabKey === '1') {
                setTypeOption('ИП')
            } else if (subTabKey === '2') {
                setTypeOption('Юридические лица')
            }
        }
    }, [subTabKey])

    const debouncedFilters = useDebounce(filters)

    const {
        fetch: fetchGeneric,
        result: resultGeneric,
        isLoading: isLoadingGeneric,
    } = useRequest(getGenericUsersList)
    const {
        fetch: fetchAdmins,
        result: resultAdmins,
        isLoading: isLoadingAdmins,
    } = useRequest(getAdminsUsersList)

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        const isNotPhysical =
            typeIndex[typeOption] === 0 &&
            location.pathname !== '/users' &&
            location.pathname !== '/users/admins/physical' &&
            isGeneric

        if (isNotPhysical) {
            return
        }

        ;(isGeneric ? fetchGeneric : fetchAdmins)({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters,
            },
        })
    }

    const changeTypeOption = (label: ELabels) => {
        setTypeOption(label)
        if (label === 'Физические лица') {
            navigate('/users/admins/physical')
        } else if (label === 'Юридические лица') {
            navigate('/users/admins/legal')
        } else {
            navigate('/users/admins/individual')
        }
    }

    useEffect(() => {
        setFilters({})
        setTypeOption(defaultType)
        getData({ ...DEFAULT_PAG, filters: defaultFilters })
    }, [isGeneric])

    useEffect(() => {
        const filtersAreNotEmpty = debouncedFilters && Object.keys(debouncedFilters).length > 0

        if (!filtersAreNotEmpty) {
            return
        }

        getData({
            page: 1,
            pageSize: pagination.pageSize,
            filters: { ...debouncedFilters, user_type: typeIndex[typeOption] },
        })
        setPagination({ page: 1, pageSize: pagination.pageSize })
    }, [debouncedFilters])

    useEffect(() => {
        setFilters({
            user_type: typeIndex[typeOption],
        })
        getData({
            page: 1,
            pageSize: pagination.pageSize,
            filters: { ...filters, user_type: typeIndex[typeOption] },
        })
        setPagination({ page: 1, pageSize: pagination.pageSize })
    }, [typeOption])

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
    }

    useImperativeHandle(ref, () => ({
        updateFilters: (data) => {
            setTypeOption(typeIndex[data])
        },
    }))

    const dataSource = useMemo(() => {
        const result = isGeneric ? resultGeneric : resultAdmins

        if (!result) {
            return []
        }

        return result.users?.map((user: object, index: number) => ({
            ...user,
            key: `id${index}`,
        }))
    }, [resultAdmins, resultGeneric])

    const columns = getColumns(typeIndex[typeOption], isGeneric)

    return (
        <div className={style.opened}>
            <Filter
                isGeneric={isGeneric}
                filters={filters}
                onChange={updateFilters}
                typeIndex={typeIndex[typeOption]}
            />
            {shouldDisplayExtraFilters && (
                <div className={style.selector}>
                    {typeOptions.map(({ label, id }) => (
                        <Box direction="row" key={id}>
                            <div
                                className={
                                    typeOption === label ? style.greenToolSelected : style.greenTool
                                }
                                onClick={() => changeTypeOption(label)}
                            >
                                {label}
                            </div>
                            <div className={style.spaceBar}></div>
                        </Box>
                    ))}
                </div>
            )}
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <UiTable
                        dataSource={dataSource}
                        columns={columns}
                        rowClassName={(record) => (record.has_update ? style.rowBold : '')}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, pageSize) => {
                                getData({
                                    page,
                                    pageSize,
                                    filters: { user_type: typeIndex[typeOption], ...filters },
                                })
                                setPagination({ page, pageSize })
                            },
                            total: isGeneric ? resultGeneric?.total : resultAdmins?.total,
                            current: pagination.page,
                            pageSize: pagination.pageSize,
                            showSizeChanger: true,
                        }}
                        loading={isGeneric ? isLoadingGeneric : isLoadingAdmins}
                        onRow={({ uid }) => ({
                            onClick: () => {
                                navigate(`/user/${uid}`)
                            },
                        })}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
})

export default Table
