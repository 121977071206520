import { FC, useEffect } from 'react'
import { DropdownSelector } from '../../../../../ui'
import { Form, Input } from 'antd'
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload } from 'react-simple-captcha'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface IStepOne {
    isRestorePassword: boolean
    captchaRef: React.LegacyRef<Input> | undefined
    handleTypeChange: (value: any) => void
}

const userTypes = [
    { value: 'Физическое лицо', id: '60df8c96-6af7-448f-9308-2c0f815a1e37' },
    { value: 'Юридическое лицо', id: '8212377f-4b14-4e93-b2d1-fe76307b7eec' },
    { value: 'Индивидуальный предприниматель', id: '34ce7be6-b338-4e5b-951c-ef2ecade5da0' },
]

const StepOne: FC<IStepOne> = ({ captchaRef, handleTypeChange, isRestorePassword }) => {
    const { t } = useTranslation()

    useEffect(() => {
        loadCaptchaEnginge(5)
    }, [])

    return (
        <>
            {!isRestorePassword && (
                <div className={style.formBlock}>
                    <div>{t('login.signUpAs')}</div>
                    <div className={style.inputBlock}>
                        <Form.Item name="userType">
                            <DropdownSelector options={userTypes} onChange={handleTypeChange} />
                        </Form.Item>
                    </div>
                </div>
            )}

            <Form.Item
                name="login"
                rules={[
                    {
                        required: true,
                        message: t('login.pleaseLogin'),
                    },
                    {
                        pattern: new RegExp(/^[a-zA-Z0-9_]+$/),
                        message:
                            'Логин должен содержать только латинские буквы, цифры и подчеркивания',
                    },
                ]}
            >
                <div className={style.formBlockShort}>
                    <div>{t('login.login')}</div>
                    <div className={style.inputBlock}>
                        <Input minLength={5} maxLength={15} className={style.input} />
                    </div>
                </div>
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true, message: t('login.pleaseEmail') }]}>
                <div className={style.formBlockShort}>
                    <div>{t('login.email')}</div>
                    <div className={style.inputBlock}>
                        <Input
                            type={'email'}
                            minLength={6}
                            maxLength={30}
                            className={style.input}
                        />
                    </div>
                </div>
            </Form.Item>

            <Form.Item
                name="captcha"
                rules={[{ required: true, message: t('login.pleaseCaptcha') }]}
            >
                <div className={style.formBlockShort}>
                    <div>{t('login.enterCaptcha')}</div>
                    <div className={style.inputBlock}>
                        <Input ref={captchaRef} className={style.input} />
                        <div className={style.captcha}>
                            <LoadCanvasTemplateNoReload />
                        </div>
                    </div>
                </div>
            </Form.Item>
        </>
    )
}

export default StepOne
