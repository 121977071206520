import { RequestTypes } from '../hooks/useRequest'

export const BILLING_API_PREFIX = '/api/billing/v1/'
export const BILLING_BALANCES = 'account-balances'
export const BILLING_DEPOSIT = 'admin-deposit'
export const BILLING_WITHDRAW = 'admin-withdraw'

export const getBalances = {
    link: `${BILLING_API_PREFIX}${BILLING_BALANCES}`,
    method: RequestTypes.get,
}

export const postDeposit = {
    link: `${BILLING_API_PREFIX}${BILLING_DEPOSIT}`,
    method: RequestTypes.post,
}

export const postWithdraw = {
    link: `${BILLING_API_PREFIX}${BILLING_WITHDRAW}`,
    method: RequestTypes.post,
}

export const payTypePicker = (typeId: number): string => {
    switch (typeId) {
        case 62:
            return 'Ручное пополнение платежного счета'
        case 56:
            return 'Автоматическое пополнение платежного счета'
        case 63:
            return 'Прочие поступления денежных средств'
        case 64:
            return 'Прочие списания денежных средств'
        default:
            return 'Ручное пополнение платежного счета'
    }
}

export const payTypeIdPicker = (name: string): number => {
    switch (name) {
        case 'Ручное пополнение платежного счета':
            return 62
        case 'Автоматическое пополнение платежного счета':
            return 56
        case 'Прочие поступления денежных средств':
            return 63
        case 'Прочие списания денежных средств':
            return 64
        default:
            return 62
    }
}
