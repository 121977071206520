import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Transfer: FC<IIconProps> = ({ size = 20 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 15L11 13L9 11L11 13H5"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 8.4V12.4C18 16.4 16.4 18 12.4 18H7.6C3.6 18 2 16.4 2 12.4V7.6C2 3.6 3.6 2 7.6 2H11.6"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 8H14.7273C12.2727 8 12 7.72727 12 5.27273V2L18 8Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
