import { FC, useEffect, useState } from 'react'
import { Tabs } from 'antd'
import {
    Pagination,
    CertificateCard,
    Card,
    ContractCard,
    LoadingContainer,
    ThirdPartyToolsCard,
} from '../../../ui'
import { Box } from '../../../ui/Box/Box'
import { ControlsBlock } from './components'
import { getCertificates, getGreenContracts, getOther } from '../../config'
import useRequest from '../../../hooks/useRequest'
import { monthPicker, statusIdPicker } from '../../../helpers'
import { useAppSelector } from '../../../../store/hooks'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { FolderCross } from '../../../../assets/icons'
import { EUserRole } from '../../../../constants/profile'
import { IContract, IOther } from './types'
import moment from 'moment'
import style from './style.m.less'

// TODO REFACT MANY PROPS FOR CHILDRENS
const { TabPane } = Tabs

const shortCertificatesList = [
    { label: 'Активные', id: '5d0bbe80-2e0e-4698-8daf-7df24ce6ba83' },
    { label: 'Погашенные', id: 'fe794a9b-3213-40bc-82b4-aac2919f177e' },
    { label: 'Заблокированные', id: 'gq894a9b-3213-40bc-82b4-aac2919f177e' },
    { label: 'Истек срок действия', id: '7q1bbe80-2e0e-4698-8daf-7df24ce6ba83' },
]

const certificatesList = [
    ...shortCertificatesList,
    { label: 'Удаленные', id: 'bd3bbe80-2e0e-4698-8daf-7df24ce6ba83' },
    { label: 'На ЭТП', id: '5g194a9b-3213-40bc-82b4-aac2919f177e' },
]

const contractsList = [
    { label: 'Активные', id: '5b7f77bf-a3ec-4441-9b13-e46eabc3c027' },
    { label: 'Заблокированные', id: '87f3386e-0358-4f90-bc8b-b655ce181bb8' },
]

const otherList = [
    { label: 'Активные', id: '5d0bbe80-2e0e-4698-8daf-7df24ce6ba83' },
    { label: 'Погашенные', id: 'fe794a9b-3213-40bc-82b4-aac2919f177e' },
]

const tabsList = [
    { label: 'Сертификаты происхождения', key: 0, content: <></> },
    { label: 'Зеленые договоры', key: 1, content: <></> },
    { label: 'Сторонние инструменты', key: 2, content: <></> },
]

interface IRegisteredLayout {
    isUserType?: boolean
    userRole: number
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    filters?: any
}

const RegisteredLayout: FC<IRegisteredLayout> = ({ isUserType, userRole }) => {
    const [isAdminType, setIsAdminType] = useState<boolean>(false)
    const [currentTool, setCurrentTool] = useState(tabsList[0].label)
    const optionsList =
        currentTool === 'Сертификаты происхождения' && isAdminType
            ? certificatesList
            : currentTool === 'Сертификаты происхождения'
            ? shortCertificatesList
            : currentTool === 'Зеленые договоры'
            ? contractsList
            : otherList
    const [option, setOption] = useState(optionsList[0].label)

    const { renewableEnergyTypesMap } = useAppSelector(selectDictionariesMaps) ?? {}

    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    const {
        fetch: fetchCertificates,
        result: certificates,
        isLoading: isLoadingCertificates,
    } = useRequest(getCertificates)
    const {
        fetch: fetchContracts,
        result: contracts,
        isLoading: isLoadingContracts,
    } = useRequest(getGreenContracts)
    const { fetch: fetchOther, result: others, isLoading: isLoadingOther } = useRequest(getOther)

    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>({})

    const isGreenContracts = currentTool === 'Зеленые договоры'
    const isCertificate = currentTool === 'Сертификаты происхождения'

    const result = isGreenContracts ? contracts : isCertificate ? certificates : others
    const isLoading = isLoadingCertificates || isLoadingContracts || isLoadingOther

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        ;(isGreenContracts ? fetchContracts : isCertificate ? fetchCertificates : fetchOther)({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters,
            },
            errorCodeMessage: true,
        })
    }

    useEffect(() => {
        if (Object.keys(filters).length) {
            getData({ filters })
        }
    }, [currentTool])

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        let updatedFilter
        if (key === 'account_number') {
            const filterCopy = { ...filters }
            delete filterCopy.generator_id
            updatedFilter = {
                ...filterCopy,
                ...(obj ? { ...obj } : { [key]: data }),
            }
        } else {
            updatedFilter = {
                ...filters,
                ...(obj ? { ...obj } : { [key]: data }),
            }
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        getData({ page: 1, pageSize: pagination.pageSize, filters: updatedFilter })
    }

    const onChange = (value: string) => {
        setOption(value)
        updateFilters(statusIdPicker(value), 'status_id')
    }

    useEffect(() => {
        const isIncluded = [
            EUserRole.Operator,
            EUserRole.Administrator,
            EUserRole.SuperAdmin,
        ].includes(userRole)
        if (isIncluded !== isAdminType) {
            setIsAdminType(isIncluded)
        }
    }, [userRole])

    return (
        <div className={style.mainWrapper}>
            <Tabs
                defaultActiveKey="0"
                onChange={(activeKey) => {
                    const activeNumber = Number(activeKey)
                    setCurrentTool(tabsList[activeNumber].label)
                }}
                className={style.tabs}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={label} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
            <ControlsBlock
                options={optionsList}
                currentOption={option}
                toolType={currentTool}
                isUserType={isUserType}
                onChange={onChange}
                total={result?.total}
                isDisabled={!result?.certificates?.length}
                onChangeFilters={updateFilters}
                isAdminType={isAdminType}
            />
            <LoadingContainer isLoading={isLoading}>
                <div className={style.cardsWrapper}>
                    {result?.certificates?.map((item, index) => {
                        const {
                            certificate_id,
                            certificate_number,
                            renewable_energy_type_id,
                            status_id,
                            volume,
                            generator_name,
                            ts_created,
                            ts_expired,
                            month,
                            year,
                            is_owner,
                            is_etp,
                            is_deposit,
                            generator_id,
                        } = item
                        const productionPeriod = `${monthPicker(month)} ${year}`

                        return (
                            <div key={certificate_number}>
                                <CertificateCard
                                    isRegistered
                                    isUserType={isUserType}
                                    toolType={currentTool}
                                    certificateNumber={certificate_number}
                                    option={status_id}
                                    energyType={getEnergyType(renewable_energy_type_id)}
                                    capacity={volume}
                                    productionPeriod={productionPeriod}
                                    generatorName={generator_name}
                                    generator_id={generator_id}
                                    certificateId={certificate_id}
                                    isOwner={is_owner}
                                    isEtp={is_etp}
                                    isDeposit={is_deposit}
                                    createdTs={moment(ts_created).format('DD.MM.YYYY')}
                                    expiredTs={moment(ts_expired).format('DD.MM.YYYY')}
                                    {...item}
                                />
                            </div>
                        )
                    })}
                    {result?.contracts?.map((contract: IContract) => (
                        <ContractCard
                            isRegistered
                            isOwner={false}
                            contract={contract}
                            key={contract.contract_id}
                        />
                    ))}
                    {result?.ext_instruments?.map((tool: IOther) => (
                        <ThirdPartyToolsCard
                            isRegistered
                            isOwner={false}
                            tool={tool}
                            key={tool.id}
                        />
                    ))}
                </div>
                {!result?.total && (
                    <Card className={style.card}>
                        <Box>
                            <FolderCross />
                            <div className={style.textAlert}>Ничего не найдено</div>
                        </Box>
                    </Card>
                )}
            </LoadingContainer>

            {result?.total > 10 && (
                <Pagination
                    paginationChange={(page, pageSize) => {
                        getData({ page, pageSize, filters })
                        setPagination({ page, pageSize })
                    }}
                    total={result?.total}
                    current={pagination.page}
                    pageSize={pagination.pageSize}
                    showSizeChanger={true}
                    customStyle={{ width: '100%' }}
                />
            )}
        </div>
    )
}

export default RegisteredLayout
