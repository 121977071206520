import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Document from './components/Document'
import { IconButton } from '../../../ui'
import { Export } from '../../../../assets/icons'
import { Upload, message } from 'antd'
import SignUtils from '../../../../utils/sign/SignUtils'
import type { UploadProps } from 'antd/es/upload/interface'
import request from '../../../../services/request'
import { errorHandler, checkExtension } from '../../../../components/helpers'

import style from './style.m.less'

interface IDocumentBlock {
    fileList: { list: any[]; total: number }
    updateFileList: (file: any, id: string, hash: string, comment: string) => void
    deleteFromFileList: (uid: string) => void
    className?: string
    withoutComment?: boolean
    noCheckExtantion?: boolean
    maxFileSize?: number
}

const DocumentsBlock: FC<IDocumentBlock> = ({
    fileList,
    updateFileList,
    deleteFromFileList,
    className = '',
    withoutComment = false,
    noCheckExtantion = false,
    maxFileSize,
}) => {
    const { t } = useTranslation()

    const { list, total } = fileList

    const SIZE_ERR_TEXT = 'Суммарный объем приложенных файлов к анкете не должен превышать 50 МБ'
    const SIZE_ONE_ERR_TEXT = `Размер загружаемого файла не должен превышать ${maxFileSize} МБ`
    const RESPONSE_ERR_TEXT = 'Ошибка при загрузке файла'
    const MAX_SIZE = 50000000

    const uploadProps: UploadProps = {
        showUploadList: false,
        customRequest: async (options) => {
            function readFileAsync(file: any) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()

                    reader.onload = () => {
                        resolve(reader.result)
                    }

                    reader.onerror = reject

                    reader.readAsArrayBuffer(file)
                })
            }

            const { file } = options
            const SIZES_SUMMARY = total + file.size

            if (maxFileSize && maxFileSize * 1000 < file.size) {
                message.error(SIZE_ONE_ERR_TEXT)
                return
            }

            if (SIZES_SUMMARY > MAX_SIZE) {
                message.error(SIZE_ERR_TEXT)
            } else if (list.length >= 10) {
                message.error(errorHandler('ERR_APL_49'))
            } else if (!checkExtension(file.name) && !noCheckExtantion) {
                message.error(errorHandler('ERR_APL_48'))
            } else {
                try {
                    const result = await readFileAsync(file)
                    const res = await request.post('/api/documents/v1/file', result, {
                        headers: {
                            Filename: encodeURI(file.name),
                            'Service-ID': 2,
                        },
                    })

                    if (res.status === 200) {
                        const id = res.data.id
                        const hash = await SignUtils.hashBytes(result)
                        const comment = ''
                        updateFileList(file, id, hash, comment)
                    } else {
                        throw new Error(RESPONSE_ERR_TEXT)
                    }
                } catch (err) {
                    console.log('Error: ', err)
                }
            }
        },
    }

    return (
        <div className={className || style.documentsBlock}>
            {!!list.length &&
                list?.map((item) => (
                    <Document
                        key={item.id}
                        file={item}
                        deleteFromFileList={deleteFromFileList}
                        withoutComment={withoutComment}
                    />
                ))}
            <div className={style.uploadDocumentFrame}>
                <Upload {...uploadProps}>
                    <IconButton
                        icon={<Export />}
                        customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                    >
                        {t('editMyProfile.uploadDoc')}
                    </IconButton>
                </Upload>
                <div className={style.info}>{t('editMyProfile.uploadNecessaryDocs')}</div>
            </div>
        </div>
    )
}

export default DocumentsBlock
