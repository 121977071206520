import { FC, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { Alert } from 'antd'
import { Import, Clock, TickCircle } from '../../assets/icons'
import {
    BackArrow,
    CenteredSpin,
    ContractCard,
    IconButton,
    Box,
    OperationHistoryMapper,
} from '../ui'
import { EHistoryType, IOperationConfig } from '../ui/OperationHistoryMapper/types'
import { useContractData } from '../hooks/useContractData'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { monthPicker, currencyFormat, consumersLabel } from '../helpers'
import { getMyContractData, getPublicContractData } from './config'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getDictionaries, selectDictionariesMaps } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'
import { EUserRole } from '../../constants/profile'

import style from './style.m.less'
import { Consumption } from './types'
import BlockModal from './components/BlockModal/BlockModal'
import { PaymentModal, SubModal, BlockingBlock } from './components'

const getRealizationConfig = (
    isAdminType: boolean,
    consumption: Consumption,
    is_buyer_tax_payer: boolean,
    contract_number: string,
    buyer_uid: string,
    fee: number,
    isSeller: boolean,
    seller_name: string,
    disableRealization: boolean,
    buttonAction?: () => void,
    buttonActionAboutRealisation?: () => void,
) => {
    const convertDate = (date: Moment | string) =>
        date ? moment(date).format('DD.MM.YYYY') : 'Не определена'

    const { payment_status, pay_before, period, volume, contract_id } = consumption

    const consumptionState = {
        contract_id,
        contract_number,
        period_date: period,
        fee,
        seller_name,
        volume,
        buyer_uid,
    }

    const isNotPayed = payment_status === 0
    const isReserved = payment_status === 1
    const isPayed = payment_status === 2

    // если админ
    if (isAdminType) {
        return {
            header: (
                <div className={style.volcanoBlock}>
                    <Clock />
                    <div>{`Оплатить и указать потребителей до ${convertDate(pay_before)}`}</div>
                </div>
            ),
        }
        // если продавец, оплачено и комиссию платит покупатель
    } else if (isPayed && isSeller && is_buyer_tax_payer) {
        return {
            header: (
                <div className={style.greenBlock}>
                    <TickCircle />
                    <div>Реализация прошла успешно</div>
                </div>
            ),

            confirm: (
                <IconButton
                    onClick={buttonActionAboutRealisation}
                    customStyle={{ marginTop: '24px' }}
                >
                    Подробнее о реализации
                </IconButton>
            ),
        }
        // если продавец и не оплачено
    } else if (isNotPayed && isSeller) {
        return {
            header: (
                <div className={style.volcanoBlock}>
                    <Clock />
                    <div>{`Оплатить до ${convertDate(pay_before)}`}</div>
                </div>
            ),
            confirm: (
                <IconButton onClick={buttonAction} customStyle={{ marginTop: '24px' }}>
                    Перейти к оплате
                </IconButton>
            ),
        }
    } else if (isReserved && isSeller) {
        return {
            header: (
                <div className={style.greenBlock}>
                    <TickCircle />
                    <div>Денежные средства зарезервированы</div>
                </div>
            ),
            alert: (
                <div>
                    Вы выполнили все необходимые условия для успешной реализации АГ по ЗД. Ожидается
                    выполнение условий другими участниками
                </div>
            ),
        }
    }
    //если оплачено, потребители не указаны и плательщик - продавец
    else if (!isSeller && isReserved && !is_buyer_tax_payer) {
        return {
            header: (
                <div className={style.volcanoBlock}>
                    <Clock />
                    <div>{`Указать потребителей до ${convertDate(pay_before)}`}</div>
                </div>
            ),
            alert: (
                <div className={style.modalAlertText}>
                    Добавлен новый объем атрибутов генерации. Для успешной реализации АГ по ЗД
                    необходимо указать Потребителей.
                </div>
            ),
            confirm: (
                <Link to={`/realization-ag`} state={consumptionState}>
                    <IconButton customStyle={{ marginTop: '24px' }}>
                        Указать потребителей
                    </IconButton>
                </Link>
            ),
        }
        //если не оплачено, потребители не указаны и плательщик - покупатель
    } else if (isNotPayed && is_buyer_tax_payer) {
        return {
            header: (
                <div className={style.volcanoBlock}>
                    <Clock />
                    <div>{`Указать потребителей и оплатить до ${convertDate(pay_before)}`}</div>
                </div>
            ),
            alert: (
                <div className={style.modalAlertText}>
                    <div>Добавлен новый объем атрибутов генерации</div>
                    <div>Для успешной реализации АГ по ЗД необходимо:</div>
                    <div>1) указать Потребителей;</div>
                    <div>2) внести плату за реализацию АГ по ЗД.</div>
                </div>
            ),
            confirm: !disableRealization && (
                <Link to={`/payment-realization-ag`} state={consumptionState}>
                    <IconButton customStyle={{ marginTop: '24px' }}>
                        Перейти к реализации
                    </IconButton>
                </Link>
            ),
            hasTotal: true,
        }
        //если потребители указаны
    } else if (isReserved) {
        return {
            header: (
                <div className={style.greenBlock}>
                    <TickCircle />
                    <div>Потребители указаны</div>
                </div>
            ),
            alert: (
                <div>
                    Вы выполнили все необходимые условия для успешной реализации АГ по ЗД. Ожидается
                    выполнение условий другими участниками
                </div>
            ),
            confirm: (
                <IconButton
                    onClick={buttonActionAboutRealisation}
                    customStyle={{ marginTop: '24px' }}
                >
                    Подробнее об потребителях
                </IconButton>
            ),
        }
        //если оплата прошла
    } else if (isPayed) {
        return {
            header: (
                <div className={style.greenBlock}>
                    <TickCircle />
                    <div>Реализация прошла успешно</div>
                </div>
            ),
            confirm: (
                <IconButton
                    onClick={buttonActionAboutRealisation}
                    customStyle={{ marginTop: '24px' }}
                >
                    Подробнее о реализации
                </IconButton>
            ),
        }
    } else {
        return null
    }
}

const Contract: FC = () => {
    const [blockModalData, setBlockModalData] = useState({
        isVisible: false,
        isUnblock: false,
    })
    const [paymentModalOptions, setPaymentModalOptions] = useState({
        modalIndex: 0,
        isModalOpen: false,
    })
    const [isSubModalModalOpen, setIsSubModalModalOpen] = useState(false)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()

    const contractData = useContractData()

    const { fetch, result, isLoading } = useRequest(
        contractData?.isOwner ? getMyContractData : getPublicContractData,
    )

    const isLoadingDict = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()
    const role = useAppSelector((state) => state?.user?.data?.user_role)
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(role)
    const userId = useAppSelector((state) => state?.user?.data?.uid)

    const { fetch: fetchContractHistory, result: contractHistory } = useRequest({
        link: '/api/audit/v1/event-history-contracts?id=',
        method: RequestTypes.post,
    })
    const contractHistoryParsed: IOperationConfig[] = []

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const getContractHistory = () => {
        if (contractData?.id) {
            fetchContractHistory({
                addToLink: contractData?.id,
            })
        }
    }

    const fetchContractData = () => {
        if (contractData?.id) {
            fetch({
                addToLink: contractData?.id,
            })
        } else {
            fetch({
                addToLink: params?.id,
            })
        }
    }

    useMount(() => {
        if (isLoadingDict !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    useEffect(() => {
        if (contractData) {
            fetchContractData()
            getContractHistory()
        }
    }, [contractData])

    useEffect(() => {
        if (result?.status_id === 3) {
            setBlockModalData((prev) => ({ ...prev, isUnblock: true }))
        }
    }, [result?.status_id])

    if (isLoading || isLoadingDict === ELoading.Loading || !result) {
        return <CenteredSpin />
    }

    const handlePaymentModal = (modalIndex: number) => {
        setPaymentModalOptions({ isModalOpen: !paymentModalOptions.isModalOpen, modalIndex })
    }

    const {
        contract_id,
        contract_number,
        account_number,
        buyer_account,
        seller_account,
        renewable_energy_type_id,
        status_id,
        volume_consume,
        volume_new,
        buyer_uid,
        buyer_name,
        buyer_codes,
        seller_uid,
        seller_name,
        seller_codes,
        tax_payer_type_id,
        invisible_in_the_list,
        is_buyer_tax_payer,
        ts_registration,
        ts_created,
        ts_delivery,
        consumptions,
        block,
        sdd_start_begin_date,
    } = result

    if (contractHistory?.total > 0) {
        const today = moment().startOf(dayUnit)
        const eh = contractHistory?.events?.map(
            (phe: { ts_event: string; payload: any[]; type_event_id: number }) => {
                const parsedDate = moment(phe.ts_event).format(shortFormat)
                const eventDay = moment(parsedDate, shortFormat)

                let title = eventDay.format(extraFormat)

                if (eventDay.isSame(today)) {
                    title = `${t('history.today')}, ${title}`
                }

                if (eventDay.isSame(today.subtract(1, dayUnit))) {
                    title = `${t('history.yesterday')}, ${title}`
                }

                const operation = {
                    id: phe.type_event_id,
                    time: moment(phe.ts_event).format('HH:MM'),
                    value: phe?.payload?.volume_consume,
                }

                return {
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: [operation],
                }
            },
        )

        contractHistoryParsed.push(...eh)
    }

    const isSeller = userId === seller_uid
    const isBuyer = userId === buyer_uid
    const isBuyerOrSeller = isSeller || isBuyer

    const historyContent = (
        <div className={style.historyBlock}>
            <div className={style.header}>{t('myProfile.operationHistory')}</div>
            <OperationHistoryMapper
                config={contractHistoryParsed}
                settings={{ historyType: EHistoryType.Certs }}
                isContract
            />
            {contractHistory?.total > 0 && (
                <Link to={`/contract-history-operations/${contractData?.id}`}>
                    <IconButton customStyle={{ marginTop: '40px' }}>Все операции</IconButton>
                </Link>
            )}
        </div>
    )

    const disableRealization =
        (is_buyer_tax_payer && userId !== buyer_uid) ||
        (!is_buyer_tax_payer && userId !== seller_uid)

    return (
        <div className={style.certificateMain}>
            <div className={style.container}>
                <div className={style.headerBlock}>
                    <div className={style.backBlock}>
                        <BackArrow />
                        <div className={style.mainHeader}>
                            {contractData?.cardLabel || 'Зеленый договор'}
                        </div>
                    </div>
                    <IconButton
                        icon={<Import />}
                        customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                    >
                        {t('certificate.upload')}
                    </IconButton>
                </div>
                <ContractCard
                    isDetailed
                    onBlock={() => setBlockModalData((prev) => ({ ...prev, isVisible: true }))}
                    isUserType={contractData?.isUserType}
                    isOwner={contractData?.isOwner}
                    refreshData={fetchContractData}
                    contract={{
                        contract_id,
                        buyer_account,
                        seller_account,
                        account_number,
                        buyer_name,
                        renewable_energy_type_id,
                        contract_number,
                        status_id,
                        volume_consume,
                        volume_new,
                        seller_name,
                        buyer_codes,
                        buyer_uid,
                        seller_uid,
                        ts_registration,
                        ts_created,
                        ts_delivery,
                        seller_codes,
                        tax_payer_type_id,
                        invisible_in_the_list,
                        is_buyer_tax_payer,
                        sdd_start_begin_date,
                    }}
                />
                <div className={style.wrapper}>
                    <Box>
                        {consumptions?.map((item: Consumption, itemIndex: number) => {
                            const getActualFee = () => {
                                if (isSeller) {
                                    return item.fee ? item.fee : item.seller_fee
                                } else {
                                    return item.buyer_fee
                                }
                            }

                            const actualFee = getActualFee()

                            const config = getRealizationConfig(
                                isAdminType,
                                item,
                                is_buyer_tax_payer,
                                contract_number,
                                buyer_uid,
                                actualFee,
                                isSeller,
                                seller_name,
                                disableRealization,
                                () => handlePaymentModal(itemIndex),
                                () =>
                                    navigate(`/audit/${item?.consumers_audit}`, {
                                        state: {
                                            eventData: {
                                                eventId: 74,
                                                // TODO: убрать хардкод и просвоить всем eventId понятные названия
                                                eventType: 'Указание потребителей по ЗД',
                                            },
                                        },
                                    }),
                            )

                            if (!config) {
                                return null
                            }
                            return (
                                <>
                                    <div className={style.repaymentWrapper}>
                                        {status_id === 3 && (
                                            <BlockingBlock
                                                id={contract_number}
                                                blockingInfo={block}
                                            />
                                        )}
                                        <div className={style.repaymentBlock}>
                                            <Box direction="row" justify="space-between">
                                                <div className={style.repaymentHeader}>
                                                    {'Реализация АГ'}
                                                </div>
                                                {config?.header}
                                            </Box>
                                            {config?.alert && (
                                                <Alert
                                                    className={style.historyAlert}
                                                    message={config?.alert}
                                                    type="info"
                                                    showIcon
                                                />
                                            )}
                                            {(item.payment_status === 1 ||
                                                item.payment_status === 2) &&
                                                !isSeller && (
                                                    <div className={style.objectBlock}>
                                                        <div className={style.infoBlock}>
                                                            Дата реализации
                                                        </div>
                                                        <div>
                                                            {moment(item.period).format(
                                                                'DD.MM.YYYY',
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            <div className={style.objectBlock}>
                                                <div className={style.infoBlock}>
                                                    Период поставки
                                                </div>
                                                <div>{`${monthPicker(
                                                    moment(item.period).month() + 1,
                                                )}, ${moment(item.period).year()}`}</div>
                                            </div>
                                            <div className={style.objectBlock}>
                                                <div className={style.infoBlock}>
                                                    {isSeller || item.payment_status === 0
                                                        ? 'Объем'
                                                        : 'Реализованный объем'}
                                                </div>
                                                <div>{`${currencyFormat(item.volume)} кВт*ч`}</div>
                                            </div>
                                            {isSeller && item.payment_status !== 2 && (
                                                <div className={style.objectBlock}>
                                                    <div className={style.infoBlock}>
                                                        Сумма комиссии
                                                    </div>
                                                    <div>{`${currencyFormat(
                                                        item?.fee || item.seller_fee,
                                                    )} Р`}</div>
                                                </div>
                                            )}
                                            {(item.payment_status === 1 ||
                                                item.payment_status === 2) &&
                                                !isSeller && (
                                                    <>
                                                        <div className={style.objectBlock}>
                                                            <div className={style.infoBlock}>
                                                                Количество потребителей
                                                            </div>
                                                            <div>{`${
                                                                item.consumers_count
                                                            } ${consumersLabel(
                                                                item.consumers_count,
                                                            )}`}</div>
                                                        </div>
                                                        {item.payment_status === 2 && (
                                                            <div className={style.objectBlock}>
                                                                <div className={style.infoBlock}>
                                                                    Сумма комиссии
                                                                </div>
                                                                <div>{`${currencyFormat(
                                                                    actualFee,
                                                                )} ₽`}</div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            {config?.hasTotal && (
                                                <div className={style.objectBlock}>
                                                    <div className={style.infoBlock}>
                                                        Сумма комиссии
                                                    </div>
                                                    <div>{`${currencyFormat(actualFee)} ₽`}</div>
                                                </div>
                                            )}
                                            {config?.confirm}
                                        </div>
                                    </div>
                                    <PaymentModal
                                        seller_fee={item.seller_fee}
                                        buyer_fee={item.buyer_fee}
                                        is_buyer_tax_payer={is_buyer_tax_payer}
                                        consume_period={item.period}
                                        commission_assign={item?.fee || item.seller_fee}
                                        volume={item.volume}
                                        buyer_uid={buyer_uid}
                                        buyer_name={buyer_name}
                                        contract_number={contract_number}
                                        isVisible={
                                            paymentModalOptions.isModalOpen &&
                                            itemIndex === paymentModalOptions.modalIndex
                                        }
                                        onCancel={() => handlePaymentModal(itemIndex)}
                                        onOk={() => {
                                            fetchContractData()
                                            handlePaymentModal(itemIndex)
                                        }}
                                        openSubModal={() => setIsSubModalModalOpen(true)}
                                        contract_id={item.contract_id}
                                    />
                                    <SubModal
                                        isVisible={isSubModalModalOpen}
                                        onClose={() => setIsSubModalModalOpen(false)}
                                    />
                                </>
                            )
                        })}
                    </Box>
                    {isAdminType || isBuyerOrSeller ? historyContent : <></>}
                </div>
            </div>
            <BlockModal
                contractId={contractData?.id}
                modalStatus={blockModalData}
                setModalStatus={setBlockModalData}
            />
        </div>
    )
}

export default Contract
