import { FC } from 'react'

import style from './style.m.less'

interface ConsumersTableProps {
    consumers: string[]
    volumes: number[]
}

export const SeparateConsumersTable: FC<ConsumersTableProps> = ({ consumers, volumes }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.table}>
                <div className={style.header}>Потребители ({consumers?.length})</div>
                <div className={style.row}>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Потребитель
                    </div>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Объем, кВт*ч
                    </div>
                </div>
                {consumers?.map((item, index) => (
                    <div key={index} className={style.row}>
                        <div className={style.column}>{item}</div>
                        <div className={style.column}>{volumes[index]}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
