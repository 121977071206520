import { RequestTypes } from '../hooks/useRequest'

export const AUDIT_API_PREFIX = '/api/audit/v1/'
export const AUDIT_DETAILS = 'event-details'
export const AUDIT_CUSTOM = 'event-custom?id='
export const AUDIT_CERT_CUSTOM = 'certificate-event-custom?id='
export const AUDIT_PAYMENT_TYPES = 'dict/event-payment-types'
export const AUDIT_EVENTS = 'events'

export const getEventDetails = {
    link: `${AUDIT_API_PREFIX}${AUDIT_DETAILS}`,
    method: RequestTypes.get,
}

export const getEventCustom = {
    link: `${AUDIT_API_PREFIX}${AUDIT_CUSTOM}`,
    method: RequestTypes.post,
}

export const getEventCertCustom = {
    link: `${AUDIT_API_PREFIX}${AUDIT_CERT_CUSTOM}`,
    method: RequestTypes.post,
}

export const getAuditEventTypes = {
    link: `${AUDIT_API_PREFIX}${AUDIT_PAYMENT_TYPES}`,
    method: RequestTypes.get,
}

export const getAuditEvent = {
    link: `${AUDIT_API_PREFIX}${AUDIT_EVENTS}`,
    method: RequestTypes.post,
}
