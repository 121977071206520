import { ScriptableContext } from 'chart.js'
import { Line } from 'react-chartjs-2/dist'
import moment, { Moment } from 'moment'
import { graphMonthPicker } from '../../helpers'

interface IGraphUnit {
    period: string
    volume: number
}

interface IGraph {
    stats: IGraphUnit[]
    period: [Moment, Moment]
}

const options = (data: any) => {
    const values = data?.map((el: IGraphUnit) => el.volume) || []
    const maxValue = values.length ? Math.max(...values) : 100000
    const stepSize = Math.round(maxValue / 5)
    const max = maxValue + stepSize
    return {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                position: 'right',
                grid: {
                    color: '#E6F1FF',
                },
                min: 0,
                max,
                ticks: {
                    color: '#8C8C8C',
                    font: {
                        size: 14,
                        weight: '400',
                    },
                    stepSize,
                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#8C8C8C',
                    font: {
                        size: 14,
                        weight: '400',
                    },
                },
            },
        },
    }
}

const generateMonths = (period: [Moment, Moment] | null) => {
    if (!period) {
        return []
    }
    const start = moment(period[0], 'YYYY-MM-DD')
    const end = moment(period[1], 'YYYY-MM-DD')

    if (end.date() === 1) {
        end.add(1, 'day')
    }

    const months = []
    const current = start

    while (current.isSameOrBefore(end)) {
        months.push(current.format('YYYY-MM-DD'))
        current.add(1, 'month')
    }

    return months
}

const generateData = (list: any, period?: [Moment, Moment]) => {
    let labelsGenerated = []
    let data = []
    if (period) {
        const monthGeneratedList = generateMonths(period)
        labelsGenerated = monthGeneratedList
        data = Array(monthGeneratedList.length).fill(0)
    } else {
        // GENERATE AND FILL EMPTY DATA
        data = Array(7).fill(0)
        for (let i = 0; i < 6; i++) {
            labelsGenerated.unshift(
                moment()
                    .startOf('month')
                    .subtract(1 + i, 'month')
                    .format('YYYY-MM-DD'),
            )
        }
        for (let i = 0; i < 1; i++) {
            labelsGenerated.push(
                moment()
                    .startOf('month')
                    .subtract(0 - i, 'month')
                    .format('YYYY-MM-DD'),
            )
        }
    }
    if (list) {
        for (let i = 0; i < list.length; i++) {
            const index = labelsGenerated.findIndex((el: string) => el === list[i].period)

            if (index < 0) {
                continue
            }
            data[index] = list[i].volume
        }
    }

    const labels = labelsGenerated.map((el: string) => graphMonthPicker(moment(el).format('MM')))

    return {
        labels,
        datasets: [
            {
                data,
                fill: true,
                backgroundColor: (context: ScriptableContext<'line'>) => {
                    const ctx = context.chart.ctx
                    const gradient = ctx.createLinearGradient(0, 0, 0, 230)
                    gradient.addColorStop(0, 'rgba(82, 210, 160,0.5)')
                    gradient.addColorStop(1, 'rgba(82, 210, 160,0.07)')
                    return gradient
                },
                borderColor: '#52D2A0',
                borderWidth: 2,
                tension: 0.5,
            },
        ],
    }
}

const Graph = ({ stats, period }: IGraph) => {
    return <Line data={generateData(stats, period)} options={options(stats)} />
}

export default Graph
