import { Divider, Typography } from 'antd'
import { Box } from '../../../../ui/Box/Box'
import style from './style.m.less'
import classNames from 'classnames'
import { Trash } from '../../../../../assets/icons'

type Props = {
    user: any
    unEditable?: boolean
    disabled?: boolean
    onClick?: (d: any) => void
    onDelete?: (d: any) => void
    selected?: boolean
}

export const UserBox = ({ user }: any) => {
    switch (user.user_type) {
        case 0:
            return (
                <Box justify="space-between">
                    <Typography.Text strong>
                        {`${user.last_name} ${user.first_name} ${user.middle_name}`}
                    </Typography.Text>
                    <Box direction="row" align="center">
                        <Typography.Text type="secondary">
                            Номер ЛС {user.personal_account_number}
                        </Typography.Text>
                    </Box>
                </Box>
            )
        case 1:
            return (
                <Box justify="space-between">
                    <Typography.Text strong>
                        {`ИП ${user.last_name} ${user.first_name} ${user.middle_name}`}
                    </Typography.Text>
                    <Box direction="row" align="center">
                        <Typography.Text type="secondary">ИНН {user.personal_inn}</Typography.Text>
                        <Divider type="vertical" style={{ borderColor: '#BFBFBF' }} />
                        <Typography.Text type="secondary">ОГРНИП {user.ogrn}</Typography.Text>
                        <Divider type="vertical" style={{ borderColor: '#BFBFBF' }} />
                        <Typography.Text type="secondary">
                            Номер ЛС {user.personal_account_number}
                        </Typography.Text>
                    </Box>
                </Box>
            )
        case 2:
            return (
                <Box justify="space-between">
                    <Typography.Text strong>{user.company_name_short}</Typography.Text>
                    <Box direction="row" align="center">
                        <Typography.Text type="secondary">ИНН {user.company_inn}</Typography.Text>
                        <Divider type="vertical" style={{ borderColor: '#BFBFBF' }} />
                        <Typography.Text type="secondary">ОГРН {user.ogrn}</Typography.Text>
                        <Divider type="vertical" style={{ borderColor: '#BFBFBF' }} />
                        <Typography.Text type="secondary">КПП {user.kpp}</Typography.Text>
                        <Divider type="vertical" style={{ borderColor: '#BFBFBF' }} />
                        <Typography.Text type="secondary">
                            Номер ЛС {user.personal_account_number}
                        </Typography.Text>
                    </Box>
                </Box>
            )
        default:
            return <></>
    }
}

const OwnerCard = ({ user, unEditable, selected, onClick, disabled, onDelete }: Props) => {
    return (
        <Box
            key={user.uid || 0}
            className={classNames(
                style.userBox,
                selected && style.userBoxSelected,
                disabled && style.userBoxDisabled,
                unEditable && style.uneditable,
            )}
            onClick={() => onClick && onClick(user)}
        >
            <UserBox user={user} onDelete={onDelete} />
            {onDelete && (
                <Box onClick={() => onDelete(user)} className={style.deleteIcon}>
                    <Trash />
                </Box>
            )}
        </Box>
    )
}

export default OwnerCard
