import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_CERTIFICATES = 'my-certificates'
export const GREEN_TOOLS_ACCOUNTS = 'accounts?need_opened_only=1'
export const GREEN_GENERATORS_ACCOUNT = 'generators-certificates?account_id='
export const GREEN_CONTRACTS = 'contracts/my-contracts'
export const GREEN_TOOLS_OTHER = 'ei/my-ext-instruments'
export const GREEN_TOOLS_NEW_OTHER = 'ei/new'
export const GREEN_TOOLS_REDEEMED_TO_ME = 'certificate-user-consumption'

export const getCertificates = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CERTIFICATES}`,
    method: RequestTypes.post,
}

export const getAccounts = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ACCOUNTS}`,
    method: RequestTypes.get,
}

export const getGeneratorsForAccount = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_GENERATORS_ACCOUNT}`,
    method: RequestTypes.get,
}

export const getContracts = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_CONTRACTS}`,
    method: RequestTypes.post,
}

export const getOther = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_OTHER}`,
    method: RequestTypes.post,
}

export const getNewOther = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_NEW_OTHER}`,
    method: RequestTypes.get,
}

export const getRedeemedToMe = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_REDEEMED_TO_ME}`,
    method: RequestTypes.post,
}
