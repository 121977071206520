import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ArrowLeft } from '../../assets/icons'
import request from '../../services/request'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectDictionariesMaps } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'
import { getDictionaries } from '../../store/dictionaries'
import { CenteredSpin, Pagination, CertificateCard } from '../ui'

import style from './style.m.less'
import { EEnergyType } from '../../constants/approval'

const PAGE_SIZE = 10

interface ICertificate {
    certificate_id: string
    number: string
    status: number // Состояние сертификата(Активен, Погашен, Заблокирован)
    capacity: number // Объем сертификата

    generator_id: string // ID ГО который создал АГ для сертификата
    generator_name: string // Название ГО который создал АГ для сертификата

    renewable_energy_type_id: EEnergyType // Вид возобновляемого источника энергии
    month: number // Период производства
    year: number // Период производства
    created_ts: string // Дата создания сертификата
    expires_ts: string // Срок действия сертификата
}

const CertificatesInPledge: FC = () => {
    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)

    const [data, setData] = useState<ICertificate[]>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true)
                const res = await request.post('/api/green-tools/v1/certificate/deposits', {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                })
                setData(res.data.certificates)
                setTotal(res.data.total)
            } finally {
                setLoading(false)
            }
        }
        load()
    }, [pageSize, page])

    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [])

    const { renewableEnergyTypesMap } = useAppSelector(selectDictionariesMaps) ?? {}

    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>Сертификаты происхождения в залоге</div>
            </div>
            {(loading || isLoading === ELoading.Loading) && <CenteredSpin />}
            {data && (
                <div className={style.cardsWrapper}>
                    {data?.map((item) => {
                        const date = new Date()
                        date.setMonth(item.month - 1)
                        date.setFullYear(item.year)
                        const productionPeriod = moment(date).format('MMMM YYYY')
                        return (
                            <CertificateCard
                                capacity={item.capacity}
                                id={item.generator_id}
                                option={item.status}
                                energyType={getEnergyType(item.renewable_energy_type_id)}
                                generatorName={item.generator_name}
                                productionPeriod={productionPeriod}
                                createdTs={moment(item.created_ts).format('DD.MM.YYYY')}
                                expiredTs={moment(item.expires_ts).format('DD.MM.YYYY')}
                                certificateNumber={item.number}
                                isDeposit
                            />
                        )
                    })}
                </div>
            )}
            <Pagination
                current={page}
                pageSize={pageSize}
                paginationChange={(receivedPage: number, pageSize?: number) => {
                    setPage(receivedPage)
                    if (pageSize) {
                        setPageSize(pageSize)
                    }
                }}
                total={total}
                showSizeChanger
                className={style.pagination}
            />
        </div>
    )
}

export default CertificatesInPledge
