// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".questionnaireTitle_O47b6 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.verticalLine_owYdS {\n  width: 100%;\n  height: 1px;\n  background-color: #F0F0F0;\n  margin: 24px 0;\n}\n.horizontalLine_Dmfl1 {\n  width: 0.5px;\n  height: 24px;\n  background: #D9D9D9;\n}\n.questionnaireTitleWrapper_RfopJ {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  margin-bottom: 24px;\n}\n.questionnaireItem_gzdly {\n  margin-bottom: 16px;\n}\n.questionnaireItemWrapper_zSCAK {\n  display: flex;\n  gap: 24px;\n  margin-bottom: 16px;\n}\n.questionnaireItemName_d3SfT {\n  width: 220px;\n  color: #8C8C8C;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOApprovalItem/components/Questionnaire/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,WAAA;EACA,WAAA;EACA,yBAAA;EACA,cAAA;AAAJ;AAGA;EACI,YAAA;EACA,YAAA;EACA,mBAAA;AADJ;AAIA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AAKA;EACI,mBAAA;AAHJ;AAMA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAJJ;AAOA;EACI,YAAA;EACA,cAAA;AALJ","sourcesContent":[".questionnaireTitle {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.verticalLine {\n    width: 100%;\n    height: 1px;\n    background-color: #F0F0F0;\n    margin: 24px 0;\n}\n\n.horizontalLine {\n    width: 0.5px;\n    height: 24px;\n    background: #D9D9D9;\n}\n\n.questionnaireTitleWrapper {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n.questionnaireItem {\n    margin-bottom: 16px;\n}\n\n.questionnaireItemWrapper {\n    display: flex;\n    gap: 24px;\n    margin-bottom: 16px;\n}\n\n.questionnaireItemName {\n    width: 220px;\n    color: #8C8C8C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionnaireTitle": "questionnaireTitle_O47b6",
	"verticalLine": "verticalLine_owYdS",
	"horizontalLine": "horizontalLine_Dmfl1",
	"questionnaireTitleWrapper": "questionnaireTitleWrapper_RfopJ",
	"questionnaireItem": "questionnaireItem_gzdly",
	"questionnaireItemWrapper": "questionnaireItemWrapper_zSCAK",
	"questionnaireItemName": "questionnaireItemName_d3SfT"
};
export default ___CSS_LOADER_EXPORT___;
