import { FC, useState } from 'react'
import { Divider, Alert, Checkbox, Tooltip, Tag } from 'antd'
import { Link } from 'react-router-dom'
import {
    CertificateCardModal,
    ChangeOwner,
    DeleteModal,
    BlockModal,
    SendToMarketModal,
    ReturnFromMarketModal,
    ReturnFromMarketErrorModal,
} from './components'
import { currencyFormat, currencyFormatWithoutEmptyValue } from '../../helpers'
import { PowerTypeTag, CardInfoBlock, GreenToolsModal, IconButton, ModalStatus, Box } from '../'
import { useAppSelector } from '../../../store/hooks'
import {
    Flash,
    Calendar,
    EmptyWallet,
    Copy,
    TickSquare,
    User,
    Trash,
    Lock,
    Key,
    Flag,
    Transfer,
} from '../../../assets/icons'
import useRequest, { RequestTypes } from '../../hooks/useRequest'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { EUserRole } from '../../../constants/profile'
import { CertificateETPData } from 'src/api/etp/types'

const getStatus = (option: number) => {
    switch (option) {
        case 1:
            return <div className={style.statusTag}>Активен</div>
        case 2:
            return <div className={style.statusRepay}>Погашен</div>
        case 3:
            return <div className={style.statusBlocked}>Заблокирован</div>
        case 4:
            return <div className={style.statusBlocked}>Истек срок действия</div>
        case 5:
            return <div className={style.statusBlocked}>Удаленные</div>
        case 6:
            return <div className={style.statusRepay}>На ЭТП</div>
        default:
            return <div className={style.statusRepay}>Не определен</div>
    }
}

interface ICertificateCard {
    toolType: string
    isRegistered?: boolean
    etpData: CertificateETPData
    isDetailed?: boolean
    isSplitted: boolean
    isUserType?: boolean
    isSelectMode?: boolean
    option: number
    energyType: string
    certificateNumber?: string
    modalStatus?: ModalStatus
    setModalStatus?: (value: ModalStatus) => void
    capacity: number
    generatorName: string
    generator_id: string
    productionPeriod: string
    createdTs: string
    expiredTs: string
    invisibleStatus?: boolean
    consumptionVolume?: number
    certificateId?: string
    accountNumber?: string
    accountId?: string
    refreshData?: () => void
    isChangeOwner: boolean
    isOwner?: boolean
    isEtp?: boolean
    isDeposit?: boolean
    ownerId?: string
    block?: any
    repayment?: any
}

//toolType отвечает за тип инструмента - сертификат или договор.
//isRegistered отвечает за статус пользователя - зареган он или нет
//isDetailed отвечает за более детализированный вывод информации в карточке
//isUserType определяет находимся ли мы в профиле пользователя
//certificateType определяет тип сертификата
//isSelectMode определяет, находимся ли мы в режиме селекта

const CertificateCard: FC<ICertificateCard> = ({
    toolType,
    isRegistered,
    isUserType,
    isSplitted,
    certificateNumber,
    isDetailed,
    option,
    energyType,
    modalStatus = ['divide', false],
    setModalStatus = () => {},
    capacity,
    generatorName,
    createdTs,
    expiredTs,
    productionPeriod,
    certificateId,
    accountNumber,
    accountId,
    refreshData,
    invisibleStatus,
    isChangeOwner,
    generator_id,
    isOwner,
    isEtp,
    isDeposit,
    etpData,
    block,
    ownerId,
    consumptionVolume,
    repayment,
}) => {
    const { t } = useTranslation()

    const [isSendToMarketModalVisible, setSendToMarketModalVisible] = useState(false)
    const [isReturnFromMarketModalVisible, setReturnFromMarketModalVisible] = useState(false)
    const [isReturnFromMarketErrorModalVisible] = useState(false)
    const [isGreenToolsModalVisible, setGreenToolsModalVisible] = useState(false)
    const [isChangeOwnerModalVisible, setIsChangeOwnerModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [blockModalStatus, setBlockModalStatus] = useState({ visible: false, isUnblock: false })
    const [isInvisible, setIsInvisible] = useState<boolean>(!!invisibleStatus)
    const { fetch } = useRequest({
        link: `/api/green-tools/v1/certificate/set-invisible`,
        method: RequestTypes.post,
    })

    const role = useAppSelector((state) => state?.user?.data?.user_role)
    const isNotChangedOwner = [EUserRole.GOOwner].includes(role) && !isChangeOwner
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(role)
    const isOwnerOrAdmin = (isOwner && isDetailed) || (isAdminType && isDetailed)
    const isBlockedOrInDeposit = option === 3 || isDeposit

    const openChangeOwnerModal = () => {
        setIsChangeOwnerModalVisible(true)
    }

    const onChangeIsInvisible = (e: any) => {
        const body = { certificate_id: certificateId, is_invisible: e.target.checked }
        fetch({
            body,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Настройки видимости успешно применены',
            },
            onSuccess: setIsInvisible(e.target.checked),
            errorCodeMessage: true,
        })
    }

    const isContract = toolType === 'Зеленый договор' || toolType === 'Зеленые договоры'
    const isActive = option === 1 && !isContract
    const isOverdue = option === 4
    const isRepaid = option === 2
    const isActiveOrOverdue = isActive || isOverdue || isRepaid
    const cardLabel = isContract
        ? t('certificateCard.greenContract')
        : t('certificateCard.certOrigin')

    const isBlocked = block && block?.ts_created

    const isShowDeleteMain = !isSplitted && !isBlocked && isNotChangedOwner && !isOverdue && !isEtp

    const isShowdeleteAdmin = isAdminType && !isSplitted

    const { offer_id: offerId } = etpData || {}

    return (
        <div className={isDetailed ? style.certificateCardMainFull : style.certificateCardMain}>
            <Box direction="row" align="center">
                {isDeposit && !isDetailed && (
                    <Tooltip
                        placement="top"
                        color="#434343"
                        overlayInnerStyle={{ color: 'white' }}
                        title="Находится в залоге"
                    >
                        <div className={style.iconWrapper}>
                            <Flag />
                        </div>
                    </Tooltip>
                )}
                <div
                    className={
                        isDetailed
                            ? style.certificateHeaderWrapperFull
                            : style.certificateHeaderWrapper
                    }
                >
                    {isRegistered ? (
                        <Link
                            to={{
                                pathname: `/user-certificate-card/${certificateNumber}`,
                            }}
                            state={{
                                toolType,
                                cardLabel,
                                isUserType,
                                id: certificateId,
                                isOwner,
                                option,
                            }}
                            className={style.certificateHeader}
                        >
                            {cardLabel}
                        </Link>
                    ) : (
                        <div className={style.certificateHeader}>
                            {isDetailed ? certificateNumber : cardLabel}
                        </div>
                    )}
                    <div className={style.tagsWrapper}>
                        <PowerTypeTag value={energyType}>{energyType}</PowerTypeTag>
                        {!(isActive && isEtp) && getStatus(option)}
                        {isEtp && <Tag className={style.onETP}>На ЭТП</Tag>}
                    </div>
                </div>
                {isOwnerOrAdmin && (
                    <Link
                        to={{
                            pathname: `/profile`,
                        }}
                        state={{
                            accountId,
                        }}
                        className={style.personalAccount}
                    >
                        <div>
                            {t('certificateCard.persAccount')}
                            {accountNumber}
                        </div>
                    </Link>
                )}
            </Box>
            {!isDetailed && <div className={style.certificateId}>{certificateNumber}</div>}
            <div className={style.certificatePowerRow}>
                <div className={isDetailed ? style.certificatePowerFull : style.certificatePower}>
                    {currencyFormat(capacity)} {t('certificateCard.scale')}
                </div>
                {isOwner && (consumptionVolume || repayment?.volume) ? (
                    <div
                        className={
                            isDetailed
                                ? style.certificatePowerRedeemedFull
                                : style.certificatePowerRedeemed
                        }
                    >
                        {currencyFormat(consumptionVolume || repayment?.volume)}{' '}
                        {t('certificateCard.scale')}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <Box margin="10px 0px">
                <Link to={`/generator-card/${generator_id}`} className={style.link}>
                    <Box direction="row" align="center">
                        <Flash />
                        <Box margin="0px 5px">{generatorName}</Box>
                    </Box>
                </Link>
            </Box>
            <div className={isDetailed ? style.infoRowFull : style.infoRow}>
                <CardInfoBlock
                    label={t('certificateCard.manufPeriod')}
                    value={productionPeriod}
                    icon={<Calendar />}
                    gap={7}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={t('certificateCard.dateReturn')}
                    value={createdTs}
                    icon={<Calendar />}
                    gap={7}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={
                        isContract
                            ? t('certificateCard.contractPeriod')
                            : t('certificateCard.validityPeriod')
                    }
                    value={`до ${expiredTs}`}
                    icon={<Calendar />}
                    gap={7}
                    customBlockStyle={isOverdue ? { color: '#FF4D4F' } : {}}
                />
            </div>
            {isOwnerOrAdmin && (
                <>
                    <Checkbox
                        className={style.checkbox}
                        checked={isInvisible}
                        onClick={onChangeIsInvisible}
                    >
                        {t('certificateCard.noShowInReestr')}
                    </Checkbox>
                    {isDeposit && (
                        <Alert
                            className={style.historyAlert}
                            message={
                                <div className={style.modalAlertText}>
                                    <div>Сертификат происхождения находится в залоге</div>
                                </div>
                            }
                            type="info"
                            showIcon
                        />
                    )}

                    <Box direction="row" justify="space-between" align="flex-end">
                        {((!isAdminType && isActiveOrOverdue) || (isAdminType && option !== 5)) && (
                            <>
                                <div className={style.buttonsBlock}>
                                    {!isEtp && (
                                        <IconButton
                                            customIconStyle={{
                                                marginTop: '3px',
                                                marginRight: '10px',
                                            }}
                                            icon={<EmptyWallet />}
                                            onClick={() => setGreenToolsModalVisible(true)}
                                        >
                                            {t('certificateCard.changePersAccount')}
                                        </IconButton>
                                    )}

                                    {!isBlocked && option !== 2 && !isOverdue && !isEtp && (
                                        <>
                                            {/* Сменить владельца */}
                                            <IconButton
                                                icon={<User />}
                                                customIconStyle={{
                                                    marginRight: '8px',
                                                    marginTop: '5px',
                                                }}
                                                onClick={openChangeOwnerModal}
                                            >
                                                {t('certificate.changeOwner')}
                                            </IconButton>
                                            {/* Поделить */}
                                            <IconButton
                                                customIconStyle={{
                                                    marginTop: '4px',
                                                    marginRight: '10px',
                                                }}
                                                icon={<Copy />}
                                                onClick={() => setModalStatus(['divide', true])}
                                            >
                                                {t('certificateCard.divide')}
                                            </IconButton>
                                        </>
                                    )}
                                    {/* Погасить */}
                                    {!isBlocked &&
                                        option !== 2 &&
                                        option !== 3 &&
                                        !isOverdue &&
                                        !isEtp && (
                                            <Link
                                                to="/certificate-repayment"
                                                state={{ volume: capacity, certificateId }}
                                            >
                                                <IconButton
                                                    customIconStyle={{
                                                        marginTop: '4px',
                                                        marginRight: '10px',
                                                    }}
                                                    icon={<TickSquare />}
                                                >
                                                    {t('certificateCard.payOff')}
                                                </IconButton>
                                            </Link>
                                        )}

                                    {!isOverdue &&
                                        !isAdminType &&
                                        !isDeposit &&
                                        !isRepaid &&
                                        (isEtp ? (
                                            <IconButton
                                                customIconStyle={{
                                                    marginTop: '4px',
                                                    marginRight: '10px',
                                                }}
                                                icon={<Transfer />}
                                                onClick={() =>
                                                    setReturnFromMarketModalVisible(true)
                                                }
                                            >
                                                {t('certificateCard.returnFromMarket')}
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                customIconStyle={{
                                                    marginTop: '4px',
                                                    marginRight: '10px',
                                                }}
                                                icon={<Transfer />}
                                                onClick={() => setSendToMarketModalVisible(true)}
                                            >
                                                {t('certificateCard.sendToMarket')}
                                            </IconButton>
                                        ))}
                                </div>

                                <div className={style.buttonsBlock}>
                                    {isAdminType && (
                                        <IconButton
                                            customIconStyle={{
                                                marginTop: '3px',
                                                marginRight: '10px',
                                            }}
                                            icon={
                                                isBlockedOrInDeposit ? (
                                                    <Key />
                                                ) : (
                                                    <Lock color="currentColor" />
                                                )
                                            }
                                            onClick={() =>
                                                setBlockModalStatus({
                                                    visible: true,
                                                    isUnblock: isBlockedOrInDeposit ? true : false,
                                                })
                                            }
                                            className={style.cancelButton}
                                        >
                                            {isBlockedOrInDeposit
                                                ? 'Разблокировать'
                                                : 'Заблокировать'}
                                        </IconButton>
                                    )}

                                    {/* удалять СП в залоге могут только админы */}
                                    {(isShowDeleteMain || isShowdeleteAdmin) && (
                                        <IconButton
                                            customIconStyle={{
                                                marginTop: '3px',
                                                marginRight: '10px',
                                            }}
                                            icon={<Trash />}
                                            onClick={() => setIsDeleteModalVisible(true)}
                                            className={style.cancelButton}
                                        >
                                            {'Удалить'}
                                        </IconButton>
                                    )}
                                </div>
                            </>
                        )}
                    </Box>
                </>
            )}
            <GreenToolsModal
                isModalVisible={isGreenToolsModalVisible}
                setIsModalVisible={setGreenToolsModalVisible}
                certificateId={certificateId}
                refreshData={refreshData}
                user_id={ownerId}
            />
            <ChangeOwner
                isModalVisible={isChangeOwnerModalVisible}
                setIsModalVisible={() => setIsChangeOwnerModalVisible(false)}
                certificateId={certificateId}
                capacity={capacity}
            />
            <DeleteModal
                isModalVisible={isDeleteModalVisible}
                setIsModalVisible={setIsDeleteModalVisible}
                certificateId={certificateId}
                certificateNumber={certificateNumber}
                capacity={capacity}
            />
            <BlockModal
                modalStatus={blockModalStatus}
                setModalStatus={setBlockModalStatus}
                certificateId={certificateId}
            />
            <CertificateCardModal
                modalStatus={modalStatus}
                setModalStatus={setModalStatus}
                capacity={capacity}
                certificateId={certificateId}
            />
            <SendToMarketModal
                certificateNumber={certificateNumber}
                certificateVolume={capacity}
                onCancel={() => setSendToMarketModalVisible(false)}
                onOk={() => {
                    setSendToMarketModalVisible(false)
                    refreshData?.()
                }}
                isVisible={isSendToMarketModalVisible}
            />
            <ReturnFromMarketModal
                setIsModalVisible={setReturnFromMarketModalVisible}
                onOk={() => {
                    refreshData?.()
                    setReturnFromMarketModalVisible(false)
                }}
                certificateId={offerId}
                isVisible={isReturnFromMarketModalVisible}
            />
            <ReturnFromMarketErrorModal isVisible={isReturnFromMarketErrorModalVisible} />
        </div>
    )
}

export default CertificateCard
