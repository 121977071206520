import { isUserAdmin } from '../../constants/approval'
import { CurrentTab } from './types'

export const getTabs = (userRole: number): CurrentTab[] => {
    const tabs: CurrentTab[] = ['Профиль']

    if (userRole !== 1 && !isUserAdmin(userRole)) {
        tabs.splice(1, 0, 'Платежный счет', 'Лицевой счет')
    }

    // if (userRole === 3) {
    //     tabs.push('ГО пользователя')
    // }

    return tabs
}
