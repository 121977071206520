import { message } from 'antd'
import MessageComponent from '../ui/Messages/MessageComponent'
import { ReactNode, useCallback, useState } from 'react'
import request from '../../services/request'
import { errorHandler } from '../../components/helpers'

interface ErrorRequest {
    isError: boolean
    errorMessage: any
}
export enum RequestTypes {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch',
}

interface RequestHookTypes {
    link: string
    method: RequestTypes
}

interface IMessage {
    title: string
    description?: string
    icon?: ReactNode
}

interface RequestDataTypes<TResult = any, TError = any> {
    getParams?: any
    body?: any
    config?: any
    shortParams?: string[]
    successMessage?: IMessage
    errorMessage?: IMessage
    errorCodeMessage?: boolean
    addToLink?: string
    showError?: boolean
    onSuccess?: (data: TResult) => void
    onError?: (err: TError) => void
}

const createParams = (obj: any): string => {
    if (!obj || !Object.keys(obj).length) {
        return ''
    }
    let compiledParams = '?'

    for (const key in obj) {
        if (obj[key]) {
            compiledParams += `${key}=${obj[key]}&`
        }
    }
    compiledParams = compiledParams.slice(0, -1)
    return compiledParams
}

const createShort = (getParams: any, shortParams?: string[]): string => {
    if (!shortParams || !shortParams.length) {
        return ''
    }

    const isParamsEmpty = !Object.keys(getParams).length

    return `${isParamsEmpty ? '?' : '&'}${shortParams.join('&')}`
}

export default function useRequest<TResult = any>({ link, method }: RequestHookTypes) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<ErrorRequest>({ isError: false, errorMessage: '' })
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [result, setResult] = useState<TResult | null>(null)

    const { isError, errorMessage } = error

    const dropState = () => {
        setIsLoading(false)
        setIsSuccess(false)
        setError({ isError: false, errorMessage: '' })
        setResult(null)
    }

    const fetchRequest = async ({
        body,
        getParams,
        config,
        successMessage,
        errorMessage,
        onSuccess,
        onError,
        errorCodeMessage,
        addToLink,
        shortParams,
        showError = true,
    }: RequestDataTypes<TResult>) => {
        const fullLink = `${link}${addToLink ? addToLink : ''}${createParams(
            getParams,
        )}${createShort(getParams, shortParams)}`

        try {
            let res: any = null
            switch (method) {
                case 'get':
                    res = await request.get(fullLink)
                    break
                case 'post':
                    res = await request.post(fullLink, body, config)
                    break
                case 'put':
                    res = await request.put(fullLink, body, config)
                    break
                case 'patch':
                    res = await request.patch(fullLink, body, config)
                    break
                case 'delete':
                    res = await request.delete(fullLink, body)
                    break
                default:
                    break
            }
            setResult(res.data)
            setIsSuccess(true)
            if (successMessage) {
                const { title = '', description = '', icon } = successMessage
                message.success({
                    content: <MessageComponent header={title} message={description} icon={icon} />,
                    icon: <></>,
                })
            }

            onSuccess && onSuccess(res.data)
            setIsLoading(false)
        } catch (err: any) {
            if (errorCodeMessage && showError) {
                message.error(errorHandler(err.err_code, err.err_attributes))
            } else if (showError) {
                message.error(err.err_message)
            }

            if (errorMessage && showError) {
                const { title = '', description = '', icon } = errorMessage
                message.error({
                    content: <MessageComponent header={title} message={description} icon={icon} />,
                    ...(icon ? icon : <></>),
                })
            }

            onError && onError(err)
            setError({
                isError: true,
                errorMessage: errorHandler(err.err_code),
            })
        } finally {
            setIsLoading(false)
        }
    }

    const fetch = useCallback(
        ({
            body = {},
            getParams = {},
            shortParams = [],
            config = {},
            successMessage,
            errorMessage,
            onError,
            onSuccess,
            errorCodeMessage,
            addToLink,
            showError = true,
        }: RequestDataTypes<TResult>) => {
            setError({ isError: false, errorMessage: '' })
            setIsLoading(true)
            fetchRequest({
                body,
                getParams,
                config,
                successMessage,
                errorMessage,
                onError,
                onSuccess,
                errorCodeMessage,
                addToLink,
                shortParams,
                showError,
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [link],
    )

    return {
        isLoading,
        isError,
        errorMessage,
        isSuccess,
        fetch,
        result,
        dropState,
        error,
    }
}
