import { LockBadge } from '../ui'
import { userRolePicker } from '../helpers/profileHelpers'
import { ELabels } from './types'

import style from './style.m.less'

export const getTabs = (isSuperAdmin: boolean) => {
    return isSuperAdmin
        ? [
              { label: 'Пользователи', key: 0 },
              { label: 'Административные пользователи', key: 1 },
          ]
        : [
              { label: 'Физические лица', key: 0 },
              { label: 'Юридические лица', key: 2 },
              { label: 'ИП', key: 1 },
          ]
}

export const typeIndex: {
    [key in ELabels]: number
} = {
    [ELabels.FIZ]: 0,
    [ELabels.YUR]: 2,
    [ELabels.IP]: 1,
}

export const getColumns = (typeIndex: number, isGeneric: boolean) => {
    const columns: {
        title: string
        dataIndex: string
        key: string
        render?: (
            value: number,
            data: { is_blocked: boolean; has_update: boolean; uid: string },
        ) => { props: { style: { padding: string } }; children: JSX.Element } | string
        width?: string
    }[] = [
        {
            title: 'ФИО',
            dataIndex: 'full_name',
            key: 'full_name',
            render: (value, { is_blocked }) => ({
                props: {
                    style: { padding: `${is_blocked ? '6px' : ''} 16px` },
                },
                children: (
                    <div className={style.fullNameRow}>
                        {is_blocked && (
                            <LockBadge
                                popoverContent={
                                    <div className={style.popover}>Пользователь заблокирован</div>
                                }
                            />
                        )}
                        <div>{value}</div>
                    </div>
                ),
            }),
        },
        {
            title: 'Логин',
            dataIndex: 'login',
            key: 'login',
        },
        {
            title: 'Роль пользователя',
            dataIndex: 'user_role',
            key: 'user_role',
            render: (user_role: number) => userRolePicker(user_role),
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        ...(isGeneric
            ? [
                  {
                      title: 'ИНН',
                      dataIndex: typeIndex === 2 ? 'company_inn' : 'personal_inn',
                      key: typeIndex === 2 ? 'company_inn' : 'personal_inn',
                  },
              ]
            : []),
    ]

    if (!isGeneric) {
        columns.push({
            title: 'Сокращенное название организации',
            dataIndex: 'company_name_short',
            key: 'company_name_short',
            width: '16%',
        })
    }

    if (typeIndex === 2 && isGeneric) {
        columns.splice(3, 0, {
            title: 'Сокращенное название организации',
            dataIndex: 'company_name_short',
            key: 'company_name_short',
            width: '16%',
        })
    }

    if (typeIndex === 0 && isGeneric) {
        columns.push({
            title: 'СНИЛС',
            dataIndex: 'snils',
            key: 'snils',
        })
    }

    return columns
}
