import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'

interface IOwnerBlock {
    ownerInfo: any
}

const OwnerBlock: FC<IOwnerBlock> = ({ ownerInfo }) => {
    const { t } = useTranslation()
    const { user_type, company_name_short, first_name, last_name, middle_name, user_pub_id } =
        ownerInfo
    const goOwnerName =
        user_type === 2
            ? company_name_short
            : user_type === 1
            ? `ИП ${last_name} ${first_name} ${middle_name}`
            : `${last_name} ${first_name} ${middle_name}`

    return (
        <div className={style.repaymentBlock}>
            <div className={style.repaymentHeader}>{t('certificate.ownerInfo')}</div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Текущий владелец ЗИ</div>
                <div className={style.nameBlock}>{goOwnerName}</div>
            </div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Уникальный номер пользователя</div>
                <div className={style.nameBlock}>{user_pub_id}</div>
            </div>
        </div>
    )
}

export default OwnerBlock
