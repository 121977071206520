import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FooterLogo, Mail, Telegram } from '../../../../assets/icons'
import style from './style.m.less'

const FooterInfo: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.footerInfo}>
            <div className={style.infoText}>{t('footer.address')}</div>
            <div className={style.phoneBlock}>
                <div>+7 (495) 740-30-27</div>
                <div>+7 (916) 689-93-55</div>
            </div>
            <div>
                <div className={style.footerInfoBlock}>
                    <Mail />
                    <div className={style.iconInfo}>mail@e-greentrack.ru</div>
                </div>
            </div>
        </div>
    )
}

export default FooterInfo
