import { FC } from 'react'
import { Alert } from 'antd'
import { IOperationConfig, IOperationSettings } from './types'
import { HistoryBlock } from './components'
import style from './style.m.less'

interface IOperationsMapper {
    config: IOperationConfig[] | undefined
    settings: IOperationSettings
    isContract?: boolean
    isThirdParty?: boolean
    isShort?: boolean
}

const OperationsHistoryMapper: FC<IOperationsMapper> = ({
    config,
    settings,
    isContract,
    isThirdParty,
    isShort,
}) => {
    return (
        <>
            {!config?.length && (
                <Alert
                    className={style.historyAlert}
                    message={
                        isContract
                            ? 'Здесь будет отображаться история операций зеленого договора'
                            : isThirdParty
                            ? 'Здесь будет отображаться история операций стороннего инструмента'
                            : 'Здесь будет отображаться история операций Сертификата происхождения'
                    }
                    type="info"
                    showIcon
                />
            )}
            {config?.map(({ date, operations, flexStart, iconMargin }, index) => (
                <HistoryBlock
                    key={index}
                    date={date}
                    isShort={isShort}
                    settings={settings}
                    operations={operations}
                    flexStart={flexStart}
                    iconMargin={iconMargin}
                    isContract={isContract}
                    isThirdParty={isThirdParty}
                />
            ))}
        </>
    )
}

export default OperationsHistoryMapper
