import { FC, useState, useEffect, ChangeEvent } from 'react'
import { Divider, Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'
import useRequest from '../hooks/useRequest'
import { ButtonsBlock } from '../Edit/components'
import { getBalances, postDeposit, postWithdraw, payTypeIdPicker } from './config'
import { Tag, DropdownSelector2, DocumentsBlock } from '../ui'
import { ConsumerBlock } from './components'

const { TextArea } = Input

const operationDepositOptions = [
    { id: 'cb3c2fb4-d72a-4590-8be1-deda1793758f', value: 'Ручное пополнение платежного счета' },
    { id: 'bb4deea3-356d-4f98-b2ad-79de8031361e', value: 'Прочие поступления денежных средств' },
]

const operationWithdrawOptions = [
    { id: 'cb3c2fb4-d72a-4590-8be1-deda1793758f', value: 'Прочие списания денежных средств' },
]

interface IAdminPaymentOperations {
    type: 'deposit' | 'withdraw'
}

export const UserStatusTag = ({ status }: any) => (
    <Tag outline transparent className={style.tag}>
        {status}
    </Tag>
)

const AdminPaymentOperations: FC<IAdminPaymentOperations> = ({ type }) => {
    const { t } = useTranslation()
    const { fetch, result, dropState } = useRequest(getBalances)
    const { fetch: sendDeposit } = useRequest(postDeposit)
    const { fetch: sendWithdraw } = useRequest(postWithdraw)
    const [files, setFiles] = useState<any[]>([])
    const [selected, setSelected] = useState<any>()

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const navigate = useNavigate()
    const [form] = Form.useForm()

    const isDeposit = type === 'deposit'
    const paymentOptions = isDeposit ? operationDepositOptions : operationWithdrawOptions

    useEffect(() => {
        if (selected) {
            dropState()
            fetch({ addToLink: `?uid=${selected}`, errorCodeMessage: true })
        }
    }, [selected])

    const onFinish = async (values: any) => {
        const payData = {
            ...values,
            amount: Number(values.amount),
            operation_type_id: payTypeIdPicker(values.operation_type_id),
            uid: selected,
            documents: files,
        }
        if (isDeposit) {
            sendDeposit({
                body: payData,
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Счет успешно пополнен',
                },
                errorCodeMessage: true,
                onSuccess: () => navigate(-1),
            })
        } else {
            sendWithdraw({
                body: payData,
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Средства со счета успешно списаны',
                },
                errorCodeMessage: true,
                onSuccess: () => navigate(-1),
            })
        }
    }

    const goBack = () => {
        navigate(-1)
    }

    const onSubmit = () => {
        form.submit()
    }

    const updateSelected = (id: string) => {
        setSelected(id)
    }

    const fixNumberDigit = (e: ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) < 0 || e.target.value.startsWith('-')) {
            e.target.value = e.target.value.slice(-1)
        }
        const lengthAfter = e.target.value.toString().match(/\.(\d+)/)?.[1].length || 0
        if (lengthAfter > 2) {
            const diff = lengthAfter - 2
            e.target.value = e.target.value.slice(0, -diff)
        }
    }

    const onKeyOnlyNumbers = (e: KeyboardEvent) => {
        if (!/^[0-9.,]+$/.test(e.key)) {
            e.preventDefault()
        }
    }

    return (
        <div className={style.editMain}>
            <div className={style.header}>{`${
                isDeposit ? 'Пополнение' : 'Списание с'
            } платежного счета`}</div>

            <div className={style.editFrame}>
                <ConsumerBlock updateRepayments={updateSelected} />
                {result && selected && (
                    <div className={style.balancesFrame}>
                        <div className={style.balancesBlock}>
                            <div className={style.balancesLabel}>Текущий баланс</div>
                            <div className={style.balancesValue}>{`${result.total_balance} ₽`}</div>
                        </div>
                        <div className={style.balancesBlock}>
                            <div className={style.balancesLabel}>Зарезервировано</div>
                            <div
                                className={style.balancesValue}
                            >{`${result.reserved_balance} ₽`}</div>
                        </div>
                        <div className={style.balancesBlock}>
                            <div className={style.balancesLabel}>Свободные средства</div>
                            <div
                                className={style.balancesValue}
                            >{`${result.available_balance} ₽`}</div>
                        </div>
                    </div>
                )}
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    name="adminPaymentForm"
                    className={style.form}
                    initialValues={{ operation_type_id: paymentOptions[0].value }}
                >
                    <div>
                        <div>
                            Тип операции <span className={style.star}>*</span>
                        </div>
                        <Form.Item name="operation_type_id">
                            <DropdownSelector2
                                placeholder="Выберите из списка"
                                customStyle={{ width: '540px', marginTop: '2px' }}
                                options={paymentOptions}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <div>
                            {`Сумма ${isDeposit ? 'пополнения' : 'списания'}`}
                            <span className={style.star}>*</span>
                        </div>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: t('billing.pleaseAmountBilling'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={`Введите сумму ${
                                    isDeposit ? 'пополнения' : 'списания'
                                }`}
                                min={0}
                                maxLength={14}
                                type="number"
                                onKeyPress={onKeyOnlyNumbers}
                                onInput={fixNumberDigit}
                                className={style.input}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <div>
                            Основание <span className={style.star}>*</span>
                        </div>
                        <Form.Item
                            name="reason"
                            rules={[
                                {
                                    required: true,
                                    message: t('billing.pleaseReasonBilling'),
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Ваш комментарий"
                                className={style.input}
                                rows={3}
                            />
                        </Form.Item>
                    </div>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={6}
                        description="Загрузите подтверждаюший документ"
                        customStyle={{ width: '540px' }}
                    />
                </Form>
                <Divider />
                <ButtonsBlock
                    disableCondition={!selected}
                    confirmTitle={isDeposit ? 'Пополнить' : 'Списать'}
                    confirmAction={onSubmit}
                    cancelAction={goBack}
                />
            </div>
        </div>
    )
}

export default AdminPaymentOperations
