import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useMount from './useMount'
import useRequest from './useRequest'
import { RequestTypes } from './useRequest'

export const useAuditData = (id?: string) => {
    const location = useLocation()
    const state = location.state
    const { fetch } = useRequest({
        link: `/api/audit/v1/certificate-event-custom?id=`,
        method: RequestTypes.post,
    })

    const [auditData, setAuditData] = useState()

    useMount(async () => {
        if (!state && id) {
            fetch({
                addToLink: id,
                errorCodeMessage: true,
                onSuccess: (data) => {
                    setAuditData({ eventType: data.type_event_id, eventId: data.type_event_id })
                },
            })
        } else {
            const { eventData } = state
            const { eventType, eventId } = eventData
            setAuditData({ eventType, eventId })
        }
    })

    if (auditData !== undefined) {
        return auditData
    }
}
