import { FC, useEffect, useState } from 'react'
import { IconButton, Modal, PrimaryButton } from '../'
import { Box } from '../../ui/Box'

import style from './style.m.less'
import { getFindUsers } from '../../../services/apiConfigs'
import useRequest from '../../hooks/useRequest'
import { Empty, Pagination, Spin } from 'antd'
import UserCard from './UserCard'
import UserFilters from './UserFilters'

interface IUserSearchModal {
    isModalVisible: boolean
    setIsModalVisible: () => void
    userType?: number
    onSelect: (data: any) => void
    prevSelected?: string[]
    prevSelectedKey?: string
    uneditable?: boolean
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    f?: any
}

const UserSearchModal: FC<IUserSearchModal> = ({
    isModalVisible,
    setIsModalVisible,
    userType = 0,
    onSelect,
    prevSelected,
    prevSelectedKey,
}) => {
    const { fetch: userFetch, result: userList, dropState, isLoading } = useRequest(getFindUsers)
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>({})
    const [selected, setSelected] = useState<any>(undefined)

    const selectHandle = (d: any) => {
        setSelected(d)
    }

    const getData = ({ page = 1, pageSize = 10, f = filters }: PaginationType) => {
        userFetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    ...f,
                    user_type: userType,
                },
            },
        })
    }

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
    }

    const getUpselected = () => {
        if (selected) {
            onSelect(selected)
            setSelected(undefined)
            setFilters({})
            setIsModalVisible()
        }
    }

    const find = () => {
        getData({ page: 1, pageSize: pagination.pageSize, f: filters })
    }

    const closeModal = () => {
        dropState()
        setIsModalVisible()
    }

    useEffect(() => {
        if (isModalVisible) {
            // getData({ ...DEFAULT_PAG, f: filters })
        } else {
            setSelected(undefined)
            setFilters({})
        }
    }, [isModalVisible])

    return (
        <Modal
            isModalVisible={isModalVisible}
            onCancel={closeModal}
            bodyStyle={{ maxHeight: '85vh', overflowX: 'scroll' }}
            boxShadow={false}
            footer={
                <Box width={'100%'}>
                    <Box
                        direction="row"
                        justify="space-between"
                        align="center"
                        margin="10px 0px 10px 0px"
                    >
                        {userList?.total > 10 ? (
                            <Pagination
                                pageSize={pagination.pageSize}
                                current={pagination.page}
                                total={userList?.total || 0}
                                onChange={(page, pageSize) => {
                                    getData({ page, pageSize, f: filters })
                                    setPagination({ page, pageSize })
                                }}
                                showSizeChanger
                            />
                        ) : (
                            <Box width={'100%'} />
                        )}
                        <Box direction="row" justify="flex-end">
                            <PrimaryButton
                                onClick={closeModal}
                                value={'Отмена'}
                                isCancel
                                customStyle={{ padding: '8px 16px' }}
                            />
                            <PrimaryButton
                                onClick={getUpselected}
                                value={'Выбрать'}
                                customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                            />
                        </Box>
                    </Box>
                </Box>
            }
        >
            <Box>
                <Box>
                    <div className={style.modalHeader}>Поиск пользователя в системе</div>
                    <UserFilters onChange={updateFilters} userType={userType} />
                    <Box width={100} margin="20px 0px 20px -10px">
                        <IconButton
                            onClick={find}
                            className={style.buttonMargin}
                            disabled={
                                !Object.keys(filters).length ||
                                !Object.values(filters).filter((el: any) => `${el}`.length > 0)
                                    .length
                            }
                        >
                            Найти
                        </IconButton>
                    </Box>
                </Box>
                <Box width={650} padding="10px 0px 0px 0px">
                    <>
                        {isLoading ? (
                            <Spin />
                        ) : (
                            <>
                                {userList?.users?.length < 1 || !userList || !userList?.users ? (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                ) : (
                                    (userList?.users || []).map((el: any) => (
                                        <UserCard
                                            selected={el.uid === selected?.uid}
                                            disabled={
                                                prevSelected?.includes(
                                                    el[prevSelectedKey || 'uid'],
                                                ) || false
                                            }
                                            onClick={selectHandle}
                                            user={el}
                                        />
                                    ))
                                )}
                            </>
                        )}
                    </>
                </Box>
            </Box>
        </Modal>
    )
}

export default UserSearchModal
