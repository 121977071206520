import FileSaver from 'file-saver'
import { errorHandler } from '../helpers'
import { message } from 'antd'
import request from '../../services/request'

export const downloadCsv = async (url: string, file_name = '', bodyData?: any) => {
    try {
        const body = await request.post(
            url,
            {
                ...bodyData,
            },
            {
                responseType: 'blob',
            },
        )

        const bodyBlob = new Blob([body?.data], {
            type: 'application/octet-stream',
        })

        FileSaver.saveAs(bodyBlob, file_name.length ? file_name : encodeURI(body?.headers.filename))
    } catch (err: any) {
        if (err) {
            message.error(errorHandler(err.err_code))
        }
    }
}

export const downloadZip = async (url: string, file_name = '', bodyData?: any) => {
    try {
        const body = await request.post(
            url,
            {
                ...bodyData,
            },
            {
                responseType: 'arraybuffer',
            },
        )

        const bodyBlob = new Blob([body?.data], {
            type: 'application/zip',
        })

        FileSaver.saveAs(bodyBlob, file_name.length ? file_name : encodeURI(body?.headers.filename))
    } catch (err: any) {
        if (err) {
            message.error(errorHandler(err.err_code))
        }
    }
}

export const downloadXml = async (url: string, bodyData: any, file_name = '') => {
    try {
        const body = await request.post(
            url,
            {
                ...bodyData,
            },
            {
                responseType: 'blob',
            },
        )

        const bodyBlob = new Blob([body?.data], {
            type: 'application/octet-stream',
        })

        FileSaver.saveAs(bodyBlob, file_name.length ? file_name : encodeURI(body?.headers.filename))
    } catch (err: any) {
        if (err) {
            message.error(errorHandler(err.err_code))
        }
    }
}
