import { FC, useState, useEffect, useCallback } from 'react'
import { Divider } from 'antd'
import { CenteredSpin, Pagination, Empty } from '../ui'
import { useTranslation } from 'react-i18next'
import request from '../../services/request'
import { Filters, Controls, GeneratorCard } from './components'
import { IOwner } from './components/GeneratorCard/GeneratorCard'
import debounce from 'lodash/debounce'
import { ESortType } from './components/Controls/Controls'

import style from './style.m.less'

const PAGE_SIZE = 10

interface IData {
    go_owners: IOwner[]
    total: number
    total_power_max: number
}

const Owners: FC = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)

    const [data, setData] = useState<IData>()
    const [loading, setLoading] = useState(true)

    const [userNameFilter, setUserNameFilter] = useState('')
    const [totalPowerFromFilter, setTotalPowerFromFilter] = useState(0)
    const [totalPowerToFilter, setTotalPowerToFilter] = useState(0)
    const [renewableEnergyTypeFilter, setRenewableEnergyTypeFilter] = useState<number[]>([])
    const [sortType, setSortType] = useState<ESortType>()
    const [descending, setDescending] = useState<boolean>()

    const loadOwners = useCallback(
        async (
            page: number,
            pageSize: number,
            name?: string,
            renewable_energy_type_ids?: number[],
            total_power_from?: number,
            total_power_to?: number,
            type_id?: ESortType,
            is_descending?: boolean,
        ) => {
            try {
                setLoading(true)
                const res = await request.post(`/api/generators/v1/go-owners`, {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        renewable_energy_type_ids,
                        total_power_from,
                        total_power_to,
                        name,
                    },
                    sort: {
                        type_id,
                        is_descending,
                    },
                })
                setData(res.data)
            } finally {
                setLoading(false)
            }
        },
        [],
    )

    const loadOwnersDebounced = useCallback(debounce(loadOwners, 300), [])

    useEffect(() => {
        loadOwnersDebounced(
            page,
            pageSize,
            userNameFilter,
            renewableEnergyTypeFilter,
            totalPowerFromFilter,
            totalPowerToFilter,
            sortType,
            descending,
        )
    }, [
        page,
        pageSize,
        renewableEnergyTypeFilter,
        totalPowerFromFilter,
        totalPowerToFilter,
        userNameFilter,
        sortType,
        descending,
    ])

    return (
        <div className={style.ownersMain}>
            <div className={style.mainHeader}>{t('owners.title')}</div>
            <div className={style.mainWrapper}>
                <Controls
                    name={userNameFilter}
                    sortType={sortType}
                    desc={descending}
                    onChangeUserName={setUserNameFilter}
                    onChangeSortType={setSortType}
                    onDescendingType={setDescending}
                />
                <div className={style.mainBlock}>
                    <div className={style.cardsBlock}>
                        {loading && <CenteredSpin />}
                        {data?.go_owners ? (
                            data?.go_owners.map((owner: IOwner, index: number) => (
                                <div key={owner.uid}>
                                    <GeneratorCard owner={owner} />
                                    {index !== data.go_owners.length - 1 && (
                                        <Divider className={style.divider} />
                                    )}
                                </div>
                            ))
                        ) : (
                            <Empty
                                imageStyle={{
                                    height: 60,
                                    marginTop: 60,
                                }}
                                descriptionText={'По вашему запросу ничего не найдено'}
                            />
                        )}
                    </div>
                    <Filters
                        values={{
                            renewableEnergyType: renewableEnergyTypeFilter,
                            totalPowerFrom: totalPowerFromFilter,
                            totalPowerTo: totalPowerToFilter,
                        }}
                        onChange={({ totalPowerFrom, totalPowerTo, renewableEnergyType }) => {
                            setTotalPowerFromFilter(totalPowerFrom)
                            setTotalPowerToFilter(totalPowerTo)
                            setRenewableEnergyTypeFilter(renewableEnergyType)
                        }}
                    />
                </div>
                <Pagination
                    current={page}
                    pageSize={pageSize}
                    paginationChange={(receivedPage: number, pageSize?: number) => {
                        setPage(receivedPage)
                        if (pageSize) {
                            setPageSize(pageSize)
                        }
                    }}
                    total={data?.total}
                    showSizeChanger
                />
            </div>
        </div>
    )
}

export default Owners
