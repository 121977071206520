import { RequestTypes } from '../hooks/useRequest'

export const BILLING_API_PREFIX = '/api/billing/v1/'
export const BILLING_CREDENTIALS = 'credentials'

export const getCredentials = {
    link: `${BILLING_API_PREFIX}${BILLING_CREDENTIALS}`,
    method: RequestTypes.get,
}

export const postCredentials = {
    link: `${BILLING_API_PREFIX}${BILLING_CREDENTIALS}`,
    method: RequestTypes.post,
}

interface BillingCredentials {
    bank_name: string
    beneficiary_acc: string
    bic: string
    corr_acc: string
    inn: string
    kpp: string
    recipient: string
}

export const credentialsConfig = (credentials: BillingCredentials) => {
    const { bank_name, inn, kpp, bic, corr_acc, recipient, beneficiary_acc, reason } = credentials

    return {
        visibleInfo: [
            {
                name: 'Получатель',
                value: recipient,
                noMargin: true,
            },
            {
                name: 'ИНН Банка получателя',
                value: inn,
            },
            {
                name: 'Банк получателя',
                value: bank_name,
            },
            {
                name: 'БИК Банка получателя',
                value: bic,
            },
            {
                name: 'КПП Банка получателя',
                value: kpp,
            },
            {
                name: 'К/С Банка получателя',
                value: corr_acc,
            },
            {
                name: 'Счет получателя в банке получателя',
                value: beneficiary_acc,
            },
            {
                name: 'Назначение платежа',
                value: reason,
            },
        ],
    }
}
