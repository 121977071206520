import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton, Box } from '../ui'
import { ELoading } from '../../store/dictionaries/interfaces'
import useMount from '../hooks/useMount'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { UnregisteredLayout, RegisteredLayout } from './components'
import { getDictionaries } from '../../store/dictionaries'
import { EUserRole } from '../../constants/profile'
import style from './style.m.less'

interface IGreenTools {
    isAuth: boolean
    isUserType?: boolean
}

const GreenTools: FC<IGreenTools> = ({ isAuth, isUserType }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isOwnerType = [EUserRole.GOOwner].includes(userRole)
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(userRole)

    useMount(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    return (
        <div className={style.greenToolsMain}>
            <div className={style.mainHeader}>
                <div>{isUserType ? t('greenTools.myGreenTools') : t('greenTools.greenTools')}</div>
                {isAuth && (
                    <Box direction="row" gap="16px">
                        {isOwnerType && (
                            <IconButton onClick={() => navigate('/issue-certificate')}>
                                {t('detailedCard.issueCerf')}
                            </IconButton>
                        )}
                        {isAdminType && (
                            <>
                                <IconButton onClick={() => navigate('/third-party-tools-adding')}>
                                    {'Добавить сторонние инструменты'}
                                </IconButton>
                                <IconButton
                                    onClick={() => navigate('/third-party-tools-repayment')}
                                >
                                    {'Погасить сторонние инструменты'}
                                </IconButton>
                            </>
                        )}
                    </Box>
                )}
            </div>
            <div className={style.mainWrapper}>
                {isAuth ? (
                    <RegisteredLayout userRole={userRole} isUserType={isUserType} />
                ) : (
                    <UnregisteredLayout />
                )}
            </div>
        </div>
    )
}

export default GreenTools
