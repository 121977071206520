import { FC, useEffect } from 'react'

import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../../ui'
import { getDictionaries, selectDictionariesMaps } from '../../../../store/dictionaries'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { colorPicker } from '../../../helpers'
import { EUserType } from '../../../../constants/approval'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import { QuestionnaireItem } from './QuestionnaireItem'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../../helpers'

const getGo = (go) => {
    return go?.user_type === EUserType.UrLico
        ? go?.company_name_short
        : go?.user_type === EUserType.IP
        ? `ИП ${go?.last_name} ${go?.first_name} ${go?.middle_name}`
        : null
}

const Questionnaire: FC = ({ config }) => {
    const { t } = useTranslation()

    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [isLoading])

    const dictionaries = useAppSelector(selectDictionariesMaps)

    const {
        fuelMap,
        categoriesGoMap,
        productionModesMap,
        ownershipsMap,
        aidsMap,
        regionsMap,
        qualificationCategoriesMap,
        fullGenCodeOptions,
        renewableEnergyTypesMap,
    } = dictionaries

    return (
        <>
            <div className={style.questionnaire}>
                <QuestionnaireItem title={t('gOApprovalItem.marketEE')}>
                    {config?.is_for_retail
                        ? t('gOApprovalItem.retail')
                        : t('gOApprovalItem.wholesale')}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.type')}>
                    {config?.go_type_id === 1
                        ? t('gOApprovalItem.qualifiedGO')
                        : t('gOApprovalItem.registeredGO')}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.uniqueGOcode')} isTag>
                    {config?.generator_pub_id}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.GTPcodeGenerate')} isTag>
                    {config?.point_delivery_code}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.GTPcodeConsume')} isTag>
                    {config?.point_delivery_code_consuming}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.gOCode')} isTag>
                    {config?.go_code}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.tSO')}>
                    {config?.tso?.company_name_short}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.gP')}>
                    {config?.gp?.company_name_short}
                </QuestionnaireItem>
                <div className={style.verticalLine} />
                <QuestionnaireItem title={t('gOApprovalItem.gOAdress')}>
                    {[
                        'Российская Федерация',
                        regionsMap.get(config?.region_id),
                        config?.address_index,
                        config?.city,
                        config?.street,
                        config?.house,
                        config?.housing,
                        config?.building,
                        config?.address_info,
                    ]
                        .filter(Boolean)
                        .join(', ')}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.gOCategory')}>
                    {categoriesGoMap.get(config?.category_go_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.lat')}>
                    {config?.geo_lat}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.lng')}>
                    {config?.geo_lon}
                </QuestionnaireItem>
                {/* Квалификационная категория ГО (вид источника энергии) */}
                <QuestionnaireItem title={t('gOApprovalItem.qualificationCategory')}>
                    {qualificationCategoriesMap.get(config?.qualification_category_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={'Признак ГО'}>
                    {fullGenCodeOptions.get(config?.renewable_energy_type_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.localStep')}>
                    {config?.localization_degree ? config?.localization_degree + '%' : undefined}
                </QuestionnaireItem>
                <QuestionnaireItem title={'Квалификационная категория ГО (вид источника энергии)'}>
                    {renewableEnergyTypesMap?.get(config?.renewable_energy_type_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.fuelTypes')}>
                    {config?.fuel_ids?.map((id: any, i: number) => fuelMap.get(id)).join(', ')}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.authorizedCapacity')}>
                    {config?.power ? `${currencyFormat(config?.power / 1000)} МВт` : null}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.productionMode')}>
                    {productionModesMap.get(config?.production_mode_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.groundsOwnership')}>
                    {ownershipsMap.get(config?.ownership_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.gOOwner')}>
                    {config?.go_owner ? (
                        <Link to={`/owner/${config.go_owner.uid}`} className={style.infoValue}>
                            {getGo(config?.go_owner)}
                        </Link>
                    ) : null}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.supportProgram')}>
                    {aidsMap.get(config?.aid_id)}
                </QuestionnaireItem>
                <QuestionnaireItem title={t('gOApprovalItem.cancelDuplicate')}>
                    {config?.qualification_point}
                </QuestionnaireItem>
            </div>
        </>
    )
}

export default Questionnaire
