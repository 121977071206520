import { ChangeEvent, FC, useMemo, useRef, useState } from 'react'
import { Export } from '../../../../assets/icons'
import { CenteredSpin, IconButton, DocumentsList } from '../../../ui'
import { IFile } from '../../../ui/DocumentsList/DocumentsList'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import request from '../../../../services/request'
import SignUtils from '../../../../utils/sign/SignUtils'
import { message } from 'antd'

export const MAX_FILES_SIZE = 52428800

interface IDocumentsBlock {
    customStyle?: React.CSSProperties
    hideComment?: boolean
    value?: IFile[]
    serviceId?: number
    onChange?(files: IFile[]): void
    description?: string
    disabled?: boolean
    required?: boolean
    format?: string
}

const DocumentsBlock: FC<IDocumentsBlock> = ({
    customStyle,
    value: files = [],
    serviceId = 4,
    onChange,
    hideComment,
    description,
    disabled = false,
    required,
    format,
}) => {
    const { t } = useTranslation()
    const fileInput = useRef<HTMLInputElement>(null)
    const [fileUploading, setFileUploading] = useState(false)

    const showError = () => {
        message.error('Суммарный объем прикрепленных файлов не должен превышать размер 50 Мб.')
    }

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const totalSelectedFilesSize =
                files.reduce((sizeSum, item) => sizeSum + item.size, 0) + file.size
            if (totalSelectedFilesSize > MAX_FILES_SIZE) {
                showError()
                return
            }

            if (format && !file.name.includes(format)) {
                message.error(`Загруженный файл не формата ${format}.`)
                return
            }

            setFileUploading(true)

            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = async () => {
                try {
                    const { data, status } = await request.post(
                        '/api/documents/v1/file',
                        fileReader.result,
                        {
                            headers: {
                                Filename: encodeURI(file.name),
                                'Service-ID': serviceId,
                            },
                        },
                    )
                    if (status === 200) {
                        const hash = await SignUtils.hashBytes(fileReader.result)
                        onChange?.([
                            {
                                name: file.name,
                                size: file.size,
                                id: data.id,
                                type: file.name.split('.').pop(),
                                description: '',
                                hash: hash,
                            },
                        ])
                    }
                } catch (err) {
                    console.log('Error: ', err)
                } finally {
                    setFileUploading(false)
                }
            }
            fileReader.onerror = () => {
                setFileUploading(false)
            }
        }
    }

    const handleClick = () => {
        fileInput.current?.click()
    }

    const inputFileKey = useMemo(
        () => files?.map((f) => f.hash).join('') + showError,
        [files, showError],
    )

    return (
        <>
            <div style={customStyle}>
                {fileUploading && <CenteredSpin />}
                <div className={style.uploadDocumentFrame}>
                    <div className={style.documentsListWrapper}>
                        <DocumentsList
                            hideRemove
                            hideComment={hideComment}
                            files={files}
                            onRemove={(file) => {
                                const newSelectedFiles = files.filter((f) => f.hash !== file.hash)
                                onChange?.(newSelectedFiles)
                            }}
                            onChange={(file) => {
                                const fileIndex = files.findIndex((f) => f.hash === file.hash)
                                onChange?.([
                                    ...files.slice(0, fileIndex),
                                    file,
                                    ...files.slice(fileIndex + 1),
                                ])
                            }}
                        />
                    </div>
                    <input
                        type="file"
                        className={style.file}
                        onChange={handleChange}
                        ref={fileInput}
                        key={inputFileKey}
                    />
                    <IconButton
                        icon={<Export />}
                        customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                        onClick={handleClick}
                        disabled={disabled}
                    >
                        {files.length >= 1 ? 'Загрузить другой файл' : 'Загрузить файл'}
                    </IconButton>
                    {!files.length && (
                        <div className={style.info}>
                            {description && description}
                            {required && <span className={style.star}>*</span>}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default DocumentsBlock
