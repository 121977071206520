import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { DownMini } from '../../../../assets/icons'
import style from './style.m.less'

const generalRoutes = [
    { id: 1, to: 'documents', title: 'Документы и платежи' },
    { id: 2, to: 'green-tools', title: 'Зеленые инструменты' },
    { id: 3, to: 'generators', title: 'Реестр ЗГО' },
    { id: 4, to: 'owners', title: 'Владельцы ЗГО' },
    { id: 5, to: 'news', title: 'Новости и события' },
]

const adminSettingsRoutes = [
    { id: 1, to: 'tariffs-settings', title: 'Настройка тарификации' },
    { id: 2, to: 'settings3', title: 'Настройки срока действия АГ', disabled: true },
    { id: 3, to: 'certificate-validity-period', title: 'Настройки срока действия СП' },
    { id: 4, to: 'system-dates-settings', title: 'Установка системных контрольных дат' },
    { id: 5, to: 'billing', title: 'Настройки реквизитов для пополнения' },
    { id: 7, to: 'ag-validity-period', title: 'Настройки срока атрибутов генерации' },
]

const dataRoutes = [
    { id: 1, to: 'ku-approval', title: 'Согласование данных КУ' },
    { id: 2, to: 'ku-data', title: 'Подача данных КУ' },
    { id: 3, to: 'zd-data', title: 'Подача данных ЗД' },
    { id: 4, to: 'fuel-approval', title: 'Согласование по топливу' },
    { id: 5, to: 'go-approval', title: 'Согласование добавления ЗГО' },
]

interface IAdminNavigation {
    isAdmin: boolean
}

// disabled && style.disabled TODO удалить как разблокируют разделы

const AdminNavigation: FC<IAdminNavigation> = ({ isAdmin }) => {
    const location = useLocation()
    const usersRoutes = [
        { id: 1, to: 'users', title: 'Пользователи', disabled: !isAdmin },
        { id: 2, to: 'user-appeals', title: 'Заявки пользователей' },
        { id: 3, to: 'questionnaire-approval', title: 'Согласование Анкет' },
    ]

    const menu = (routes: any[]) => {
        return (
            <Menu className={style.menu}>
                {routes?.map(
                    (route: {
                        id: number
                        to: string
                        title: string
                        disabled: boolean | undefined
                    }) => {
                        const { id, to, title, disabled } = route
                        return (
                            <Menu.Item
                                key={id}
                                className={disabled ? style.disabled : ''}
                                disabled={disabled}
                            >
                                {!disabled ? (
                                    <NavLink
                                        to={to}
                                        className={(navData) =>
                                            navData.isActive ? style.subLinkActive : style.subLink
                                        }
                                    >
                                        {title}
                                    </NavLink>
                                ) : (
                                    title
                                )}
                            </Menu.Item>
                        )
                    },
                )}
            </Menu>
        )
    }

    return (
        <nav className={style.navBar}>
            <NavLink
                className={(navData) => (navData.isActive ? style.navLinkActive : style.navLink)}
                to="main"
            >
                Главная
            </NavLink>
            <Dropdown overlay={menu(generalRoutes)} className={style.navLink}>
                <div>
                    <div
                        className={
                            generalRoutes.some((route) => location.pathname.includes(route.to))
                                ? style.navLinkActive
                                : style.navLink
                        }
                    >
                        Общие разделы
                    </div>
                    <DownMini />
                </div>
            </Dropdown>
            <Dropdown overlay={menu(usersRoutes)} className={style.navLink}>
                <div>
                    <div
                        className={
                            usersRoutes.some((route) => location.pathname.includes(route.to))
                                ? style.navLinkActive
                                : style.navLink
                        }
                    >
                        Пользователи
                    </div>
                    <DownMini />
                </div>
            </Dropdown>
            <NavLink
                className={(navData) => (navData.isActive ? style.navLinkActive : style.navLink)}
                to="registry"
            >
                Реестр платежных операций
            </NavLink>
            {isAdmin && (
                <Dropdown overlay={menu(adminSettingsRoutes)} className={style.navLink}>
                    <div>
                        <div
                            className={
                                adminSettingsRoutes.some((route) =>
                                    location.pathname.includes(route.to),
                                )
                                    ? style.navLinkActive
                                    : style.navLink
                            }
                        >
                            Настройки
                        </div>
                        <DownMini />
                    </div>
                </Dropdown>
            )}
            <Dropdown overlay={menu(dataRoutes)} className={style.navLink}>
                <div>
                    <div
                        className={
                            dataRoutes.some((route) => location.pathname.includes(route.to))
                                ? style.navLinkActive
                                : style.navLink
                        }
                    >
                        Работа с данными
                    </div>
                    <DownMini />
                </div>
            </Dropdown>
            <NavLink
                className={(navData) => (navData.isActive ? style.navLinkActive : style.navLink)}
                to="audit"
            >
                Аудит
            </NavLink>
        </nav>
    )
}

export default AdminNavigation
