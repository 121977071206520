import { FC } from 'react'
import { AddCircle, Import, Transfer } from '../../../../assets/icons'
import { IconButton } from '../../../ui/'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

export enum EIsActive {
    Active = 'Active',
    NonActive = 'NonActive',
}

const tabsList = [
    { label: 'Активированные', key: EIsActive.Active, content: '' },
    { label: 'Неактивированные', key: EIsActive.NonActive, content: '' },
]

export enum ESortType {
    Date = 0, // 0 - по Дате добавления в систему;
    Name = 1, // 1 - по Наименованию Владельца ГО;
    Power = 2, // 2 - по суммарной мощности;
}

interface IControlsAdmin {
    isActive: EIsActive
    onChangeActive: (isActive: EIsActive) => void
}

const ControlsAdmin: FC<IControlsAdmin> = ({ isActive, onChangeActive }) => {
    const { t } = useTranslation()

    return (
        <div className={style.controlsMain}>
            <div className={style.controlsWrapper}>
                <Link to="/adding-go">
                    <IconButton
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<AddCircle />}
                    >
                        Добавить ГО
                    </IconButton>
                </Link>
                <IconButton
                    icon={<Transfer />}
                    customStyle={{ marginLeft: '16px' }}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                >
                    Импортировать ЗГО
                </IconButton>
                <IconButton
                    icon={<Import />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                    customStyle={{ marginLeft: '16px' }}
                >
                    Выгрузить данные
                </IconButton>
            </div>
            <Tabs
                activeKey={isActive}
                className={style.tabs}
                onChange={(key: string) => onChangeActive(key as EIsActive)}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={label} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default ControlsAdmin
