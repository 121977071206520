import { Mail2, Telegram2 } from '../../assets/icons'
import { FC } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

export const Contacts: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.contacts}>
            <div>
                <h2 className={style.contactsTitle}>{t('documents.contacts')}</h2>
                <p className={style.contactsText}>{t('documents.address')}</p>
                <div className={style.contactsNumbers}>
                    <p className={style.contactsNumber}>+7 (495) 740-30-27</p>
                    <p className={style.contactsNumber}>+7 (916) 689-93-55</p>
                </div>
                <div className={style.media}>
                    <div className={style.textWithIcon}>
                        <span className={style.icon}>
                            <Mail2 />
                        </span>
                        <span>mail@e-greentrack.ru</span>
                    </div>
                </div>
            </div>
            <div>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad4422a933ff7633673df697e6b2f9591665cf76f0588d92940d73f17c4140377&amp;source=constructor"
                    width="848"
                    height="339"
                    frameborder="0"
                ></iframe>
            </div>
        </div>
    )
}

export default Contacts
