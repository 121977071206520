import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { decode } from 'js-base64'
import { useNavigate } from 'react-router-dom'
import FileSaver from 'file-saver'
import { getSignatureService } from '../../../../services/user'
import { Alert } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'

import style from './style.m.less'

interface IESignatureModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    certificateData: any
    requestId: string
}

const ESignatureModal: FC<IESignatureModal> = ({
    isModalVisible,
    setIsModalVisible,
    certificateData,
    requestId,
}) => {
    const { t } = useTranslation()

    const mockToken = {
        address: '',
        common_name: '',
        sur_name: '',
        organization_name: '',
        serial_number: '',
        issuer: '',
        subject: '',
        token_created: '',
        token_expiration: '',
        token_id: '',
    }

    if (certificateData === null) {
        certificateData = mockToken
    }

    const handleOk = async () => {
        const body = await getSignatureService(requestId, true, (error) => {
            console.log(error)
        })

        const bodyBlob = new Blob([body?.data], { type: 'application/octet-stream' })

        const signature = await getSignatureService(requestId, false, (error) => {
            console.log(error)
        })

        const signatureBlob = new Blob([signature?.data], { type: 'application/octet-stream' })

        FileSaver.saveAs(bodyBlob, body?.headers.filename)
        FileSaver.saveAs(signatureBlob, signature?.headers.filename)

        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.eSignatureModalWrapper}>
                <div className={style.modalHeader}>{t('myProfile.eSignature')}</div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.name')}</div>
                    <div>{certificateData.common_name}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.surname')}</div>
                    <div>{certificateData.sur_name}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>
                        {t('myProfile.organization')}
                    </div>
                    <div>{certificateData.organization_name}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.s/n')}</div>
                    <div>{certificateData.serial_number}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.publisher')}</div>
                    <div>{certificateData.issuer}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.subject')}</div>
                    <div>{certificateData.subject}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.issued')}</div>
                    <div>{certificateData.token_created}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.expires')}</div>
                    <div>{certificateData.token_expiration}</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.keyID')}</div>
                    <div>{certificateData.address}</div>
                </div>
            </div>

            <Alert
                className={style.eSignatureModalAlert}
                message={t('myProfile.eSignatureAlert')}
                type="info"
                showIcon
            />
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleOk}
                    cancelAction={handleCancel}
                    confirmTitle={t('myProfile.downloadESignature')}
                />
            </div>
        </Modal>
    )
}

export default ESignatureModal
