import SortSwitch, { ESortDirection } from '../../../ui/SortSwitch/SortSwitch'
import { FC, useState } from 'react'
import { Import, Location, RowVertical } from '../../../../assets/icons'
import { IconButton, SortButton, Popover } from '../../../ui/'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Input } from 'antd'
import { useAppSelector } from '../../../../store/hooks'
import { EUserRole } from '../../../../constants/approval'

export enum ESortType {
    Date = 0, // 0 - по Дате добавления в систему;
    Name = 2, // 2 - по Наименованию Владельца ГО;
    Power = 1, // 1 - по суммарной мощности;
}

interface IControls {
    name: string
    sortType?: ESortType
    desc?: boolean // false - ascending; true - descending
    onChangeUserName: (name: string) => void
    onChangeSortType: (sortType?: ESortType) => void
    onDescendingType: (desc?: boolean) => void
}

const Controls: FC<IControls> = ({
    name,
    sortType,
    desc,
    onChangeUserName,
    onChangeSortType,
    onDescendingType,
}) => {
    const { t } = useTranslation()

    const [sortOpen, setSortOpen] = useState(false)

    const userRole: EUserRole = useAppSelector((state) => state.user.data.user_role)

    const dateValue =
        sortType === ESortType.Date ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined
    const nameValue =
        sortType === ESortType.Name ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined
    const powerValue =
        sortType === ESortType.Power ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined

    const handleChange = (sortType: ESortType, sortDirection?: ESortDirection) => {
        if (sortDirection !== undefined) {
            onChangeSortType(sortType)
            onDescendingType(sortDirection === ESortDirection.DESC)
        } else {
            onChangeSortType()
            onDescendingType()
        }
        setSortOpen(false)
    }

    return (
        <div className={style.controlsMain}>
            <div className={style.sortWrapper}>
                <Popover
                    onClickOut={setSortOpen}
                    open={sortOpen}
                    content={
                        <div>
                            <div className={style.sortTitle}>{t('newsAndEvents.sortBy')}</div>
                            <div className={style.sortItem}>
                                <span className={style.sortItemLabel}>
                                    Дате добавления ГО в систему
                                </span>
                                <SortSwitch
                                    value={dateValue}
                                    onChange={(sortDirection) => {
                                        handleChange(ESortType.Date, sortDirection)
                                    }}
                                />
                            </div>
                            <div className={style.sortItem}>
                                <span className={style.sortItemLabel}>Мощности ГО</span>
                                <SortSwitch
                                    value={powerValue}
                                    onChange={(sortDirection) => {
                                        handleChange(ESortType.Power, sortDirection)
                                    }}
                                />
                            </div>
                            <div className={style.sortItem}>
                                <span className={style.sortItemLabel}>Наименованию ГО</span>
                                <SortSwitch
                                    value={nameValue}
                                    onChange={(sortDirection) => {
                                        handleChange(ESortType.Name, sortDirection)
                                    }}
                                />
                            </div>
                        </div>
                    }
                >
                    <SortButton onClick={() => setSortOpen((open) => !open)} />
                </Popover>
            </div>
            {/* {userRole !== undefined && (
                <IconButton
                    icon={<Import />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                >
                    Выгрузить данные
                </IconButton>
            )} */}
            <Input
                placeholder="Поиск"
                value={name}
                onChange={(event) => onChangeUserName(event.target.value)}
                className={style.input}
            />
            {/* <div className={style.iconButtons}>
                <IconButton className={style.iconButton} icon={<RowVertical />} size="middle" />
                <IconButton
                    className={style.iconButton}
                    icon={<Location />}
                    customIconStyle={{ marginTop: '3px' }}
                />
            </div> */}
        </div>
    )
}

export default Controls
