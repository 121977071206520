import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { IconButton } from '../../../ui'
import { SendForRevisionModal } from '../'
import { RefuseToApproveModal } from '../'
import { message } from 'antd'
import { EApproveType, EUserType } from '../../../../constants/approval'
import { IUserInfo } from 'store/user/interfaces'
import { EAction } from '../../QuestionnaireApprovalItem'

import style from './style.m.less'

interface IButtonContainer {
    profile: IUserInfo
    action: EAction
}

const ButtonContainer: FC<IButtonContainer> = ({ profile, action }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { fetch } = useRequest({
        link: `/api/users/v1/profile-approve`,
        method: RequestTypes.post,
    })

    const fullName =
        profile?.user_type === EUserType.FizLico
            ? `${profile?.last_name} ${profile?.first_name} ${profile?.middle_name}`
            : profile?.user_type === EUserType.IP
            ? `ИП ${profile?.last_name} ${profile?.first_name} ${profile?.middle_name}`
            : profile?.company_name_short

    const messages = {
        1: {
            title: t('questionnaireApprovalItem.success'),
            description: `Анкета пользователя ${fullName} согласована`,
        },
        2: { title: 'Анкета отправлена на доработку' },
        3: {
            title:
                action === EAction.SignUp
                    ? 'В регистрации отказано'
                    : 'В редактировании Анкеты отказано',
        },
    }

    const [isRevisionModalVisible, setIsRevisionModalVisible] = useState(false)
    const openRevisionModal = () => {
        setIsRevisionModalVisible(true)
    }

    const [isRefuseModalVisible, setIsRefuseModalVisible] = useState(false)
    const openRefuseModal = () => {
        setIsRefuseModalVisible(true)
    }

    const handleRequest = (
        approveType: EApproveType = EApproveType.Approve,
        comment: string = '',
    ) => {
        fetch({
            body: {
                profile_id: profile.id,
                approve_type: approveType,
                profile_comment: comment,
            },
            onSuccess() {
                navigate('/questionnaire-approval')
                setIsRevisionModalVisible(false)
            },
            onError(error) {
                if (error && error.err_code === 'ERR_APL_47') {
                    message.error('В АС “СКИЗИ“ уже используется ЭП с таким сертификатом')
                }
            },
            successMessage: messages[approveType],
        })
    }

    return (
        <div className={style.buttonContainer}>
            <IconButton
                size="large"
                className={style.buttonContainerApprove}
                onClick={openRevisionModal}
            >
                {t('questionnaireApprovalItem.refineButton')}
            </IconButton>
            <IconButton
                size="large"
                className={style.buttonContainerApprove}
                onClick={() => handleRequest()}
            >
                {t('questionnaireApprovalItem.approveButton')}
            </IconButton>
            <IconButton
                size="large"
                className={style.buttonContainerCancel}
                onClick={openRefuseModal}
            >
                {t('questionnaireApprovalItem.cancelButton')}
            </IconButton>

            <SendForRevisionModal
                isModalVisible={isRevisionModalVisible}
                onConfirm={(comment) => {
                    handleRequest(EApproveType.SendRevision, comment)
                }}
                onCancel={() => setIsRevisionModalVisible(false)}
            />
            <RefuseToApproveModal
                isModalVisible={isRefuseModalVisible}
                onConfirm={(comment) => {
                    handleRequest(EApproveType.Canceled, comment)
                }}
                onCancel={() => setIsRefuseModalVisible(false)}
            />
        </div>
    )
}

export default ButtonContainer
