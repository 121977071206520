import { FC, useState } from 'react'
import {
    Divider,
    Input,
    Radio,
    Form,
    message,
    Typography,
    // , Checkbox
} from 'antd'
import { Link } from 'react-router-dom'
import { encode, btoa } from 'js-base64'
import {
    CommonBlock,
    TagSelector,
    SelectedTsoGp2,
    DocumentsBlock,
    QualificationCategoryModal,
} from '../../components'
import {
    Alert,
    DropdownSelector2,
    PrimaryButton,
    PrimaryIconButton,
    CenteredSpin,
    DatePicker,
} from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { AddEditGP, AddEditTSO, SearchGP, SearchTSO } from '../../../GOActivation/components'
import { ITSO } from '../../../GOActivation/components/AddEditTSO/AddEditTSO'
import { useNavigate, useLocation } from 'react-router-dom'
import { selectDictionariesOptions } from '../../../../store/dictionaries'
import { useAppSelector } from '../../../../store/hooks'
import { IGP } from '../../../GOActivation/components/AddEditGP/AddEditGP'
import { IFile } from '../../components/DocumentsList/DocumentsList'
import { v4 as uuidv4 } from 'uuid'
import SignUtils from '../../../../utils/sign/SignUtils'
import { hexToBinary } from '../../../helpers'
import { useGetTariffRate } from '../../../hooks'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

const { TextArea } = Input

const MESSAGE = 'Поле обязательно для заполнения'

interface IGeneratorLayout {
    type: 'generator' | 'create' | 'profile'
}

const GeneratorLayout: FC<IGeneratorLayout> = ({ type }) => {
    const { t } = useTranslation()

    const location = useLocation()

    const { createFrom, generator }: any = location?.state || {}

    const dictionaries = useAppSelector(selectDictionariesOptions)
    const companyName = useAppSelector(
        (state) => state.user.data.company_name || state.user.data.company_name_short,
    )

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo

    const { isGOOwner } = useCurrentUser()

    const isCreate = type === 'create' ? true : undefined

    const [form] = Form.useForm()
    const [isForRetail, setIsForRetail] = useState(createFrom ? generator.is_for_retail : false)

    const [selectedTso, setSelectedTso] = useState<ITSO>()
    const [openAddEditTso, setOpenAddEditTso] = useState(false)

    const [selectedGp, setSelectedGp] = useState<IGP>()
    const [openAddEditGp, setOpenAddEditGp] = useState(false)

    const navigate = useNavigate()

    const getBack = () => {
        navigate(-1)
    }

    // перенес в useRequest создание ГО
    const { fetch: goCreate, isLoading: isLoadingGoCreate } = useRequest({
        link: `/api/generators/v1/go-create?request_id=`,
        method: RequestTypes.post,
    })

    const getCheckFields = async () => {
        try {
            const res = await form.validateFields()
            return res
        } catch (error: any) {
            if (
                error &&
                error.errorFields &&
                error.errorFields[0] &&
                error.errorFields[0].name &&
                error.errorFields[0].name[0]
            ) {
                form.scrollToField(error.errorFields[0].name[0])
            }
            return { exit: true }
        }
    }

    const handleConfirm = async () => {
        if (!data.certificate_data.certificate) {
            message.error({
                content:
                    'Подключите Электронную подпись чтобы иметь возможность подписывать документы!',
                // icon: <></>,
            })
            return
        }
        const { selectedFiles, exit, ...values } = await getCheckFields()
        // если есть ошибки в валидации то дальше не идем
        if (exit) {
            return
        }

        const params = {
            ...values,
            address_index: values.address_index ? Number(values.address_index) : undefined,
            localization_degree: values.localization_degree
                ? Number(values.localization_degree)
                : null,
            power: values.power ? Number(values.power) * 1000 : undefined,
            geo_lat: values.geo_lat ? Number(values.geo_lat) : undefined,
            geo_lon: values.geo_lon ? Number(values.geo_lon) : undefined,
            go_type_id: 2,
            is_uid_go_owner_draft: false,
        }
        if (selectedTso) {
            params.uid_tso = selectedTso.uid
            params.is_uid_tso_draft = selectedTso.isDraft
        }
        if (selectedGp) {
            params.uid_gp = selectedGp.uid
            params.is_uid_gp_draft = selectedGp.isDraft
        }
        if (selectedFiles.length) {
            params.documents = selectedFiles?.map((file: IFile) => ({
                id: file.id,
                hash: file.hash,
                comment: file.description,
            }))
        }

        const requestId = uuidv4()
        const thumbprint = await SignUtils.getCertificateThumbprint(
            data.certificate_data.certificate,
        )
        const encodedInfo = encode(JSON.stringify(params))
        const encoded = requestId + encodedInfo
        const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
        const Signature = btoa(hexToBinary(preSignature))

        goCreate({
            addToLink: `${requestId}`,
            body: params,
            errorCodeMessage: true,
            onSuccess: ({ generator_id }: any) => {
                return navigate(`/generator-card/${generator_id}`, { replace: true })
            },
            config: {
                headers: {
                    Signature,
                },
            },
            successMessage: {
                title: t('detailedCard.success'),
                description: `${values.name} ${t('detailedCard.created')}`,
            },
        })
    }

    const [isSearchTSOModalVisible, setIsSearchTSOModalVisible] = useState(false)
    const openSearchTSOModal = () => {
        setIsSearchTSOModalVisible(true)
    }

    const [isSearchGPModalVisible, setIsSearchGPModalVisible] = useState(false)
    const openSearchGPModal = () => {
        setIsSearchGPModalVisible(true)
    }

    const [isQualificationCategoryModalVisible, setIsQualificationCategoryModalVisible] =
        useState(false)

    const { loadingTariffs, rate } = useGetTariffRate({
        id: 65,
    })

    if (!dictionaries || loadingTariffs) {
        return <CenteredSpin />
    }

    const {
        regionsOptions,
        qualificationCategoriesOptions,
        fullRenewableEnergyTypesOptions,
        productionModesOptions,
        categoriesGoOptions,
        fullFuelOptions,
        aidsOptions,
        ownershipsOptions,
    } = dictionaries

    const initialValues: any = {
        is_for_retail: false,
        region_id: regionsOptions[0].value,
        renewable_energy_type_id: fullRenewableEnergyTypesOptions[0].value,
        qualification_category_id: qualificationCategoriesOptions[0].value,
        category_go_id: categoriesGoOptions[0].value,
        production_mode_id: productionModesOptions[0].value,
        ownership_id: ownershipsOptions[0].value,
        selectedFiles: [],
        fuel_ids: [],
    }

    if (createFrom) {
        initialValues.is_for_retail = generator.is_for_retail
        initialValues.point_delivery_code = generator.point_delivery_code
        initialValues.name = generator.name
        initialValues.region_id = generator.region_id
        initialValues.address_index = generator.address_index || null
        initialValues.city = generator.city
        initialValues.street = generator.street
        initialValues.house = generator.house
        initialValues.housing = generator.housing
        initialValues.building = generator.building
        initialValues.geo_lat = generator.geo_lat || null
        initialValues.geo_lon = generator.geo_lon || null
        initialValues.address_info = generator.address_info
        initialValues.renewable_energy_type_id = generator.renewable_energy_type_id
        initialValues.qualification_category_id = generator.qualification_category_id
        initialValues.localization_degree = generator.localization_degree
        initialValues.category_go_id = generator.category_go_id
        initialValues.fuel_ids = generator.fuel_ids || []
        initialValues.power = generator.power / 1000
        initialValues.production_mode_id = generator.production_mode_id
        initialValues.ownership_id = generator.ownership_id
        initialValues.aid_id = generator.aid_id || null
        initialValues.selectedFiles = generator.selectedFiles
    }

    return (
        <div className={style.editFrame}>
            <Form form={form} initialValues={initialValues} className={style.form}>
                <CommonBlock
                    required
                    label={t('edit.marketEE')}
                    select={
                        <Form.Item
                            name="is_for_retail"
                            getValueFromEvent={(event) => {
                                const { value } = event.target
                                setIsForRetail(value)
                                if (!value) {
                                    setSelectedTso(undefined)
                                    setSelectedGp(undefined)
                                } else {
                                    form.setFields([
                                        { name: 'point_delivery_code', value: undefined },
                                    ])
                                }
                                return value
                            }}
                        >
                            <Radio.Group className={style.radio}>
                                <Radio value={true}>{t('edit.retail')}</Radio>
                                <Radio value={false}>{t('edit.wholesale')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    }
                />
                {isForRetail && (
                    <>
                        <div className={style.blockGroup}>
                            <Alert
                                message={
                                    <div>
                                        Код ГО для розничного рынка будет сгенерирован
                                        автоматически.
                                    </div>
                                }
                            />
                        </div>
                    </>
                )}
                <Divider />
                <CommonBlock
                    required
                    label={t('edit.name')}
                    select={
                        <Form.Item name="name" rules={[{ required: true, message: MESSAGE }]}>
                            <Input
                                placeholder={isCreate && t('edit.namePlaceholder')}
                                className={style.inputLong}
                                maxLength={1000}
                            />
                        </Form.Item>
                    }
                />
                {!isForRetail && (
                    <>
                        <CommonBlock
                            required
                            label="Код ГТП генерации"
                            margin
                            select={
                                <Form.Item
                                    name="point_delivery_code"
                                    rules={[
                                        { required: true, message: MESSAGE },
                                        {
                                            len: 8,
                                            message: 'Код должен состоять из 8 символов',
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                                            message:
                                                'Код должен содержать только цифры и латинские символы',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={isCreate && 'Введите код'}
                                        className={style.inputLong}
                                        minLength={8}
                                        maxLength={8}
                                    />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            label="Код ГТП потребления"
                            margin
                            select={
                                <Form.Item
                                    name="point_delivery_code_consuming"
                                    rules={[
                                        { message: MESSAGE },
                                        {
                                            pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                                            message:
                                                'Код должен содержать только цифры и латинские символы',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={isCreate && 'Введите код'}
                                        className={style.inputLong}
                                        minLength={8}
                                        maxLength={8}
                                    />
                                </Form.Item>
                            }
                        />
                    </>
                )}
                {isForRetail && (
                    <>
                        <Divider />
                        <SelectedTsoGp2
                            label="ТСО"
                            selectedTsoGp={selectedTso}
                            onEditClick={() => setOpenAddEditTso(true)}
                            onRemoveClick={() => setSelectedTso(undefined)}
                            onSearchClick={openSearchTSOModal}
                            onManualEditClick={() => setOpenAddEditTso(true)}
                        />
                        <SelectedTsoGp2
                            label="ГП"
                            selectedTsoGp={selectedGp}
                            onEditClick={() => setOpenAddEditGp(true)}
                            onRemoveClick={() => setSelectedGp(undefined)}
                            onSearchClick={openSearchGPModal}
                            onManualEditClick={() => setOpenAddEditGp(true)}
                        />
                    </>
                )}
                <Divider />
                <CommonBlock
                    required
                    label={t('edit.placeGO')}
                    select={
                        <DropdownSelector2
                            placeholder={isCreate && 'Выберите из списка'}
                            options={[
                                {
                                    id: 'Россия',
                                    value: 'Россия',
                                    text: 'Россия',
                                },
                            ]}
                            customStyle={{ width: '400px' }}
                            disabled
                        />
                    }
                />
                <div className={style.blockGroup}>
                    <div>
                        <div>
                            {t('edit.subject')}
                            <span className={style.star}>*</span>
                        </div>
                        <Form.Item name="region_id" rules={[{ required: true, message: MESSAGE }]}>
                            <DropdownSelector2
                                placeholder={isCreate && 'Выберите из списка'}
                                options={regionsOptions}
                                customStyle={{ width: '492px' }}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <div>{t('edit.index')}</div>
                        <Form.Item
                            name="address_index"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (value && value.toString().length !== 6) {
                                            return Promise.reject(
                                                new Error('Индекс должен состоять из 6 символов'),
                                            )
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input
                                className={style.inputTiny}
                                placeholder="_ _ _ _ _ _"
                                maxLength={6}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>Населенный пункт</div>
                        <Form.Item name="city">
                            <TextArea className={style.textArea} rows={2} maxLength={1000} />
                        </Form.Item>
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.street')}</div>
                        <Form.Item name="street">
                            <Input
                                placeholder={isCreate && 'Введите название улицы'}
                                className={style.inputShort}
                                maxLength={100}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <div>{t('edit.house')}</div>
                        <Form.Item name="house">
                            <Input className={style.inputTiny} maxLength={10} />
                        </Form.Item>
                    </div>
                    <div>
                        <div>{t('edit.block')}</div>
                        <Form.Item name="housing">
                            <Input className={style.inputTiny} maxLength={10} />
                        </Form.Item>
                    </div>
                    <div>
                        <div>{t('edit.building')}</div>
                        <Form.Item name="building">
                            <Input className={style.inputTiny} maxLength={10} />
                        </Form.Item>
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.lat')}</div>
                        <Form.Item
                            name="geo_lat"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (value != null && (value < -90 || value > 90)) {
                                            return Promise.reject(
                                                new Error(
                                                    'Значение широты не может быть меньше -90, либо больше 90',
                                                ),
                                            )
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input
                                className={style.inputMiddle}
                                placeholder="Укажите широту"
                                type="number"
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <div>{t('edit.lng')}</div>
                        <Form.Item
                            name="geo_lon"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (value != null && (value < -180 || value > 180)) {
                                            return Promise.reject(
                                                new Error(
                                                    'Значение долготы не может быть меньше -180, либо больше 180',
                                                ),
                                            )
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input
                                className={style.inputMiddle}
                                placeholder="Укажите долготу"
                                type="number"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.addressMore')}</div>
                        <Form.Item name="address_info">
                            <TextArea className={style.textArea} rows={3} maxLength={1000} />
                        </Form.Item>
                    </div>
                </div>
                <Divider />
                <CommonBlock
                    required
                    margin
                    label="Показатель степени локализации, %"
                    select={
                        <Form.Item
                            name="localization_degree"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (value != null && (value < 0 || value > 100)) {
                                            return Promise.reject(
                                                new Error(
                                                    'Значение процента не может быть меньше нуля, либо больше 100',
                                                ),
                                            )
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input className={style.input} type="number" />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    required
                    customStyle={{ marginTop: '28px' }}
                    label={'Квалификационная категория ГО (вид источника энергии)'}
                    select={
                        <Form.Item
                            name="renewable_energy_type_id"
                            rules={[{ required: true, message: MESSAGE }]}
                        >
                            <DropdownSelector2
                                placeholder={isCreate && 'Выберите из списка'}
                                options={fullRenewableEnergyTypesOptions}
                                customStyle={{ width: '400px' }}
                            />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    margin
                    label={t('edit.fuelType')}
                    select={
                        <Form.Item name="fuel_ids">
                            <TagSelector
                                customStyle={{ width: '400px' }}
                                options={fullFuelOptions || []}
                            />
                        </Form.Item>
                    }
                />
                <Alert
                    message={
                        <div className={style.alertHeader}>
                            Указать все виды топлива, использующиеся на ГО
                        </div>
                    }
                    className={style.alertTop}
                    type="warning"
                    showIcon
                />
                <CommonBlock
                    customStyle={{ minHeight: '32px' }}
                    required
                    margin
                    label="Установленная мощность, МВт"
                    select={
                        <Form.Item
                            name="power"
                            rules={[
                                { required: true, message: MESSAGE },
                                () => ({
                                    validator(_, value) {
                                        if (value != null && (value < 1 || value > 2000000)) {
                                            return Promise.reject(
                                                new Error(
                                                    'Укажите значение в диапазоне от 1 до 2.000.000',
                                                ),
                                            )
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input className={style.input} type="number" />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    customStyle={{ height: '32px' }}
                    required
                    margin
                    label={t('edit.manufMode')}
                    select={
                        <Form.Item
                            name="production_mode_id"
                            rules={[{ required: true, message: MESSAGE }]}
                        >
                            <DropdownSelector2
                                placeholder={isCreate && 'Выберите из списка'}
                                options={productionModesOptions}
                                customStyle={{ width: '400px' }}
                            />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    customStyle={{ height: '32px' }}
                    required
                    margin
                    label={t('edit.ownerBase')}
                    select={
                        <Form.Item
                            name="ownership_id"
                            rules={[{ required: true, message: MESSAGE }]}
                        >
                            <DropdownSelector2
                                placeholder={isCreate && 'Выберите из списка'}
                                options={ownershipsOptions}
                                customStyle={{ width: '400px' }}
                            />
                        </Form.Item>
                    }
                />
                {isForRetail && (
                    <CommonBlock
                        customStyle={{ height: '32px' }}
                        required
                        margin
                        label={t('edit.ownerGO')}
                        select={<Input className={style.input} value={companyName} disabled />}
                    />
                )}
                <CommonBlock
                    customStyle={{ height: '32px' }}
                    margin
                    label={t('edit.support')}
                    select={
                        <Form.Item name="aid_id">
                            <DropdownSelector2
                                placeholder={isCreate && 'Выберите из списка'}
                                options={aidsOptions}
                                customStyle={{ width: '400px' }}
                                noDefaultValue
                                allowClear
                            />
                        </Form.Item>
                    }
                />

                {!isGOOwner && (
                    <>
                        <CommonBlock
                            required
                            margin
                            label={'№ квалификационного свидетельства'}
                            select={
                                <Form.Item
                                    name="qualification_number"
                                    rules={[{ required: true, message: MESSAGE }]}
                                >
                                    <Input className={style.input} />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            required
                            margin
                            label={'Дата квалификации'}
                            select={
                                <Form.Item
                                    name="qualification_date"
                                    rules={[{ required: true, message: MESSAGE }]}
                                >
                                    <DatePicker className={style.date} format="DD.MM.YYYY" />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            customStyle={{ marginTop: '24px' }}
                            label={'Отметка о выдаче дубликата квалификационного свидетельства'}
                            select={
                                <Form.Item name="qualification_point">
                                    <Input className={style.input} />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            customStyle={{ marginTop: '40px' }}
                            label={'Дата последней проверки'}
                            select={
                                <Form.Item name="last_check_date">
                                    <DatePicker className={style.date} format="DD.MM.YYYY" />
                                </Form.Item>
                            }
                        />
                    </>
                )}

                <Divider />
                <div style={{ marginLeft: '215px' }}>
                    <Form.Item name="selectedFiles" rules={[{ required: true, message: MESSAGE }]}>
                        <DocumentsBlock customStyle={{ width: '400px' }} />
                    </Form.Item>
                </div>
                {type === 'create' && (
                    <>
                        <Divider />
                        <Alert
                            message={
                                <div>{`После согласования карточки ЗГО оператором вам необходимо будет оплатить комиссию на сумму ${rate}, а также иметь оплаченный лицевой счет.`}</div>
                            }
                            className={style.alert}
                            type="warning"
                            showIcon
                        />
                        <Divider />
                        <div className={style.buttonWrapper}>
                            <PrimaryButton
                                onClick={getBack}
                                value="Отмена"
                                isCancel
                                customStyle={{ padding: '8px 16px' }}
                            />
                            <PrimaryIconButton
                                size="large"
                                onClick={handleConfirm}
                                loading={isLoadingGoCreate}
                                disabled={isLoadingGoCreate}
                                value="Подписать и отправить"
                                customStyle={{ padding: '8px 16px' }}
                            />
                        </div>
                    </>
                )}
            </Form>
            <SearchTSO
                isModalVisible={isSearchTSOModalVisible}
                onCancel={() => setIsSearchTSOModalVisible(false)}
                onSelect={(tso) => {
                    setSelectedTso(tso)
                    setIsSearchTSOModalVisible(false)
                }}
            />
            <SearchGP
                isModalVisible={isSearchGPModalVisible}
                onCancel={() => setIsSearchGPModalVisible(false)}
                onSelect={(gp) => {
                    setSelectedGp(gp)
                    setIsSearchGPModalVisible(false)
                }}
            />
            <AddEditTSO
                isModalVisible={openAddEditTso}
                onCancel={() => setOpenAddEditTso(false)}
                onChange={(tso) => {
                    setOpenAddEditTso(false)
                    setSelectedTso(tso)
                }}
            />
            <AddEditGP
                isModalVisible={openAddEditGp}
                onCancel={() => setOpenAddEditGp(false)}
                onChange={(gp) => {
                    setOpenAddEditGp(false)
                    setSelectedGp(gp)
                }}
            />
            <QualificationCategoryModal
                isModalVisible={isQualificationCategoryModalVisible}
                onClose={() => setIsQualificationCategoryModalVisible(false)}
            />
        </div>
    )
}

export default GeneratorLayout
