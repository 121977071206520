import { FC, useRef, useEffect, useState } from 'react'
import { Routes, Route, Outlet, Navigate, useNavigate } from 'react-router-dom'
import { ConfigProvider, notification } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import style from './style.m.less'
import { Bell } from '../../assets/icons'
import { getWsUrl } from '../../services/websocket'
import useWebSocket from '../hooks/useWebsocket'
import useMount from '../hooks/useMount'
import { logout } from '../../store/auth'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Header from '../Header'
import Footer from '../Footer'
import Generators from '../Generators'
import DetailedCard from '../DetailedCard'
import OwnerCard from '../OwnerCard'
import Owners from '../Owners'
import GreenTools from '../GreenTools'
import UserGreenTools from '../UserGreenTools'
import Certificate from '../Certificate'
import { UserCertificate } from '../UserCertificate'
import { UserContract } from '../UserContract'
import { UserThirdPartyTools } from '../UserThirdPartyTools'
import Login from '../Login'
import Settings from '../Settings'
import MySettings from '../MySettings'
import CreateGenerator from '../CreateGenerator'
import { Profile, MyGenerators, AllOperations } from '../Profile'
import { Edit, PasswordChange, AddDocuments, ChangeRole } from '../Edit'
import { ContinueRegistration } from '../ContinueRegistration'
import Main from '../Main'
import NewsAndEvents from '../NewsAndEvents'
import NewsOrEvent from '../NewsOrEvent'
import NewsChangeCreate from '../NewsChangeCreate'
import Documents from '../Documents'
import QuestionnaireApproval from '../QuestionnaireApproval'
import QuestionnaireApprovalItem from '../QuestionnaireApprovalItem'
import { KUApproval, KUCard } from '../KUApproval'
import ZDApproval from '../ZDApproval'
import ZDApprovalItem from '../ZDApprovalItem'
import GOApproval from '../GOApproval'
import GOApprovalItem from '../GOApprovalItem'
import UserHistoryOperations from '../UserHistoryOperations'
import UserOtherHistoryOperations from '../UserOtherHistoryOperations'
import UserContractHistoryOperations from '../UserContractHistoryOperations'
import UserCertificateHistoryOperations from '../UserCertificateHistoryOperations'
import { MyProfile, MyAllOperations } from '../MyProfile/'
import { EditMyProfile } from '../EditMyProfile/'
import { Password } from '../MyProfile/components'
import { CertificateRepayment } from '../CertificateRepayment/'
import { ImplementationGenerationAttributes } from '../ImplementationGenerationAttributes/'
import GOSettings from '../GOSettings'
import { GOProfile } from '../GOProfile'
import { GOPaymentOperations } from '../GOProfile'
import { GOPersonalOperations } from '../GOProfile'
import { AddEditGO } from '../AddEditGO'
import { GOActivation } from '../GOActivation'
import { OwnRepaymentGA } from '../OwnRepaymentGA'
import RelatedZGO from '../RelatedZGO'
import MyZGO from '../MyZGO'
import { TSOProfile } from '../TSOProfile'
import { KUData, DocsCard } from '../KUData'
import KUSubmitData from '../KUSubmitData'
import GoKuSubmitFuel from '../GoKuSubmitFuel'
import GODataKU from '../GODataKU'
import GODataKUPeriod from '../GODataKUPeriod'
import GOSubmitDataKU from '../GOSubmitDataKU'
import Notifications from '../Notifications'
import TariffsSettings from '../TariffsSettings'
import { Audit, AuditItem } from '../Audit'
import { Billing, EditCredentials } from '../Billing'
import { ErrorScreen } from '../ErrorBoundary'
import { SuperAdminEditProfile } from '../SuperAdminEditProfile'
import { Helpdesk, CreateAppeal, ReadAppeal, CompleteAppeal } from '../Helpdesk/'
import { useAppDispatch } from '../../store/hooks'
import { getUserInfo } from '../../store/user'
import CertificateValidityPeriod from '../CertificateValidityPeriod'
import { AGValidityPeriod } from '../AGValidityPeriod'
import { AdminPaymentOperations } from '../AdminPaymentOperations'
import CertificatesInPledge from '../CertificatesInPledge'
import IssueCertificate from '../IssueCertificate'
import { Users } from '../Users'
import { UserCard } from '../UserCard'
import { UserOperationsHistory } from '../UserOperationsHistory'
import { EditUser } from '../EditUser'
import { Registry } from '../Registry'
import { FuelRegistry, FuelCard } from '../FuelRegistry'
import { FuelApproval, FuelCardAdmin } from '../FuelApproval'
import { KUOwner, KUOwnerCard } from '../KUOwner'
import KUSubmitOwner from '../KUSubmitOwner'
import SystemDatesSettings from '../SystemDatesSettings'
import AdminUploading from '../AdminUploading'
import { ZDData, DocsCard as ZDDocsCard } from '../ZDData'
import ZDSubmitData from '../ZDSubmitData'
import { RealizationAG } from '../RealizationAG'
import { GenerationAttributes, GenerationAttributesGOCard } from '../GenerationAttributes'
import ThirdPartyToolsApproval from '../ThirdPartyToolsApproval/ThirdPartyToolsApproval'
import ThirdPartyToolsOperations from '../ThirdPartyToolsOperations'
import { addNewMessage } from '../../store/notifications'

const token = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : sessionStorage.getItem('accessToken')

const isAuth = !!token

interface IProtectedRoute {
    isAuth: boolean
    redirectPath?: string
    children: React.ReactElement
}

interface ILayout {
    isUnread: boolean
}

const ProtectedRoute: FC<IProtectedRoute> = ({ isAuth, redirectPath = '/auth', children }) => {
    if (!isAuth) {
        return <Navigate to={redirectPath} replace />
    }

    return children ? children : <Outlet />
}

const Layout: FC<ILayout> = ({ isUnread }) => {
    return (
        <>
            <Header isUnread={isUnread} isAuth={isAuth} />
            <Outlet />
            <Footer />
        </>
    )
}

const UnauthorizedLayout: FC<ILayout> = ({ isUnread }) => {
    return (
        <>
            <Header isUnread={isUnread} isAuth={isAuth} />
            <Outlet />
        </>
    )
}

const App: FC = () => {
    const [api, contextHolder] = notification.useNotification()
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)

    const dispatch = useAppDispatch()

    const [isUnread, setIsUnread] = useState<boolean>(false)

    const ws = useWebSocket({
        socketUrl: getWsUrl(`/api/notifier/v1/ws`),
    })

    const setReaded = () => {
        setIsUnread(false)
    }

    useEffect(() => {
        if (ws.data) {
            const wsMessage = ws.data.message
            if (wsMessage?.event === 'feeds_total' && wsMessage?.count_new > 0) {
                setIsUnread(true)
            } else if (wsMessage?.event === 'feed') {
                const { title, links } = wsMessage?.feed
                let { message } = wsMessage?.feed

                if (links.length) {
                    message = message.replace(
                        '{{1}}',
                        `<a href=${links[0].link}>${links[0].name}</a>`,
                    )
                }

                api.info({
                    message: <div className={style.notificationTitle}>{title}</div>,
                    description: (
                        <div
                            className={style.notificationMessage}
                            dangerouslySetInnerHTML={{
                                __html: message,
                            }}
                        />
                    ),
                    placement: 'bottomRight',
                    icon: <Bell />,
                    onClick: () => navigate('/notifications'),
                    style: { cursor: 'pointer' },
                })
                dispatch(addNewMessage(ws.data))
            } else if (wsMessage?.event === 'logout') {
                dispatch(logout())
                navigate('/auth')
            }
        }
    }, [ws.data])

    useMount(() => {
        if (isAuth) {
            dispatch(getUserInfo())
        }
    })

    return (
        <ScrollToTop>
            <ConfigProvider locale={ruRU}>
                <div className="App" ref={ref}>
                    {contextHolder}
                    <Routes>
                        <Route path="/" element={<UnauthorizedLayout isUnread={isUnread} />}>
                            <Route path="auth" element={<Login type={'auth'} />} />
                            <Route path="register" element={<Login type={'registration'} />} />
                        </Route>
                        <Route path="/" element={<Layout isUnread={isUnread} />}>
                            <Route index element={<Main />} />
                            <Route path="main" element={<Main />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="edit" element={<Edit />} />
                            <Route path="change-password" element={<PasswordChange />} />
                            <Route path="pass/restore" element={<Login type={'setPassword'} />} />
                            <Route
                                path="restore-password"
                                element={<Login type={'restorePassword'} />}
                            />
                            <Route
                                path="add-documents"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <AddDocuments />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="change-role"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <ChangeRole />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="documents" element={<Documents />} />
                            <Route
                                path="create-generator"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <CreateGenerator />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="generators" element={<Generators isAuth={isAuth} />} />
                            <Route
                                path="generators/:isactive"
                                element={<Generators isAuth={isAuth} />}
                            />
                            <Route path="news/deal/:type/:id" element={<NewsChangeCreate />} />
                            <Route path="news/deal/:type" element={<NewsChangeCreate />} />
                            <Route path="news/:id" element={<NewsOrEvent />} />
                            <Route path="news" element={<NewsAndEvents />} />
                            <Route path="green-tools" element={<GreenTools isAuth={isAuth} />} />
                            <Route path="owners" element={<Owners />} />
                            <Route
                                path="generator-card/:id"
                                element={<DetailedCard isAuth={isAuth} />}
                            />
                            <Route path="owner/:id" element={<OwnerCard />} />
                            <Route path="certificate-card/:id" element={<Certificate />} />
                            <Route path="user-certificate-card/:id" element={<UserCertificate />} />
                            <Route path="user-contract-card/:id" element={<UserContract />} />
                            <Route path="user-tools-card/:id" element={<UserThirdPartyTools />} />
                            <Route
                                path="certificate-history-operations/:id"
                                element={<UserCertificateHistoryOperations />}
                            />
                            <Route
                                path="contract-history-operations/:id"
                                element={<UserContractHistoryOperations />}
                            />
                            <Route
                                path="other-history-operations/:id"
                                element={<UserOtherHistoryOperations />}
                            />
                            <Route path="my-generators" element={<MyGenerators />} />
                            <Route path="my-generators/:tab" element={<MyGenerators />} />
                            <Route path="settings" element={<Settings />} />
                            <Route path="tariffs-settings" element={<TariffsSettings />} />
                            <Route path="system-dates-settings" element={<SystemDatesSettings />} />
                            <Route
                                path="admin-deposit"
                                element={<AdminPaymentOperations type="deposit" />}
                            />
                            <Route
                                path="admin-withdraw"
                                element={<AdminPaymentOperations type="withdraw" />}
                            />
                            <Route
                                path="my-appeals"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <Helpdesk />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="user-appeals"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <Helpdesk />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="new-appeal"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <CreateAppeal />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="appeal/:id"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <ReadAppeal />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="complete-appeal/:id"
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <CompleteAppeal />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="all-operations" element={<AllOperations />} />
                            <Route path="audit" element={<Audit />} />
                            <Route path="audit/:id" element={<AuditItem key={'audit-item'} />} />
                            <Route
                                path="audit/history/:id"
                                element={<AuditItem key={'audit-history'} />}
                            />
                            <Route path="billing" element={<Billing />} />
                            <Route path="edit-credentials" element={<EditCredentials />} />
                            <Route
                                path="invalid-link"
                                element={<ErrorScreen type="invalidLink" />}
                            />
                            <Route
                                path="notifications"
                                element={<Notifications setReaded={setReaded} />}
                            />
                            <Route
                                path="mail-update/update"
                                element={<Login type={'emailConfirmation'} />}
                            />
                            <Route
                                path="continue-registration"
                                element={<ContinueRegistration type={'registration'} />}
                            />
                            <Route path="add-user" element={<ContinueRegistration type="add" />} />
                            <Route
                                path="edit-form"
                                element={<ContinueRegistration type="edit" />}
                            />
                            <Route
                                path="my-green-tools"
                                element={<GreenTools isAuth={isAuth} isUserType />}
                            />
                            <Route
                                path="user-my-green-tools"
                                element={<UserGreenTools isAuth={isAuth} isUserType />}
                            />
                            <Route
                                path="generation-attributes"
                                element={<GenerationAttributes />}
                            />
                            <Route
                                path="generation-attributes/go/:id"
                                element={<GenerationAttributesGOCard />}
                            />
                            <Route path="issue-certificate" element={<IssueCertificate />} />
                            <Route path="users" element={<Users />} />
                            <Route path="users/individual" element={<Users tabKey="1" />} />
                            <Route path="users/legal" element={<Users tabKey="2" />} />
                            <Route path="users/admins" element={<Users tabKey="1" />} />
                            <Route path="users/admins/physical" element={<Users subTabKey="0" />} />
                            <Route path="users/admins/legal" element={<Users subTabKey="2" />} />
                            <Route
                                path="users/admins/individual"
                                element={<Users subTabKey="1" />}
                            />
                            <Route path="user/:id" element={<UserCard />} />
                            <Route
                                path="user/operations-history/:id"
                                element={<UserOperationsHistory />}
                            />
                            <Route path="user/edit/:id" element={<EditUser />} />
                            <Route path="registry" element={<Registry />} />
                            <Route path="fuel-registry" element={<FuelRegistry />} />
                            <Route path="fuel-card/:id" element={<FuelCard />} />
                            <Route path="fuel-approval" element={<FuelApproval />} />
                            <Route path="fuel-card-admin/:id" element={<FuelCardAdmin />} />
                            <Route
                                path="third-party-tools-approval"
                                element={<ThirdPartyToolsApproval />}
                            />
                            <Route
                                path="questionnaire-approval"
                                element={<QuestionnaireApproval />}
                            />
                            <Route
                                path="questionnaire-approval/:uid/:id/:prevId"
                                element={<QuestionnaireApprovalItem />}
                            />
                            <Route
                                path="questionnaire-approval/:uid/:id"
                                element={<QuestionnaireApprovalItem />}
                            />
                            <Route
                                path="register-confirm"
                                element={<Login type={'setPassword'} />}
                            />
                            <Route path="ku-approval" element={<KUApproval />} />
                            <Route path="ku-card/:id" element={<KUCard />} />
                            <Route path="zd-approval" element={<ZDApproval />} />
                            <Route path="zd-approval/:id" element={<ZDApprovalItem />} />
                            <Route path="go-approval" element={<GOApproval />} />
                            <Route path="go-approval/:id" element={<GOApprovalItem />} />
                            <Route path="my-profile" element={<MyProfile />} />
                            <Route path="edit-my-profile" element={<EditMyProfile />} />
                            <Route path="password" element={<Password />} />
                            <Route path="my-all-operations" element={<MyAllOperations />} />
                            <Route path="admin-uploading" element={<AdminUploading />} />
                            <Route path="zd-data" element={<ZDData />} />
                            <Route path="zd-submit-data" element={<ZDSubmitData />} />
                            <Route path="zd-card-docs/:type/:id" element={<ZDDocsCard />} />
                            <Route
                                path="user-history-operations"
                                element={<UserHistoryOperations />}
                            />
                            <Route
                                path="certificate-repayment"
                                element={<CertificateRepayment />}
                            />
                            <Route
                                path="implementation-generation-attributes"
                                element={<ImplementationGenerationAttributes />}
                            />
                            <Route path="go-settings" element={<GOSettings />} />
                            <Route path="go-profile" element={<GOProfile />} />
                            <Route path="go-payment-operations" element={<GOPaymentOperations />} />
                            <Route
                                path="go-personal-operations/:id"
                                element={<GOPersonalOperations />}
                            />
                            <Route path="edit-go/:id" element={<AddEditGO />} />
                            <Route path="adding-go" element={<AddEditGO />} />
                            <Route path="go-activation/:id" element={<GOActivation />} />
                            <Route path="own-repayment-ga" element={<OwnRepaymentGA />} />
                            <Route path="my-settings" element={<MySettings />} />
                            <Route path="related-zgo" element={<RelatedZGO />} />
                            <Route path="related-zgo/:id" element={<MyZGO />} />
                            <Route path="tso-profile" element={<TSOProfile />} />
                            <Route path="ku-data" element={<KUData />} />
                            <Route path="ku-owner" element={<KUOwner />} />
                            <Route path="ku-owner-card/:id" element={<KUOwnerCard />} />
                            <Route path="ku-card-docs/:id" element={<DocsCard />} />
                            <Route path="ku-submit-data" element={<KUSubmitData />} />
                            <Route path="ku-submit-owner" element={<KUSubmitOwner />} />
                            <Route path="go-data-ku-period/:id" element={<GODataKUPeriod />} />
                            <Route path="go-data-ku" element={<GODataKU />} />
                            <Route path="go-submit-data-ku" element={<GOSubmitDataKU />} />
                            <Route path="go-ku-submit-fuel" element={<GoKuSubmitFuel />} />
                            <Route
                                path="super-admin-edit-profile"
                                element={<SuperAdminEditProfile />}
                            />
                            <Route
                                path="certificate-validity-period"
                                element={<CertificateValidityPeriod />}
                            />
                            <Route path="ag-validity-period" element={<AGValidityPeriod />} />
                            <Route
                                path="certificates-in-pledge"
                                element={<CertificatesInPledge />}
                            />
                            <Route path="realization-ag" element={<RealizationAG />} />
                            <Route
                                path="payment-realization-ag"
                                element={<RealizationAG isWithPayment />}
                            />
                            <Route
                                path="third-party-tools-adding"
                                element={<ThirdPartyToolsOperations isAdding />}
                            />
                            <Route
                                path="third-party-tools-repayment"
                                element={<ThirdPartyToolsOperations />}
                            />
                        </Route>
                    </Routes>
                </div>
            </ConfigProvider>
        </ScrollToTop>
    )
}

export default App
