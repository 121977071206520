import { Documents as Docs } from './Documents'
import { Questions } from './Questions'
import Contacts from './Contacts'
import style from './style.m.less'

import { FC } from 'react'


const Documents: FC = () => {
    
    return <div className={style.documents}>
        <Docs />
        <div className={style.container}>
            <Questions/>
            <Contacts />
        </div>
    </div>
}

export default Documents
