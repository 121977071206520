import { FC } from 'react'
import { Divider } from 'antd'
import { CardInfoBlock } from '../../../ui'
import { Link } from 'react-router-dom'
import { Flash } from '../../../../assets/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { EUserType } from '../../../../constants/approval'

interface IInfoRowSelector {
    web: string
    email: string
    total_power: number
}

const InfoRowSelector = ({ web, email, total_power }: IInfoRowSelector) => {
    const { t } = useTranslation()

    return (
        <div className={style.infoRow}>
            {web && (
                <>
                    <CardInfoBlock
                        label={t('generatorCard.webSide')}
                        value={
                            <a href={web} target="_blank" rel="noreferrer">
                                {web}
                            </a>
                        }
                    />
                    <Divider className={style.divider} type={'vertical'} />
                </>
            )}
            <CardInfoBlock
                label={t('generatorCard.email')}
                value={
                    <a href={`mailto:${email}`} rel="noreferrer">
                        {email}
                    </a>
                }
            />
            <Divider className={style.divider} type={'vertical'} />
            <CardInfoBlock
                label={t('generatorCard.powerSum')}
                value={`${(Math.round(total_power / 10) / 100).toLocaleString()} ${t(
                    'generatorCard.scale',
                )}`}
                icon={<Flash />}
                gap={7}
            />
        </div>
    )
}

export interface IOwner {
    uid: string
    user_type: EUserType
    company_name_short: string
    first_name: string
    last_name: string
    middle_name: string
    web: string
    email: string
    total_power: number
}

interface IGeneratorCard {
    owner: IOwner
}

const GeneratorCard: FC<IGeneratorCard> = ({ owner }) => {
    const {
        uid,
        user_type,
        company_name_short,
        first_name,
        last_name,
        middle_name,
        total_power,
        web,
        email,
    } = owner

    const name =
        user_type === EUserType.UrLico
            ? company_name_short
            : user_type === EUserType.IP
            ? `ИП ${last_name} ${first_name} ${middle_name}`
            : `${last_name} ${first_name} ${middle_name}`

    return (
        <div className={style.cardMain}>
            <div>
                <Link to={`/owner/${uid}`} className={style.cardHeader}>
                    {name}
                </Link>
                <InfoRowSelector total_power={total_power} web={web} email={email} />
            </div>
        </div>
    )
}

export default GeneratorCard
