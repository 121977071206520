import SortSwitch, { ESortDirection } from '../../../ui/SortSwitch/SortSwitch'
import { FC, useState } from 'react'
import { Import } from '../../../../assets/icons'
import { IconButton, SortButton, Popover } from '../../../ui/'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Input } from 'antd'

export enum ESortType {
    Date = 0, // 0 - по Дате добавления в систему;
    Name = 1, // 1 - по Наименованию Владельца ГО;
    Power = 2, // 2 - по суммарной мощности;
}

interface IControls {
    name: string
    sortType?: ESortType
    desc?: boolean // false - ascending; true - descending
    onChangeUserName: (name: string) => void
    onChangeSortType: (sortType?: ESortType) => void
    onDescendingType: (desc?: boolean) => void
    titleList?: any[]
    showDownloadData?: boolean
}

const Controls: FC<IControls> = ({
    name,
    sortType,
    desc,
    onChangeUserName,
    onChangeSortType,
    onDescendingType,
    showDownloadData = true,
}) => {
    const { t } = useTranslation()

    const [sortOpen, setSortOpen] = useState(false)

    const dateValue =
        sortType === ESortType.Date ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined
    const nameValue =
        sortType === ESortType.Name ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined
    const powerValue =
        sortType === ESortType.Power ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined

    const handleChange = (sortType: ESortType, sortDirection?: ESortDirection) => {
        if (sortDirection !== undefined) {
            onChangeSortType(sortType)
            onDescendingType(sortDirection === ESortDirection.DESC)
        } else {
            onChangeSortType()
            onDescendingType()
        }
        setSortOpen(false)
    }

    return (
        <div className={style.controlsMain}>
            <div className={style.sortWrapper}>
                <Popover
                    onClickOut={setSortOpen}
                    open={sortOpen}
                    content={
                        <div>
                            <div className={style.sortTitle}>{t('newsAndEvents.sortBy')}</div>
                            <div className={style.sortItem}>
                                <span className={style.sortItemLabel}>
                                    Дате добавления в систему
                                </span>
                                <SortSwitch
                                    value={dateValue}
                                    onChange={(sortDirection) => {
                                        handleChange(ESortType.Date, sortDirection)
                                    }}
                                />
                            </div>
                            <div className={style.sortItem}>
                                <span className={style.sortItemLabel}>
                                    Наименованию Владельца ГО
                                </span>
                                <SortSwitch
                                    value={nameValue}
                                    onChange={(sortDirection) => {
                                        handleChange(ESortType.Name, sortDirection)
                                    }}
                                />
                            </div>
                            <div className={style.sortItem}>
                                <span className={style.sortItemLabel}>Суммарной мощности</span>
                                <SortSwitch
                                    value={powerValue}
                                    onChange={(sortDirection) => {
                                        handleChange(ESortType.Power, sortDirection)
                                    }}
                                />
                            </div>
                        </div>
                    }
                >
                    <SortButton onClick={() => setSortOpen((open) => !open)} />
                </Popover>
            </div>
            {showDownloadData && (
                <IconButton
                    icon={<Import />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                >
                    Выгрузить данные
                </IconButton>
            )}
            <Input
                allowClear
                placeholder="Введите наименование Владельца ЗГО"
                value={name}
                onChange={(event) => onChangeUserName(event.target.value)}
                className={style.input}
            />
        </div>
    )
}

export default Controls

export const ControlsSwitch: FC<IControls> = ({
    sortType,
    desc,
    onChangeSortType,
    onDescendingType,
    titleList = [],
}) => {
    const { t } = useTranslation()

    const [sortOpen, setSortOpen] = useState(false)

    const dateValue =
        sortType === ESortType.Date ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined
    const nameValue =
        sortType === ESortType.Name ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined
    const powerValue =
        sortType === ESortType.Power ? (desc ? ESortDirection.DESC : ESortDirection.ASC) : undefined

    const handleChange = (sortType: ESortType, sortDirection?: ESortDirection) => {
        if (sortDirection !== undefined) {
            onChangeSortType(sortType)
            onDescendingType(sortDirection === ESortDirection.DESC)
        } else {
            onChangeSortType()
            onDescendingType()
        }
    }

    return (
        <div className={style.sortWrapper}>
            <Popover
                onClickOut={setSortOpen}
                open={sortOpen}
                content={
                    <div>
                        <div className={style.sortTitle}>{t('newsAndEvents.sortBy')}</div>
                        <div className={style.sortItem}>
                            <span className={style.sortItemLabel}>{titleList[0] || ''}</span>
                            <SortSwitch
                                value={dateValue}
                                onChange={(sortDirection) => {
                                    handleChange(ESortType.Date, sortDirection)
                                }}
                            />
                        </div>
                        <div className={style.sortItem}>
                            <span className={style.sortItemLabel}>{titleList[1] || ''}</span>
                            <SortSwitch
                                value={nameValue}
                                onChange={(sortDirection) => {
                                    handleChange(ESortType.Name, sortDirection)
                                }}
                            />
                        </div>
                        <div className={style.sortItem}>
                            <span className={style.sortItemLabel}>{titleList[2] || ''}</span>
                            <SortSwitch
                                value={powerValue}
                                onChange={(sortDirection) => {
                                    handleChange(ESortType.Power, sortDirection)
                                }}
                            />
                        </div>
                    </div>
                }
            >
                <SortButton onClick={() => setSortOpen((open) => !open)} />
            </Popover>
        </div>
    )
}
