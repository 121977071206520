import { FC } from 'react'
import { Tag as AntdTag } from 'antd'
import { BackArrow, Tag } from '../../../ui'
import { getStatus } from '../../../Profile/components'
import PowerTypeTag from '../PowerTypeTag/PowerTypeTag'
import style from './style.m.less'

interface IHeaderBlock {
    label?: string
    energyType?: string
    status?: number
    tsoNotConfirmed?: boolean
    gpNotConfirmed?: boolean
    showConfirmedTag?: boolean
}

const HeaderBlock: FC<IHeaderBlock> = ({
    label,
    energyType,
    status,
    showConfirmedTag,
    tsoNotConfirmed,
    gpNotConfirmed,
}) => {
    return (
        <div className={style.headerMain}>
            <div className={style.headerBlock}>
                <BackArrow />
                <div className={style.mainHeader}>{label}</div>
                {energyType && (
                    <PowerTypeTag customStyle={{ marginLeft: '16px' }} value={energyType} />
                )}
                {getStatus(status)}
                {showConfirmedTag && (
                    <AntdTag className={style.statusTag}>
                        {tsoNotConfirmed || gpNotConfirmed
                            ? 'Связь не подтверждена'
                            : 'Связь подтверждена'}
                    </AntdTag>
                )}
            </div>
        </div>
    )
}

export default HeaderBlock
