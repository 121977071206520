import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message } from 'antd'
import { Card, PrimaryButton, PrimaryIconButton, IFile, LoadingContainer, DatePicker } from '../ui'
import DocumentsBlock from '../AdminUploading/components/DocumentsBlock/DocumentsBlock'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import { postZDWm, postZDRm } from './config'
import IAppState from 'store/states'
import { signBody } from '../helpers'
import { useCurrentUser } from '../hooks/useCurrentUser'

import style from './style.m.less'
import moment from 'moment'

const ZDSubmitData: FC = () => {
    const navigate = useNavigate()
    const [files, setFiles] = useState<IFile[]>([])
    const [date, setDate] = useState<string>()
    const { isUserAdmin } = useCurrentUser()
    const { fetch: sendZDWm, isLoading: isLoadingWm } = useRequest(postZDWm)
    const { fetch: sendZDRm, isLoading: isLoadingRm } = useRequest(postZDRm)

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    const fileHandler = (list: any) => {
        if (files.length <= 6) {
            return setFiles(list)
        }
    }

    const handleConfirm = async () => {
        if (files.length) {
            const body = {
                id: files[0].id,
                period: moment(date).format('YYYY-MM'),
            }
            if (isUserAdmin) {
                sendZDWm({
                    body,
                    successMessage: {
                        title: 'Успешно',
                        description: 'Данные по ЗД успешно поданы',
                    },
                    onSuccess: () => navigate('/zd-data'),
                    errorCodeMessage: true,
                })
            } else {
                const { request_id, signature } = await signBody(body, data)
                sendZDRm({
                    body,
                    getParams: {
                        request_id,
                    },
                    config: {
                        headers: {
                            Signature: signature,
                        },
                    },
                    successMessage: {
                        title: 'Успешно',
                        description: 'Данные по ЗД успешно поданы',
                    },
                    onSuccess: () => navigate('/zd-data'),
                    errorCodeMessage: true,
                })
            }
        } else {
            message.error('Невозможно подать данные - выберите хотя бы один файл')
        }
    }

    const isLoading = isUserAdmin ? isLoadingWm : isLoadingRm

    return (
        <LoadingContainer isLoading={isLoading}>
            <div className={style.container}>
                <div className={style.titleWrapper}>
                    <div>
                        <h1 className={style.title}>Подача данных по ЗД</h1>
                    </div>
                </div>
                <Card>
                    <div className={style.formItem}>
                        <div className={style.label}>Отчетный период</div>
                        <DatePicker
                            defaultValue={date}
                            onChange={(value) => setDate(moment.utc(value).format())}
                            format="MMMM, YYYY"
                            className={style.monthInput}
                            picker="month"
                        />
                    </div>
                    <Divider />
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        required
                        serviceId={4}
                        description="Загрузите файл, из которого хотите импортировать данные"
                        customStyle={{ width: '440px' }}
                    />
                    <Divider />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            onClick={() => navigate(-1)}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryIconButton
                            size="large"
                            onClick={handleConfirm}
                            value={isUserAdmin ? 'Подать данные' : 'Подписать и подать данные'}
                            disabled={!files.length}
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default ZDSubmitData
