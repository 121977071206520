import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import IAppState from '../../../../store/states'
import { GeneratorInfoFrame, IconButton, CenteredSpin } from '../../../ui'
import { Edit } from '../../../../assets/icons'
import { profileInfoConfig } from './profileInfoConfig'
import style from './style.m.less'
import SignUtils from '../../../../utils/sign/SignUtils'

const SuperAdminGeneralInfo: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [certificateData, setCertificateData] = useState({
        allCertificates: [],
        currentCertificate: null
    })

    const {fetch, result} = useRequest({
        link: '/api/users/v1/profile',
        method: RequestTypes.post
    })

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data, error, isLoading } = userInfo

    const onAddCertificate = async () => {
        const allCertificates = await SignUtils.getAllCertificates()
        setCertificateData(prev => ({...prev, allCertificates}))
    }

    if (error === 'no refresh token') {
        navigate('/auth')
        window.location.reload()
    }

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <div className={style.generalInfo}>
            <GeneratorInfoFrame
                label={'Общая информация'}
                config={profileInfoConfig(data)}
                customStyle={{ marginTop: 0 }}
                bottomChildren={
                    <>
                        <Divider type={'horizontal'} />
                        <div className={style.configButtonsBlock}>
                            <Link to={'/super-admin-edit-profile'} state={{ type: 'profile' }}>
                                <IconButton
                                    disabled={
                                        data?.profile_status === 1 || data?.profile_status === 2
                                    }
                                    icon={<Edit />}
                                >
                                    Редактировать профиль
                                </IconButton>
                            </Link>
                        </div>
                    </>
                }
                isProfile
            />
        </div>
    )
}

export default SuperAdminGeneralInfo
