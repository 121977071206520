import request from '../request'
import { IRequest } from '../types'

export const getPaymentHistory = async (
    limit: number,
    offset: number,
    filters: any,
    id?: string,
): Promise<IRequest> => {
    try {
        const { data, status } = await request.post(
            `/api/audit/v1/event-history-payment-acc${id ? `?id=${id}` : ''}`,
            {
                limit,
                offset,
                filters,
            },
        )

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}

export const getPersAccHistory = async (
    id: number,
    limit: number,
    offset: number,
    filters: any,
): Promise<IRequest> => {
    try {
        const { data, status } = await request.post(`/api/audit/v1/event-history-acc?id=${id}`, {
            limit,
            offset,
            filters,
        })

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}

export const getCertHistory = async (
    id: string,
    limit: number,
    offset: number,
    filters: any,
): Promise<IRequest> => {
    try {
        const { data, status } = await request.post(`/api/audit/v1/event-history-certs?id=${id}`, {
            limit,
            offset,
            filters,
        })

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}

export const getOtherHistory = async (
    id: string,
    limit: number,
    offset: number,
    filters: any,
): Promise<IRequest> => {
    try {
        const { data, status } = await request.post(
            `/api/audit/v1/event-history-ext-instrs?id=${id}`,
            {
                limit,
                offset,
                filters,
            },
        )

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}

export const getContractsHistory = async (
    id: string,
    limit: number,
    offset: number,
    filters: any,
): Promise<IRequest> => {
    try {
        const { data, status } = await request.post(
            `/api/audit/v1/event-history-contracts?id=${id}`,
            {
                limit,
                offset,
                filters,
            },
        )

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}
