interface IPayTypes {
    payTypes: 'fixed' | 'fromAGValue' | 'both'
    frequencyTypes: 'full' | undefined
}

export const auditTypesDictionary = {
    10: { name: 'aType10', id: 10, label: 'Открытие ЛС', payTypes: 'fixed' },
    50: {
        name: 'aType50',
        id: 50,
        label: 'Обслуживание ЛС',
        payTypes: 'fixed',
        frequencyTypes: 'full',
    },
    14: { name: 'aType14', id: 14, label: 'Закрытие ЛС', payTypes: 'fixed' },
    37: { name: 'aType37', id: 37, label: 'Активация КГО', payTypes: 'fixed' },
    65: { name: 'aType65', id: 65, label: 'Добавление ГО', payTypes: 'fixed' },
    71: {
        name: 'aType71',
        id: 71,
        label: 'Подтверждение статуса ЗГО',
        payTypes: 'fixed',
        frequencyTypes: 'full',
    },
    45: { name: 'aType45', id: 45, label: 'Фиксация АГ', payTypes: 'fromAGValue' },
    18: { name: 'aType18', id: 18, label: 'Передача АГ по ЗД', payTypes: 'both' },
    67: {
        name: 'aType67',
        id: 67,
        label: 'Реализация атрибутов генерации по Зеленому договору',
        payTypes: 'both',
    },
    15: { name: 'aType15', id: 15, label: 'Выпуск сертификата происхождения', payTypes: 'both' },
    29: { name: 'aType29', id: 29, label: 'Передача сертификата происхождения', payTypes: 'both' },
    20: { name: 'aType20', id: 20, label: 'Деление сертификата происхождения', payTypes: 'both' },
    30: { name: 'aType30', id: 30, label: 'Погашение сертификата происхождения', payTypes: 'both' },
    85: {
        name: 'aType85',
        id: 85,
        label: 'Передача АГ третьему лицу при погашении СП',
        payTypes: 'both',
    },
    19: { name: 'aType19', id: 19, label: 'Удаление сертификата происхождения', payTypes: 'both' },
    32: { name: 'aType32', id: 32, label: 'Передача СП на ЭТП', payTypes: 'both' },
    66: { name: 'aType66', label: 'Собственное потребление АГ', payTypes: 'fromAGValue' },
    79: {
        name: 'aType79',
        label: 'Создание стороннего инструмента',
        payTypes: 'both',
    },
    80: {
        name: 'aType80',
        label: 'Погашение стороннего инструмента',
        payTypes: 'both',
    },
}

export const auditTypesArray = [
    { name: 'aType10', id: 10, label: 'Открытие ЛС', payTypes: 'fixed' },
    {
        name: 'aType50',
        id: 50,
        label: 'Обслуживание ЛС',
        payTypes: 'fixed',
        frequencyTypes: 'full',
    },
    { name: 'aType14', id: 14, label: 'Закрытие ЛС', payTypes: 'fixed' },
    { name: 'aType65', id: 65, label: 'Добавление ГО', payTypes: 'fixed', onlyOwner: true },
    { name: 'aType37', id: 37, label: 'Активация КГО', payTypes: 'fixed', onlyOwner: true },
    {
        name: 'aType71',
        id: 71,
        label: 'Подтверждение статуса ЗГО',
        payTypes: 'fixed',
        frequencyTypes: 'full',
        onlyOwner: true,
    },
    { name: 'aType45', id: 45, label: 'Фиксация АГ', payTypes: 'fromAGValue', onlyOwner: true },
    {
        name: 'aType66',
        id: 66,
        label: 'Собственное потребление АГ',
        payTypes: 'fromAGValue',
        onlyOwner: true,
    },
    { name: 'aType18', id: 18, label: 'Передача АГ по ЗД', payTypes: 'both', onlyOwner: true },
    {
        name: 'aType67',
        id: 67,
        label: 'Реализация АГ по ЗД',
        payTypes: 'both',
    },
    { name: 'aType15', id: 15, label: 'Выпуск СП', payTypes: 'both', onlyOwner: true },
    { name: 'aType29', id: 29, label: 'Передача СП', payTypes: 'both' },
    { name: 'aType20', id: 20, label: 'Деление СП', payTypes: 'both' },
    { name: 'aType30', id: 30, label: 'Погашение СП', payTypes: 'both' },
    {
        name: 'aType85',
        id: 85,
        label: 'Передача АГ третьему лицу при погашении СП',
        payTypes: 'both',
    },
    { name: 'aType19', id: 19, label: 'Удаление СП', payTypes: 'both', onlyOwner: true },
    { name: 'aType32', id: 32, label: 'Передача СП на ЭТП', payTypes: 'both' },
    {
        name: 'aType79',
        id: 79,
        label: 'Создание стороннего инструмента',
        payTypes: 'both',
        onlyOwner: true,
    },
    {
        name: 'aType80',
        id: 80,
        label: 'Погашение стороннего инструмента',
        payTypes: 'both',
        onlyOwner: true,
    },
]

export const payTypePicker = (typeId: number): string => {
    switch (typeId) {
        case 1:
            return 'Фиксированная'
        case 2:
            return 'От объема АГ'
        default:
            return 'Фиксированная'
    }
}

export const frequencyPicker = (typeId: number): string => {
    switch (typeId) {
        case 1:
            return 'Раз в месяц'
        case 2:
            return 'Раз в квартал'
        case 3:
            return 'Раз в полгода'
        case 4:
            return 'Раз в год'
        case 5:
            return 'Раз в 4 года'
        default:
            return 'Раз в год'
    }
}

export const payTypeIdPicker = (name: string): number => {
    switch (name) {
        case 'Фиксированная':
            return 1
        case 'От объема АГ':
            return 2
        default:
            return 1
    }
}

export const frequencyIdPicker = (name: string): number => {
    switch (name) {
        case 'Раз в месяц':
            return 1
        case 'Раз в квартал':
            return 2
        case 'Раз в полгода':
            return 3
        case 'Раз в год':
            return 4
        case 'Раз в 4 года':
            return 5
        default:
            return 4
    }
}

export const getTariffsDictionary = (tariffs: any) => {
    const dictionary = {}
    for (const item of tariffs) {
        const id = item.operation_type_id
        const value = auditTypesDictionary[id]
        if (value?.name) {
            dictionary[value.name] = item
        }
    }
    return dictionary
}

export const getInitialConfig = (tariffs: any) => {
    const config = {}
    for (const tariff of tariffs) {
        for (const item in tariff) {
            if (item === 'pay_type_id') {
                config[`aType${tariff.operation_type_id}-${item}`] = payTypePicker(tariff[item])
            } else if (item === 'frequency_id') {
                config[`aType${tariff.operation_type_id}-${item}`] = frequencyPicker(tariff[item])
            } else {
                config[`aType${tariff.operation_type_id}-${item}`] = tariff[item]
            }
        }
    }
    return config
}
