import { FC } from 'react'
import { Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Modal, PrimaryButton } from '../../../ui'

import style from './style.m.less'
import { Close } from '../../../../assets/icons'

interface IGenerationAttributesPayNotEnoughModal {
    isModalVisible: boolean
    onClose: () => void
}

const GenerationAttributesPayNotEnoughModal: FC<IGenerationAttributesPayNotEnoughModal> = ({
    isModalVisible,
    onClose,
}) => {
    const navigate = useNavigate()

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            <div className={style.modal}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>Недостаточно денежных средств</div>
                    <div className={style.closeIcon} onClick={onClose}>
                        <Close />
                    </div>
                </div>
                <div className={style.content}>
                    <p>
                        Операция не выполнена, так как на Вашем балансе недостаточно средств, чтобы
                        произвести оплату комиссии.
                    </p>
                    <Box direction="row" width={'100%'} margin="32px 0 0 0" justify="flex-end">
                        <PrimaryButton
                            onClick={onClose}
                            value={'Отмена'}
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={() =>
                                navigate(`/profile`, { state: { toPaymentAccount: true } })
                            }
                            value={'Перейти к платежному счету'}
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                        />
                    </Box>
                </div>
            </div>
        </Modal>
    )
}

export default GenerationAttributesPayNotEnoughModal
