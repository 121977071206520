import moment from 'moment'

const shortFormat = 'DD.MM.YYYY'
const extraFormat = 'D MMMM, dddd'
const dayUnit = 'day'

interface IEvent {
    date: string
    operations: any[]
}

export const getOperations = (events: IEvent[]) => {
    let recordsInSet = 5
    const today = moment().startOf(dayUnit)
    const yesterday = today.subtract(1, dayUnit)
    return events
        ?.map((operation) => {
            const eventDay = moment(operation.date, shortFormat)

            let title = eventDay.format(extraFormat)

            let operations = []

            const isToday = eventDay.isSame(today)
            const isYesterday = eventDay.isSame(yesterday)

            if (isToday) {
                title = `Сегодня, ${title}`
            }

            if (isYesterday) {
                title = `Вчера, ${title}`
            }

            if (recordsInSet && operation.operations?.length) {
                const delta = Math.min(operation.operations.length, recordsInSet)
                operations = operation.operations.slice(0, delta)
                recordsInSet -= delta
            }

            return { flexStart: false, iconMargin: false, date: title, operations: operations }
        })
        .filter((event) => event?.operations?.length > 0)
}
