import { FC, useState, useEffect } from 'react'
import { Input, Tooltip, Alert } from 'antd'
import { AddCircle, InfoCircle, Trash } from '../../../../assets/icons'
import { PrimaryIconButton, Box } from '../../../ui'
import style from './style.m.less'

interface IControlledInput {
    item: string
    index: number
    changeCode: (index: number, value: string) => void
    codesArray: any
    setCodesError: (value: boolean) => void
}

const ControlledInput: FC<IControlledInput> = ({
    item,
    index,
    changeCode,
    codesArray,
    setCodesError,
}) => {
    const [value, setValue] = useState(item)
    const [isInvalid, setIsInvalid] = useState(false)
    const [isInvalidSame, setIsInvalidSame] = useState(false)

    const validateField = (e: any) => {
        //(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+){8,} at least 1 number and 1 character
        const regexp = new RegExp(/^([a-zA-Z0-9]+){8,}$/)
        if (!regexp.test(e.target.value) && e.target.value !== '') {
            setIsInvalid(true)
        } else {
            setIsInvalid(false)
        }
        setValue(e.target.value.toUpperCase())
        setIsInvalidSame(codesArray.includes(e.target.value) && e.target.value.length > 0)
    }

    const isFirst = index === 0

    const changeCodeHandler = () => {
        if (isInvalid || isInvalidSame) {
            return
        }
        changeCode(index, value)
    }

    useEffect(() => {
        if (isInvalid || isInvalidSame) {
            setCodesError(true)
        } else if (!isInvalid && !isInvalidSame) {
            setCodesError(false)
        }
    }, [isInvalid, isInvalidSame])

    return (
        <div className={style.inputWrapper}>
            <Input
                placeholder="Введите значение"
                className={isFirst ? style.inputFirst : style.input}
                value={value}
                onChange={validateField}
                onBlur={changeCodeHandler}
                style={isInvalid ? { border: '1px solid #ff4d4f' } : undefined}
                minLength={8}
                maxLength={8}
            />
            {isInvalid && (
                <div className={style.explain}>
                    Код ГТП потребления должен состоять из 8 символов и содержать только латинские
                    буквы и цифры
                </div>
            )}
            {isInvalidSame && (
                <div className={style.explain}>Код ГТП потребления уже используется</div>
            )}
        </div>
    )
}

interface ICodesConsumingBlock {
    codesArray: string[]
    updateCodesArray: (code: string) => void
    removeFromCodesArray: (index: number) => void
    changeCode: (index: number, value: string) => void
    setCodesError: (value: boolean) => void
}

const CodesConsumingBlock: FC<ICodesConsumingBlock> = ({
    codesArray,
    updateCodesArray,
    removeFromCodesArray,
    changeCode,
    setCodesError,
}) => {
    return (
        <div>
            <Box direction="row" width="415" justify="space-between" key={codesArray[0] + 0}>
                <ControlledInput
                    codesArray={codesArray}
                    item={codesArray[0]}
                    index={0}
                    changeCode={changeCode}
                    setCodesError={setCodesError}
                />
                <Tooltip
                    placement="topLeft"
                    color="#434343"
                    overlayInnerStyle={{ color: 'white', width: '260px' }}
                    title="Код ГТП потребления необходим для возможности получения атрибутов генерации в рамках зеленых договоров"
                >
                    <div className={style.infoCircle}>
                        <InfoCircle />
                    </div>
                </Tooltip>
            </Box>
            {codesArray?.length > 1 &&
                codesArray.map((item, index) => {
                    if (index === 0 || index > 50) {
                        return null
                    }
                    return (
                        <Box direction="row" width="415" justify="space-between" key={item + index}>
                            <ControlledInput
                                codesArray={codesArray}
                                item={item}
                                index={index}
                                changeCode={changeCode}
                                setCodesError={setCodesError}
                            />
                            <PrimaryIconButton
                                isCancel
                                customStyle={{ marginLeft: '8px', marginTop: '16px' }}
                                icon={<Trash />}
                                onClick={() => removeFromCodesArray(index)}
                            />
                        </Box>
                    )
                })}
            {codesArray?.length < 50 && (
                <div className={style.addCode} onClick={() => updateCodesArray('')}>
                    <AddCircle />
                    <div>Добавить код ГТП потребления</div>
                </div>
            )}
            {codesArray?.length >= 50 && (
                <div className={style.maxWrapGTP}>
                    <Alert message={<div>Достигнуто максимальное количество кодов ГТП (50)</div>} />
                </div>
            )}
        </div>
    )
}

export default CodesConsumingBlock
