import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import useMount from '../../../hooks/useMount'

import style from './style.m.less'
import { FC, useEffect, useState } from 'react'
import { HistoryEvent, PaginatedEvents, PaymentTypes } from '../../types'
import { DatePicker, DropdownSelector2 } from '../../../../components/ui'
import { AGHistoryChanged } from '../../../../assets/icons/AGHistoryChanged'
import classNames from 'classnames'
import { useAppSelector } from '../../../../store/hooks'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

interface GenerationAttributesHistoryProps {
    generatorId?: string
}

type Filters = {
    type_event_ids: number[]
    event_date_from?: string
    event_date_to?: string
    delivery_period?: string
}

const GenerationAttributesHistory: FC<GenerationAttributesHistoryProps> = ({ generatorId }) => {
    const [filters, setFilters] = useState<Filters>({
        type_event_ids: [],
    })

    const navigate = useNavigate()

    const {
        data: { uid: user_uid },
    } = useAppSelector((store) => store.user)

    const {
        fetch: getHistoryEvents,
        isLoading: isLoadingHistoryEvents,
        result: historyEvents,
    } = useRequest<PaginatedEvents<HistoryEvent>>({
        link: '/api/audit/v1/event-history-ga',
        method: RequestTypes.post,
    })

    const { fetch: getPaymentTypes, result: paymentTypes } = useRequest<PaymentTypes[]>({
        link: '/api/audit/v1/dict/event-payment-types',
        method: RequestTypes.get,
    })

    useEffect(() => {
        const newFilters = { ...filters }
        if (!newFilters.event_date_to) {
            delete newFilters['event_date_to']
        }
        if (!newFilters.event_date_from) {
            delete newFilters['event_date_from']
        }
        if (!newFilters.delivery_period) {
            delete newFilters['delivery_period']
        }

        if (user_uid) {
            getHistoryEvents({
                body: {
                    owner_generator_id: user_uid,
                    generator_id: generatorId || '',
                    limit: 10,
                    offset: 0,
                    filters: {
                        ...newFilters,
                    },
                },
            })
        }
    }, [user_uid, generatorId, filters])

    useMount(() =>
        getPaymentTypes({
            onSuccess: (list: PaymentTypes[]) => {
                setFilters((prev) => ({ ...prev, type_event_ids: list?.map((item) => item.id) }))
            },
        }),
    )

    const { events } = historyEvents || {}

    const aggregatedEvents: Record<string, HistoryEvent[]> = {}
    events?.forEach((event) => {
        const date = event.ts_event.split('T')[0]
        aggregatedEvents[date] = [...(aggregatedEvents[date] || []), event]
    })

    return (
        <div className={style.historyWrapper}>
            <div className={style.historyWrapper__inner}>
                <div className={style.filters}>
                    <div className={style.leftGapBlock}>
                        <div>Тип операции</div>
                        <div className={style.inputBlock}>
                            <DropdownSelector2
                                allowClear
                                maxTagCount={1}
                                isMultiple={true}
                                customStyle={{ width: '100%' }}
                                value={filters.type_event_ids}
                                options={(paymentTypes || []).map((item) => ({
                                    text: item.name,
                                    label: item.name,
                                    value: item.id,
                                    id: item.id,
                                }))}
                                onChange={(type_event_ids: number[]) =>
                                    setFilters((prev) => ({ ...prev, type_event_ids }))
                                }
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>Дата операции</div>
                        <div className={style.inputBlock}>
                            <DatePicker
                                customStyle={{ width: '100%' }}
                                onChange={(value: string) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        event_date_from: value,
                                        event_date_to: value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className={style.historyRows}>
                    {Object.keys(aggregatedEvents)?.map((date) => {
                        const isToday = moment(date).isSame(moment())
                        const isYesterday = moment(date).isSame(moment().subtract(1, 'days'))
                        return (
                            <>
                                <div key={date} className={style.historyDate}>
                                    <div className={style.historyDate}>
                                        <span className={style.historyDate__first}>
                                            {isToday
                                                ? 'Сегодня, '
                                                : isYesterday
                                                ? 'Вчера, '
                                                : `${moment(date).format('DD MMMM')}, `}
                                        </span>{' '}
                                        <span className={style.historyDate__second}>
                                            {isToday || isYesterday
                                                ? `${moment(date).format('DD MMMM')}, `
                                                : `${moment(date).format('dddd')}`}
                                        </span>
                                    </div>
                                    <div className={style.historyDate__line} />
                                </div>
                                {aggregatedEvents[date]?.map(
                                    ({ event_id, type_event_id, volume, ts_event }) => (
                                        <div key={event_id} className={style.historyBlock}>
                                            <div className={style.historyBlock__icon}>
                                                <AGHistoryChanged />
                                            </div>
                                            <div className={style.historyBlock__text}>
                                                <div className={style.historyBlock__name}>
                                                    {
                                                        paymentTypes?.find(
                                                            (type) => type.id === type_event_id,
                                                        )?.name
                                                    }
                                                </div>
                                                <div className={style.historyBlock__time}>
                                                    {moment(ts_event).format('hh:mm')}
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(style.historyBlock__value, {
                                                    [style.historyBlock__value_green]: false,
                                                })}
                                            >
                                                {' '}
                                                {new Intl.NumberFormat('ru-RU', {
                                                    maximumFractionDigits: 2,
                                                }).format(volume)}{' '}
                                                кВт*ч
                                            </div>
                                        </div>
                                    ),
                                )}
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default GenerationAttributesHistory
