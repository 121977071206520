import { FC } from 'react'
import { Divider, Space, Tooltip } from 'antd'
import { PowerTypeTag, CardInfoBlock } from '../../../ui'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Flash, Calendar, Location, Lock, NotVerify } from '../../../../assets/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../store/hooks'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { EUserRole } from '../../../../constants/approval'
import { currencyFormat } from '../../../helpers'

export interface IGeneratingObject {
    id: string
    name: string
    ga_total: number
    power: number
    region_id: number
    qualification_date: string
    last_check_date: string
    renewable_energy_type_id: number
    blocks: {
        ga_fixation: boolean // Фиксация АГ
        gt_emission: boolean // Выпуск ЗИ
        ga_transfer: boolean // Передача АГ по ЗД
        ga_redemption: boolean // Собственное погашение АГ
    }
    ts_deregistered: string // дата, с которой ГО снимается/снято с Учета либо NULL если снятие не планируется или ГО Восстановлено в Учете
}

interface IGeneratorCard {
    generatorData: IGeneratingObject
}

const GeneratorCard: FC<IGeneratorCard> = ({ generatorData }) => {
    const {
        id,
        name,
        power,
        qualification_date,
        last_check_date,
        region_id,
        renewable_energy_type_id,
        ga_total,
        blocks,
        ts_deregistered,
    } = generatorData

    const { t } = useTranslation()
    const { renewableEnergyTypesMap, regionsMap } = useAppSelector(selectDictionariesMaps) ?? {}

    const energyType = renewableEnergyTypesMap?.get(renewable_energy_type_id)
    const region = regionsMap?.get(region_id)

    const userRole: EUserRole = useAppSelector((state) => state.user.data.user_role)

    const hasBlock = blocks && Object.values(blocks).filter((block) => block).length > 0

    return (
        <div className={style.cardMain}>
            <div>
                <Space>
                    {ts_deregistered && moment(ts_deregistered).isBefore(moment()) && (
                        <Tooltip
                            placement="top"
                            color="#434343"
                            overlayInnerStyle={{ color: 'white' }}
                            title="Снято с учета"
                        >
                            <div className={style.notVerify}>
                                <NotVerify />
                            </div>{' '}
                        </Tooltip>
                    )}
                    {hasBlock && (
                        <div className={style.block}>
                            <Lock />{' '}
                        </div>
                    )}
                    <Link to={`/generator-card/${id}`} className={style.cardHeader}>
                        {name}
                    </Link>
                </Space>
                {userRole === EUserRole.Administrator && (
                    <div className={style.gaTotal}>
                        {ga_total.toLocaleString()} {t('generatorCard.scale2')}
                    </div>
                )}
                <div className={style.infoRow}>
                    <CardInfoBlock
                        label={t('generatorCard.power')}
                        value={`${currencyFormat(power / 1000)} ${t('generatorCard.scale')}`}
                        icon={<Flash />}
                        gap={7}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label={t('generatorCard.qualifyDate')}
                        value={
                            qualification_date
                                ? moment(qualification_date).format('DD.MM.YYYY')
                                : '-'
                        }
                        icon={<Calendar />}
                        gap={6}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label={t('generatorCard.lastCheckDate')}
                        value={last_check_date ? moment(last_check_date).format('DD.MM.YYYY') : '-'}
                        icon={<Calendar />}
                        gap={6}
                    />
                </div>
                <div className={style.locationBlock}>
                    <CardInfoBlock value={`Россия, ${region}`} icon={<Location />} gap={11} />
                </div>
            </div>
            {energyType && <PowerTypeTag value={energyType}>{energyType}</PowerTypeTag>}
        </div>
    )
}

export default GeneratorCard
