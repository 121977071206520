import { FC, useState } from 'react'
import { ImportButton } from '../../../ui'
import { Note, Key, Lock } from '../../../../assets/icons'
import style from './style.m.less'
import { ReasonModal } from '../'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '../../../helpers'
import { Divider } from 'antd'
import { UnblockModalGenerationAttributes } from '../UnblockModalGenerationAttributes'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

interface IBlockedGenerationAttributes {
    value?: string
    reason?: string
    refreshTotalAG: () => void
}

const BlockedGenerationAttributes: FC<IBlockedGenerationAttributes> = ({
    value = '',
    reason,
    refreshTotalAG,
}) => {
    const { t } = useTranslation()

    const { isUserAdmin } = useCurrentUser()
    const [isUnblockingModalVisible, setIsBlockingModalVisible] = useState(false)
    const [isReasonModalVisible, setIsReasonModalVisible] = useState(false)

    const openReasonModal = (value: any) => {
        if (value) {
            setIsReasonModalVisible(true)
        }
    }

    const closeReasonModal = () => {
        setIsReasonModalVisible(false)
    }

    return (
        <div className={style.main}>
            <div className={style.header}>Заблокированные атрибуты генерации</div>
            <div className={style.freeGenerationAttributes}>
                <Lock />
                <span>
                    {currencyFormat(value) || 0} {t('detailedCard.scale')}
                </span>
            </div>
            <div className={style.buttonsBlock}>
                {isUserAdmin ? (
                    <>
                        <ImportButton
                            value="Причина блокировки"
                            isDisabled={!value}
                            onClick={() => openReasonModal(value)}
                            icon={<Note />}
                        />
                        <Divider type={'vertical'} className={style.divider} />
                        <ImportButton
                            onClick={() => setIsBlockingModalVisible(true)}
                            value="Разблокировать объем АГ"
                            icon={<Key />}
                        />
                    </>
                ) : (
                    <ImportButton
                        value="Подробнее"
                        onClick={() => openReasonModal(value)}
                        isDisabled={!value}
                        icon={<Note />}
                    />
                )}
            </div>

            <UnblockModalGenerationAttributes
                refreshTotalAG={refreshTotalAG}
                isModalVisible={isUnblockingModalVisible}
                setIsModalVisible={setIsBlockingModalVisible}
            />
            <ReasonModal
                reason={reason}
                isModalVisible={isReasonModalVisible}
                closeReasonModal={closeReasonModal}
            />
        </div>
    )
}

export default BlockedGenerationAttributes
