// 1000  to  1 000
export const currencyFormatInt = (intNum: any) =>
    (intNum + '')
        .split('')
        .reverse()
        .join('')
        .replace(/\d\d\d/g, '$& ')
        .split('')
        .reverse()
        .join('')

export const currencyFormat = (sum: string | number) => {
    // 1000.0001 to 1 000.0001
    if (sum === 0) {
        return sum
    } else if (!sum) {
        return ''
    }
    const [intPart, ...rest] = (sum + '').split('.') // 1000   0001
    const intPartFormat = currencyFormatInt(intPart) // 1 000
    const result = [intPartFormat, ...rest].join('.') // 1 000.0001
    return result
}

// TODO delete  удалить если в /owner/id в статистеке не нужно будет возращать градацию
export const hslToHex = (h: number, s: number, l: number) => {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = (n: number) => {
        const k = (n + h / 30) % 12
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0') // convert to Hex and prefix "0" if needed
    }
    return `#${f(0)}${f(8)}${f(4)}`
}

const getNumEnding = (words: string[]) => (count: number) => {
    const rem = Math.abs(count) % 100
    if (rem >= 11 && rem <= 19) {
        return words[2]
    }
    switch (rem % 10) {
        case 1:
            return words[0]
        case 2:
        case 3:
        case 4:
            return words[1]
        default:
            return words[2]
    }
}

export const consumersLabel = getNumEnding(['потребитель', 'потребителя', 'потребителей'])
