import { FC, useState } from 'react'
import { DropdownSelector2, ImportButton } from '../../../ui'
import { Divider, Alert } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { userTypeIdPicker } from '../../../helpers'
import { Search, Trash } from '../../../../assets/icons'
import { SearchConsumer } from '../'

const options = [
    { id: 'Физическое лицо', value: 'Физическое лицо' },
    { id: 'Юридическое лицо', value: 'Юридическое лицо' },
    { id: 'Индивидуальный предприниматель', value: 'Индивидуальный предприниматель' },
]

interface IConsumerBlock {
    updateRepayments: (id: string) => void
}

const ConsumerBlock: FC<IConsumerBlock> = ({ updateRepayments }) => {
    const { t } = useTranslation()
    const [type, setType] = useState(null)
    const [selected, setSelected] = useState<any>(undefined)

    const selectHandle = (consumer: any) => {
        setSelected(consumer)
        updateRepayments(consumer.uid)
    }

    const clearCard = () => {
        setSelected(undefined)
        updateRepayments('')
    }

    const [isSearchConsumerModalVisible, setIsSearchConsumerModalVisible] = useState(false)
    const openSearchConsumerModal = () => {
        setIsSearchConsumerModalVisible(true)
    }

    return (
        <div>
            {!selected && (
                <>
                    <div className={style.label}>Пользователь</div>
                    <DropdownSelector2
                        placeholder="Выберите пользователя"
                        noDefaultValue
                        options={options}
                        customStyle={{
                            width: '400px',
                        }}
                        onChange={(value: any) => {
                            updateRepayments('')
                            setType(value)
                        }}
                    />
                </>
            )}
            {type && !selected && (
                <div className={style.linkWrapper}>
                    <ImportButton
                        value="Найти в системе"
                        icon={<Search />}
                        onClick={openSearchConsumerModal}
                    />
                </div>
            )}
            {selected && type === 'Физическое лицо' && (
                <Alert
                    className={style.resultAlert}
                    message={
                        <div className={style.alertTitleWrapper}>
                            <span className={style.alertTitle}>
                                {`${selected.last_name} ${selected.first_name} ${selected.middle_name}`}
                            </span>
                            <div className={style.alertIconWrapper}>
                                {selected.is_blocked && (
                                    <div className={style.statusBlocked}>Заблокирован</div>
                                )}
                                <div className={style.icon} onClick={clearCard}>
                                    <Trash />
                                </div>
                            </div>
                        </div>
                    }
                    description={
                        <>
                            <span
                                className={style.alertDetail}
                            >{`ИНН ${selected.company_inn}`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`СНИЛС ${selected.snils}`}</span>
                        </>
                    }
                    type="info"
                />
            )}
            {selected && type === 'Юридическое лицо' && (
                <Alert
                    className={style.resultLegalAlert}
                    message={
                        <div className={style.alertTitleWrapper}>
                            <span className={style.alertTitle}>{selected.company_name_short}</span>
                            <div className={style.alertIconWrapper}>
                                {selected.is_blocked && (
                                    <div className={style.statusBlocked}>Заблокирован</div>
                                )}
                                <div className={style.icon} onClick={clearCard}>
                                    <Trash />
                                </div>
                            </div>
                        </div>
                    }
                    description={
                        <>
                            <span
                                className={style.alertDetail}
                            >{`ИНН ${selected.company_inn}`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`ОГРН ${selected.ogrn}`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`КПП ${selected.kpp}`}</span>
                        </>
                    }
                    type="info"
                />
            )}
            {selected && type === 'Индивидуальный предприниматель' && (
                <Alert
                    className={style.resultAlert}
                    message={
                        <div className={style.alertTitleWrapper}>
                            <span
                                className={style.alertTitle}
                            >{`ИП ${selected.last_name} ${selected.first_name} ${selected.middle_name}`}</span>
                            <div className={style.alertIconWrapper}>
                                {selected.is_blocked && (
                                    <div className={style.statusBlocked}>Заблокирован</div>
                                )}
                                <div className={style.icon} onClick={clearCard}>
                                    <Trash />
                                </div>
                            </div>
                        </div>
                    }
                    description={
                        <>
                            <span
                                className={style.alertDetail}
                            >{`ИНН ${selected.personal_inn}`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`ОГРН ${selected.ogrn}`}</span>
                        </>
                    }
                    type="info"
                />
            )}
            <SearchConsumer
                isModalVisible={isSearchConsumerModalVisible}
                setIsModalVisible={() => setIsSearchConsumerModalVisible(false)}
                userType={userTypeIdPicker(type)}
                selectHandle={selectHandle}
            />
        </div>
    )
}

export default ConsumerBlock
