import { FC, useMemo, useState } from 'react'
import { Import } from '../../../../../../assets/icons'
import { Input } from 'antd'
import moment, { Moment } from 'moment'
import {
    Box,
    DropdownSelector2,
    ImportButton,
    TimeRangePicker,
    DatePicker,
} from '../../../../../ui'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectDictionariesOptions } from '../../../../../../store/dictionaries'
import { getGeneratorsForAccount } from '../../../../config'
import useMount from '../../../../../hooks/useMount'
import { statusIdPicker } from '../../../../../helpers'
import useRequest from '../../../../../hooks/useRequest'
import MoreFilters from '../MoreFilters/MoreFilters'
import style from './style.m.less'

const depositOptions = [
    { id: 'a851024a-58ae-48dc-b085-178e9966f180', value: 'Все' },
    { id: '9bc31bb4-8ec3-4520-8a18-9eba6400dd57', value: 'В залоге' },
]

interface IControlsBlock {
    toolType: string
    onChange: (value: string) => void
    onChangeFilters: (data: string | number, key: string, obj?: any) => void
    currentOption: string
    isUserType?: boolean
    total?: number
    options: { label: string; id: string }[]
    isDisabled?: boolean
    isAdminType: boolean
}

const ControlsBlock: FC<IControlsBlock> = ({
    toolType,
    options,
    currentOption,
    total,
    onChange,
    onChangeFilters,
    isDisabled,
    isAdminType,
}) => {
    const { renewableEnergyTypesOptions } = useAppSelector(selectDictionariesOptions) ?? {}
    const { fetch: fetchGenerators, result: generatorsResult } = useRequest(getGeneratorsForAccount)
    const [accountValue, setAccountValue] = useState('')

    const isCertificate = toolType === 'Сертификаты происхождения'
    const isContract = toolType === 'Зеленые договоры'
    const isThirdParty = toolType === 'Сторонние инструменты'

    const realRenewableEnergyTypesOptions = useMemo(
        () =>
            renewableEnergyTypesOptions
                ? [
                      { id: 0, value: null, text: 'Все', label: 'Все' },
                      ...renewableEnergyTypesOptions,
                  ]
                : [{ id: 0, value: null, text: 'Все', label: 'Все' }],
        [renewableEnergyTypesOptions],
    )

    useMount(() => {
        fetchGenerators({
            errorCodeMessage: true,
        })
        onChangeFilters('', '', {
            status_id: statusIdPicker(currentOption),
        })
    })

    const updateFilters = (data: any, key: string) => {
        switch (key) {
            case 'rangeDate':
                return onChangeFilters('', '', {
                    ts_created_from: data ? moment(data[0]).startOf('day') : undefined,
                    ts_created_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            case 'date':
                return onChangeFilters('', '', {
                    month: data?.month,
                    year: data?.year,
                })
            default:
                return onChangeFilters(data, key)
        }
    }

    const onTimeRangePickerChange =
        ([from, to]: string[]) =>
        (dates: [Moment, Moment] | null) => {
            const isArray = Array.isArray(dates)
            onChangeFilters('', '', {
                [from]: isArray ? dates[0] : dates,
                [to]: isArray ? dates[1] : dates,
            })
        }

    return (
        <div className={style.controlsBlock}>
            <div className={style.inputsRow}>
                {isAdminType && !isContract && (
                    <div>
                        <div>Лицевой счет</div>
                        <div className={style.inputBlock}>
                            <Input
                                className={style.certificateNumberInput}
                                placeholder="Введите номер ЛС"
                                value={accountValue}
                                onChange={(event) => {
                                    setAccountValue(event.target.value)
                                    updateFilters(event.target.value, 'account_number')
                                    fetchGenerators({
                                        addToLink: event.target.value,
                                        errorCodeMessage: true,
                                    })
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className={style.leftGapBlock}>
                    <div>
                        Номер {isCertificate ? 'сертификата' : isContract ? 'ЗД' : 'инструмента'}
                    </div>
                    <div className={style.inputBlock}>
                        <Input
                            className={
                                isContract || (isThirdParty && !isAdminType)
                                    ? style.certificateNumberInputContracts
                                    : style.certificateNumberInput
                            }
                            placeholder={`Введите номер ${
                                isCertificate ? 'СП' : isContract ? 'договора' : 'инструмента'
                            }`}
                            onChange={(event) =>
                                updateFilters(
                                    event.target.value,
                                    isCertificate ? 'certificate_number' : 'contract_number',
                                )
                            }
                        />
                    </div>
                </div>
                {isContract ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '233px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата регистрации</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'registration_ts_from',
                                        'registration_ts_to',
                                    ])}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата создания в системе</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'created_ts_from',
                                        'created_ts_to',
                                    ])}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата начала поставки по ЗД</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'delivery_ts_from',
                                        'delivery_ts_to',
                                    ])}
                                />
                            </div>
                        </div>
                    </>
                ) : isCertificate ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Номинал сертификата, кВт*ч</div>
                            <Box direction="row" gap="8px">
                                <Input
                                    className={style.certificateNumberInputShort}
                                    placeholder="С"
                                    onChange={(event) =>
                                        updateFilters(Number(event.target.value), 'volume_from')
                                    }
                                />
                                <Input
                                    className={style.certificateNumberInputShort}
                                    placeholder="По"
                                    onChange={(event) =>
                                        updateFilters(Number(event.target.value), 'volume_to')
                                    }
                                />
                            </Box>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                />
                            </div>
                        </div>
                    </>
                ) : isAdminType ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>ЗГО</div>
                            <DropdownSelector2
                                customStyle={{ width: '296px' }}
                                options={generatorsResult?.generators?.map((item: any) => {
                                    return {
                                        text: item.name,
                                        value: item.id,
                                        id: item.id,
                                    }
                                })}
                                placeholder="Выберите ЗГО"
                                onChange={(value) => updateFilters(value, 'generator_id')}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '233px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>ЗГО</div>
                            <DropdownSelector2
                                customStyle={{ width: '233px', marginTop: '2px' }}
                                options={generatorsResult?.generators?.map((item: any) => {
                                    return {
                                        text: item.name,
                                        value: item.id,
                                        id: item.id,
                                    }
                                })}
                                placeholder="Выберите ЗГО"
                                onChange={(value) => updateFilters(value, 'generator_id')}
                            />
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>Период производства</div>
                            <DatePicker
                                className={style.select}
                                onChange={(value) => {
                                    updateFilters(
                                        {
                                            month: moment(value).month() + 1,
                                            year: moment(value).year(),
                                        },
                                        'date',
                                    )
                                }}
                            />
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата создания</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'created_ts_from',
                                        'created_ts_to',
                                    ])}
                                />
                            </div>
                        </div>
                    </>
                )}
                {!isAdminType && isCertificate && (
                    <div className={style.leftGapBlock}>
                        <div className={style.label}>Залог</div>
                        <DropdownSelector2
                            customStyle={{ width: '296px' }}
                            options={depositOptions}
                            onChange={(value) => {
                                const returnedValue = value === 'Все' ? null : true
                                updateFilters(returnedValue, 'is_deposit')
                            }}
                        />
                    </div>
                )}
            </div>
            {(isCertificate || (isThirdParty && isAdminType)) && (
                <MoreFilters
                    toolType={toolType}
                    updateFilters={updateFilters}
                    generators={generatorsResult?.generators}
                    isAdminType={isAdminType}
                    onTimeRangePickerChange={onTimeRangePickerChange}
                />
            )}

            <div className={style.selectorWrapper}>
                <div className={style.selector}>
                    {options?.map((item) => {
                        const { label, id } = item
                        return (
                            <Box direction="row" key={id}>
                                <div
                                    className={
                                        currentOption === label
                                            ? style.greenToolSelected
                                            : style.greenTool
                                    }
                                    onClick={() => onChange(label)}
                                >
                                    {label}
                                </div>
                                <div className={style.spaceBar}></div>
                            </Box>
                        )
                    })}
                    <div className={style.total}>{`Всего: ${total}`}</div>
                </div>
            </div>
        </div>
    )
}

export default ControlsBlock
