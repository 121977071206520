import { FC, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, ImportButton, CenteredSpin, IFile } from '../ui'
import { Box } from '../ui/Box/Box'
import { useSelector } from 'react-redux'
import { ButtonsBlock } from '../Edit/components'
import { Divider } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { sendRepayment, sendWithFile } from './config'
import { ArrowLeft, AddCircle } from '../../assets/icons'
import { currencyFormat, signBody } from '../helpers'
import DocumentsBlock from '../AdminUploading/components/DocumentsBlock/DocumentsBlock'
import { ConsumerBlock } from './components'
import { useDebouncedRequest, useGetTariffRate } from '../hooks'
import IAppState from 'store/states'
import { useCurrentUser } from '../hooks/useCurrentUser'

const addingOptions = [
    { label: 'Вручную', id: '8q7f77bf-a3ec-4441-9b13-e46eabc3c027' },
    { label: 'Из файла', id: 'jr324494-8d32-423a-a7d8-b038ece4a70b' },
]

const CertificateRepayment: FC = () => {
    const { t } = useTranslation()
    const { state } = useLocation()
    const { volume, certificateId } = state
    const navigate = useNavigate()
    const [option, setOption] = useState(addingOptions[0].label)
    const [total, setTotal] = useState(0)
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    const { isUserAdmin, uid } = useCurrentUser()

    const isManual = option === 'Вручную'
    const [files, setFiles] = useState<IFile[]>([])

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const [selectedRepayments, setSelectedRepayments] = useState<any>([{ user_id: '', volume: 0 }])
    const [unregistredRepayments, setUnregistredRepayments] = useState<any[]>([
        { unreg_consumer_data: {}, volume: 0 },
    ])
    const stateRef = useRef()
    const stateUnregistredRef = useRef()
    stateRef.current = selectedRepayments
    stateUnregistredRef.current = unregistredRepayments

    const updateSelectedRepayments = (index: number, key: string, value: number | string) => {
        const arrayCopy = !stateRef?.current?.[index]
            ? [...stateRef.current, { user_id: '', volume: 0 }]
            : [...stateRef.current]
        arrayCopy[index][key] = value
        setSelectedRepayments(arrayCopy)
    }

    const updateUnregistredRepayments = (index: number, key: string, value: number | string) => {
        const arrayCopy = !stateUnregistredRef?.current?.[index]
            ? [...stateUnregistredRef.current, { volume: 0, unreg_consumer_data: {} }]
            : [...stateUnregistredRef.current]
        arrayCopy[index][key] = value
        setUnregistredRepayments(arrayCopy)
    }

    const { fetch: fetchOtherTarifs, result } = useRequest({
        link: '/api/billing/v1/tariff-rate',
        method: RequestTypes.post,
    })

    const noOwnerList = selectedRepayments.filter(
        (el: { user_id: string; volume: number }) => el.user_id !== uid && el.user_id.length,
    )

    const isOther = noOwnerList.length > 0

    const fetchOterTarrifs = (volume: number) => {
        fetchOtherTarifs({
            body: {
                operation_type_id: 85,
                volume,
            },
            showError: false,
        })
    }

    const debouncedSendVolume = useDebouncedRequest(fetchOterTarrifs, 500)

    useEffect(() => {
        const res = selectedRepayments.reduce((sum, current) => sum + current.volume, 0)
        setTotal(res)
        const otherVolume = noOwnerList.reduce((sum, current) => sum + current.volume, 0)
        if (!(otherVolume > 0)) {
            return
        }
        debouncedSendVolume(otherVolume)
    }, [selectedRepayments])

    const [componentsArray, setComponentsArray] = useState<any>([
        <ConsumerBlock
            totalVolume={volume}
            updateRepayments={updateSelectedRepayments}
            updateUnregistredRepayments={updateUnregistredRepayments}
            index={0}
        />,
    ])

    const incrementComponentsArray = () => {
        setComponentsArray([
            ...componentsArray,
            <ConsumerBlock
                totalVolume={volume}
                updateRepayments={updateSelectedRepayments}
                index={componentsArray.length}
                updateUnregistredRepayments={updateUnregistredRepayments}
            />,
        ])
    }

    const { fetch } = useRequest(sendRepayment)
    const { fetch: fetchWithFile } = useRequest(sendWithFile)

    const onChange = (value: string) => {
        setOption(value)
    }

    const getBack = () => {
        navigate(-1)
    }

    const confirmRepayment = async () => {
        const body = isManual
            ? {
                  certificate_id: certificateId,
                  repayments: selectedRepayments.filter((data) => !!data.user_id),
                  unreg_repayments: unregistredRepayments.filter(
                      (data) => Object.keys(data.unreg_consumer_data).length > 0,
                  ),
              }
            : {
                  certificate_id: certificateId,
                  document: { id: files[0].id, hash: files[0].hash },
              }

        const { request_id, signature } = await signBody(body, data)

        if (isManual) {
            fetch({
                body,
                getParams: {
                    request_id,
                },
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Сертификат успешно погашен',
                },
                onSuccess: () => navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                errorCodeMessage: true,
            })
        } else {
            fetchWithFile({
                body,
                getParams: {
                    request_id,
                },
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Сертификат успешно погашен',
                },
                onSuccess: () => navigate(-1),
                errorCodeMessage: true,
            })
        }
    }

    const { isLoading, rate } = useGetTariffRate({
        id: 30,
        volume,
        showError: false,
    })

    const confirmDisableCondition =
        (isManual && !(volume === total)) || (!isManual && !files.length)

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>Погашение сертификата</div>
            </div>

            <Card className={style.repaymentCard}>
                <div className={style.title}>Как Вы хотите добавить потребителей ЭЭ?</div>
                <Box direction="row" gap={'16px'}>
                    {addingOptions?.map((item) => {
                        const { label, id } = item
                        return (
                            <>
                                <div
                                    className={
                                        option === label ? style.greenToolSelected : style.greenTool
                                    }
                                    key={id}
                                    onClick={() => onChange(label)}
                                >
                                    {label}
                                </div>
                            </>
                        )
                    })}
                </Box>
                {isManual ? (
                    <>
                        {componentsArray}
                        <div className={style.buttonsBlock}>
                            <ImportButton
                                value="Добавить потребителя"
                                icon={<AddCircle />}
                                onClick={incrementComponentsArray}
                            />
                        </div>
                    </>
                ) : (
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={4}
                        description="Загрузите файл, из которого хотите импортировать данные (формат - xml, не более 50 мб)"
                        format="xml"
                        customStyle={{ width: '440px', marginTop: '24px' }}
                    />
                )}
                <Divider />
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Объем</div>
                    <div className={style.repaymentItemValue}>{`${volume} кВт*ч`}</div>
                </div>
                {rate !== '0 ₽' && (
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>Оплата за погашение СП</div>
                        <div className={style.repaymentItemValue}>{rate}</div>
                    </div>
                )}
                {isOther && result !== '0 ₽' && (
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>
                            Оплата за передачу АГ третьим лицам
                        </div>
                        <div className={style.repaymentItemValue}>{`${currencyFormat(
                            result?.rate,
                        )} ₽`}</div>
                    </div>
                )}
                <Divider />
                <ButtonsBlock
                    confirmTitle={'Подписать и погасить'}
                    cancelAction={getBack}
                    confirmAction={confirmRepayment}
                    confirmDisabled={confirmDisableCondition}
                />
            </Card>
        </div>
    )
}

export default CertificateRepayment
