import { FC } from 'react'
import { Alert, Box, Modal, PrimaryButton } from '../../../ui'

import style from './style.m.less'
import { Close } from '../../../../assets/icons'
import moment from 'moment'
import useMount from '../../../hooks/useMount'
import useRequest from '../../../hooks/useRequest'
import { balanceConfig, paymentConfig } from './config'
import { message } from 'antd'
import { Balance } from 'src/api/audit/types'

interface IGenerationAttributesPayModal {
    isModalVisible: boolean
    fee?: number
    generatorId?: string
    totalVolume: number
    gasId?: number
    period?: string
    goName?: string
    onClose: () => void
    onOpenSubModal: () => void
    onOk: () => void
}

const GenerationAttributesPayModal: FC<IGenerationAttributesPayModal> = ({
    isModalVisible,
    fee = 0,
    goName,
    generatorId,
    gasId,
    period,
    totalVolume,
    onOpenSubModal,
    onClose,
    onOk,
}) => {
    const { fetch: fetchBalance, result: balance } = useRequest<Balance>(balanceConfig)
    const { fetch: makePayment } = useRequest(paymentConfig)

    useMount(() => {
        fetchBalance({})
    })

    const onMakePayment = () => {
        if (Number(balance?.available_balance) < fee) {
            onOpenSubModal()
            return
        }

        makePayment({
            body: {
                generator_id: generatorId,
                gas_id: gasId,
                period: period,
                volume: totalVolume,
                fee: fee,
            },
            onSuccess: () => {
                message.success('Оплата успешно прошла')
                onOk()
                onClose()
            },
            onError: () => {
                message.error('Ошибка оплаты')
            },
        })
    }

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            <div className={style.modal}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>Оплата комиссии</div>
                    <div className={style.closeIcon} onClick={onClose}>
                        <Close />
                    </div>
                </div>
                <div className={style.content}>
                    <div className={style.metrics}>
                        <div className={style.metrics__row}>
                            <div>ЗГО</div>
                            <div>{goName}</div>
                        </div>
                        <div className={style.metrics__row}>
                            <div>Период поставки</div>
                            <div>{moment(period).format('MMMM YYYY')}</div>
                        </div>
                        <div className={style.metrics__row}>
                            <div>Объем АГ</div>
                            <div>{totalVolume} кВт*ч</div>
                        </div>
                    </div>
                    <Alert
                        className={style.payAlert}
                        showIcon={false}
                        message={
                            <>
                                <div className={style.payAlert__header}>
                                    Сумма комиссии составила {fee} ₽
                                </div>
                                <div className={style.payAlert__bottom}>
                                    Баланс платежного счета: {balance?.available_balance} ₽
                                </div>
                            </>
                        }
                        type="info"
                    />
                    <Box direction="row" width={'100%'} margin="32px 0 0 0" justify="flex-end">
                        <PrimaryButton
                            onClick={onClose}
                            value={'Отмена'}
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={onMakePayment}
                            value={'Оплатить'}
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                        />
                    </Box>
                </div>
            </div>
        </Modal>
    )
}

export default GenerationAttributesPayModal
