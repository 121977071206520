import { FC, ReactNode } from 'react'
import { Popover } from 'antd'
import { Lock } from '../../../assets/icons'

import style from './style.m.less'

interface ILockBadge {
    popoverContent?: ReactNode
}

export const LockBadge: FC<ILockBadge> = ({ popoverContent }) =>
    popoverContent ? (
        <Popover content={popoverContent} placement="topLeft" color="#434343">
            <div
                className={`${style.badgeWrapper} ${
                    popoverContent ? style.badgeWrapperHovered : ''
                }`}
            >
                <Lock />
            </div>
        </Popover>
    ) : (
        <div className={`${style.badgeWrapper} ${popoverContent ? style.badgeWrapperHovered : ''}`}>
            <Lock />
        </div>
    )
