import { FC, useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, ImportButton, CenteredSpin, IFile } from '../ui'
import { Box } from '../ui/Box/Box'
import { useSelector } from 'react-redux'
import { ButtonsBlock } from '../Edit/components'
import { Divider, message } from 'antd'
import DocumentsBlock from '../AdminUploading/components/DocumentsBlock/DocumentsBlock'
import GenerationAttributesPayNotEnoughModal from '../GenerationAttributes/components/GenerationAttributesPayNotEnoughModal/GenerationAttributesPayNotEnoughModal'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import { sendRealization, getFromFile } from './config'
import { ArrowLeft, AddCircle } from '../../assets/icons'
import { signBody, currencyFormat } from '../helpers'
import { ConsumerBlock, PaymentModal } from './components'
import { useGetTariffRate } from '../hooks'
import IAppState from '../../store/states'

const addingOptions = [
    { label: 'Вручную', id: '8q7f77bf-a3ec-4441-9b13-e46eabc3c027' },
    { label: 'Из файла', id: 'jr324494-8d32-423a-a7d8-b038ece4a70b' },
]

interface IRealizationAG {
    isWithPayment?: boolean
}

const RealizationAG: FC<IRealizationAG> = ({ isWithPayment }) => {
    const { t } = useTranslation()
    const { state } = useLocation()
    const { contract_id, contract_number, period_date, fee, volume } = state
    const navigate = useNavigate()
    const [option, setOption] = useState(addingOptions[0].label)
    const [total, setTotal] = useState(0)
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
    const [isPayNotEnoughModalVisible, setIsPayNotEnoughModalVisible] = useState<boolean>(false)
    const [files, setFiles] = useState<IFile[]>([])

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const isManual = option === 'Вручную'

    const { fetch: fetchConsumers } = useRequest(getFromFile)

    useEffect(() => {
        if (files.length) {
            const body = {
                document_id: files[0].id,
                contract_number,
            }
            fetchConsumers({
                body,
                onSuccess: (data) => {
                    setTotal(data?.consumed_volume)
                    setSelectedRepayments(data?.consumers)
                },
                errorCodeMessage: true,
            })
        }
    }, [files])

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    const [selectedRepayments, setSelectedRepayments] = useState<any>([{ user_id: '', volume: 0 }])
    const stateRef = useRef()
    stateRef.current = selectedRepayments

    const updateSelectedRepayments = (index: number, key: string, value: number) => {
        const arrayCopy = !stateRef?.current?.[index]
            ? [...stateRef.current, { user_id: '', volume: 0 }]
            : [...stateRef.current]
        arrayCopy[index][key] = value
        if (key === 'consumer_data') {
            delete arrayCopy[index]['user_id']
        }
        setSelectedRepayments(arrayCopy)
    }

    useEffect(() => {
        const res = selectedRepayments.reduce((sum, current) => sum + current.volume, 0)
        setTotal(res)
    }, [selectedRepayments])

    const [componentsArray, setComponentsArray] = useState<any>([
        <ConsumerBlock
            totalVolume={volume}
            updateRepayments={updateSelectedRepayments}
            index={0}
        />,
    ])

    const incrementComponentsArray = () => {
        setComponentsArray([
            ...componentsArray,
            <ConsumerBlock
                totalVolume={volume}
                updateRepayments={updateSelectedRepayments}
                index={componentsArray.length}
            />,
        ])
    }

    const { fetch } = useRequest(sendRealization)

    const onChange = (value: string) => {
        setOption(value)
    }

    const getBack = () => {
        navigate(-1)
    }

    const confirmRepayment = async () => {
        if (isWithPayment) {
            setIsPaymentModalVisible(true)
            return
        }
        const body = {
            contract_number,
            period_id: contract_id,
            period_date,
            consumers: selectedRepayments,
        }

        if (isManual) {
            const { request_id, signature } = await signBody(body, data)

            fetch({
                body,
                getParams: {
                    request_id,
                },
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Реализация АГ по ЗД прошла успешно',
                },
                onSuccess: () => navigate(-1),
                errorCodeMessage: true,
            })
        }
    }

    const { loadingTariffs, rate } = useGetTariffRate({
        id: 67,
        volume,
    })

    if (loadingTariffs) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>Реализация атрибутов генерации</div>
            </div>

            <Card className={style.repaymentCard}>
                <div className={style.title}>Как Вы хотите добавить потребителей?</div>
                <Box direction="row" gap={'16px'}>
                    {addingOptions.map((item) => {
                        const { label, id } = item
                        return (
                            <>
                                <div
                                    className={
                                        option === label ? style.greenToolSelected : style.greenTool
                                    }
                                    key={id}
                                    onClick={() => onChange(label)}
                                >
                                    {label}
                                </div>
                            </>
                        )
                    })}
                </Box>
                {isManual ? (
                    <>
                        {componentsArray}
                        <ImportButton
                            value="Добавить потребителя"
                            icon={<AddCircle />}
                            onClick={incrementComponentsArray}
                        />
                    </>
                ) : (
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={4}
                        description="Загрузите файл, из которого хотите импортировать данные"
                        customStyle={{ width: '440px', marginTop: '24px' }}
                    />
                )}
                <Divider />
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Общий объем</div>
                    <div className={style.repaymentItemValue}>{`${currencyFormat(
                        volume,
                    )} кВт*ч`}</div>
                </div>
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Реализуемый объем</div>
                    <div className={style.repaymentItemValue}>
                        {!total ? '-' : `${currencyFormat(total)} кВт*ч`}
                    </div>
                </div>
                {isWithPayment && (
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>Сумма к оплате</div>
                        <div className={style.repaymentItemValue}>{`${currencyFormat(fee)} ₽`}</div>
                    </div>
                )}
                <Divider />
                <ButtonsBlock
                    confirmTitle={'Подписать и реализовать'}
                    confirmDisabled={!(volume === total)}
                    cancelAction={getBack}
                    confirmAction={confirmRepayment}
                />
            </Card>
            <PaymentModal
                isModalVisible={isPaymentModalVisible}
                data={state}
                consumers={selectedRepayments}
                onOpenSubModal={() => {
                    setIsPaymentModalVisible(false)
                    setIsPayNotEnoughModalVisible(true)
                }}
                onCancel={() => {
                    setIsPaymentModalVisible(false)
                    navigate(-1)
                }}
            />
            <GenerationAttributesPayNotEnoughModal
                isModalVisible={isPayNotEnoughModalVisible}
                onClose={() => setIsPayNotEnoughModalVisible(false)}
            />
        </div>
    )
}

export default RealizationAG
