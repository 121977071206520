import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import style from './style.m.less'
import { useEffect, useState } from 'react'
import { PaymentStatuses } from '../../types'
import { Alert, BackArrow, LoadingContainer } from '../../../../components/ui'
import PageContainer from '../../../../components/ui/PageElements/PageContainer/PageContainer'
import PageTitle from '../../../../components/ui/PageElements/PageTitle/PageTitle'
import { useParams } from 'react-router-dom'
import { AGGOCard } from '../../types'
import cx from 'classnames'
import { Button } from 'antd'
import GenerationAttributesPayModal from '../GenerationAttributesPayModal/GenerationAttributesPayModal'
import GenerationAttributesPayNotEnoughModal from '../GenerationAttributesPayNotEnoughModal/GenerationAttributesPayNotEnoughModal'
import moment from 'moment'
import GenerationAttributesHistory from '../GenerationAttributesHistory/GenerationAttributesHistory'

const GenerationAttributesGOCard = () => {
    const { id } = useParams()
    const [isPayModalVisible, setIsPayModalVisible] = useState<boolean>(false)
    const [isPayNotEnoughModalVisible, setIsPayNotEnoughModalVisible] = useState<boolean>(false)
    const {
        fetch: getGO,
        isLoading: isLoadingGetGO,
        result: GOResult,
    } = useRequest<AGGOCard>({
        link: '/api/generation_attributes/v1/gas/ga/get',
        method: RequestTypes.get,
    })

    const goName = GOResult?.go_name

    const {
        free_volume = 0,
        green_tools_volume = 0,
        total_volume = 0,
        locked_volume = 0,
        fee,
        period,
        id: gasId,
        generator_id,
        status,
    } = GOResult || {}

    const freeVolumeProgressWidth = 100 / (total_volume / free_volume)
    const greenToolsVolumeProgressWidth = 100 / (total_volume / green_tools_volume)
    const lockedVolumeProgressWidth = 100 / (total_volume / locked_volume)

    useEffect(() => {
        if (id) {
            getGO({
                getParams: {
                    id,
                },
            })
        }
    }, [id])

    return (
        <LoadingContainer isLoading={isLoadingGetGO}>
            <PageContainer>
                <PageTitle
                    title={
                        <div className={style.title}>
                            <BackArrow link="/generation-attributes" />
                            <div>{`${goName}, ${moment(period).format('MMMM YYYY')}`}</div>
                        </div>
                    }
                />
                <div className={style.statsContainer}>
                    <div className={style.statsBlock}>
                        <div className={style.statsBlock__stat}>
                            <div className={style.statsBlock__name}>АГ в составе ЗИ</div>
                            <div className={style.statsBlock__metrics}>
                                {GOResult?.green_tools_volume} кВт*ч
                            </div>
                            {!!greenToolsVolumeProgressWidth && (
                                <div
                                    className={cx(
                                        style.statsBlock__progress,
                                        style.statsBlock__progress_green,
                                    )}
                                    style={{ width: greenToolsVolumeProgressWidth + '%' }}
                                />
                            )}
                        </div>
                        <div className={style.statsBlock__stat}>
                            <div className={style.statsBlock__name}>Свободные АГ</div>
                            <div className={style.statsBlock__metrics}>
                                {GOResult?.free_volume} кВт*ч
                            </div>
                            {!!freeVolumeProgressWidth && (
                                <div
                                    className={cx(
                                        style.statsBlock__progress,
                                        style.statsBlock__progress_blue,
                                    )}
                                    style={{ width: freeVolumeProgressWidth + '%' }}
                                />
                            )}
                        </div>
                        <div className={style.statsBlock__stat}>
                            <div className={style.statsBlock__name}>
                                Заблокированные/неоплаченные АГ
                            </div>
                            <div className={style.statsBlock__metrics}>
                                {GOResult?.locked_volume} кВт*ч
                            </div>
                            {!!lockedVolumeProgressWidth && (
                                <div
                                    className={cx(
                                        style.statsBlock__progress,
                                        style.statsBlock__progress_red,
                                    )}
                                    style={{ width: lockedVolumeProgressWidth + '%' }}
                                />
                            )}
                        </div>
                    </div>
                    <div className={style.totalBlock}>
                        <div>
                            <div className={style.totalBlock__header}>
                                Всего атрибутов генерации
                            </div>
                            <div className={style.totalBlock__value}>
                                {GOResult?.total_volume}{' '}
                                <span className={style.totalBlock__metrics}>кВт*ч</span>
                            </div>
                        </div>
                    </div>
                    {status === PaymentStatuses.NotPaid && (
                        <div className={style.payBlock}>
                            <div className={style.payBlock__alert}>
                                <Alert
                                    message={
                                        <>
                                            <div className={style['payBlock__alert-message']}>
                                                Для того, чтобы использовать атрибуты генерации за{' '}
                                                {moment(period).format('MMMM YYYY')}, необходимо
                                                внести оплату в размере {fee} ₽
                                            </div>
                                            <Button onClick={() => setIsPayModalVisible(true)}>
                                                Оплатить
                                            </Button>
                                        </>
                                    }
                                    type="warning"
                                    showIcon
                                />
                            </div>
                        </div>
                    )}
                    {status === PaymentStatuses.Paid && (
                        <div className={style.historyWrapper}>
                            <GenerationAttributesHistory generatorId={generator_id} />
                        </div>
                    )}
                </div>
                <GenerationAttributesPayModal
                    isModalVisible={isPayModalVisible}
                    onClose={() => setIsPayModalVisible(false)}
                    fee={fee}
                    generatorId={generator_id}
                    gasId={gasId}
                    totalVolume={total_volume}
                    period={period}
                    goName={goName}
                    onOpenSubModal={() => setIsPayNotEnoughModalVisible(true)}
                    onOk={() => {
                        setIsPayModalVisible(false)
                        getGO({
                            getParams: {
                                id,
                            },
                        })
                    }}
                />
                <GenerationAttributesPayNotEnoughModal
                    isModalVisible={isPayNotEnoughModalVisible}
                    onClose={() => setIsPayNotEnoughModalVisible(false)}
                />
            </PageContainer>
        </LoadingContainer>
    )
}

export default GenerationAttributesGOCard
