import moment from 'moment'
import { IGeneratingObject } from '../../store/generators/IGeneratingObject'
import { EUserType } from '../../constants/approval'

export const MainInfoConfig = (generatorInfo: IGeneratingObject, dictionaries) => {
    const {
        productionModesMap,
        regionsMap,
        ownershipsMap,
        aidsMap,
        fuelMap,
        fullRenewableEnergyTypesMap,
        fullGenCodeOptions,
    } = dictionaries

    const { user_type, company_name_short, last_name, first_name, middle_name, uid } =
        generatorInfo.go_owner

    const goOwnerName =
        user_type === EUserType.UrLico
            ? company_name_short
            : user_type === EUserType.IP
            ? `ИП ${last_name} ${first_name} ${middle_name}`
            : `${last_name} ${first_name} ${middle_name}`

    return {
        visibleInfo: [
            {
                name: 'Рынок ЭЭ',
                value: generatorInfo.is_for_retail
                    ? 'Розничный рынок электроэнергии'
                    : 'Оптовый рынок электроэнергии',
            },
            {
                name: 'Тип ГО',
                value: `${
                    generatorInfo.go_type_id === 1
                        ? 'Квалифицированный генерирующий объект'
                        : 'Зарегистрированный генерирующий объект'
                }`,
            },
            {
                name: 'Уникальный код ГО',
                value: generatorInfo.generator_pub_id,
                isTag: true,
            },
            {
                name: 'Код ГТП генерации',
                value: generatorInfo.point_delivery_code,
                isTag: true,
            },
            {
                name: 'Код ГТП потребления',
                value: generatorInfo.point_delivery_code_consuming,
                isTag: true,
            },
            {
                name: 'Код ГО',
                value: generatorInfo.go_code,
                isTag: true,
            },
            {
                name: 'ТСО',
                value: generatorInfo.tso?.company_name_short,
            },
            {
                name: 'ГП',
                value: generatorInfo.gp?.company_name_short,
            },
            {
                name: 'Местонахождение ГО',
                value: [
                    'Российская Федерация',
                    regionsMap.get(generatorInfo.region_id),
                    generatorInfo.address_index,
                    generatorInfo.city,
                    generatorInfo.street,
                    generatorInfo.house,
                    generatorInfo.housing,
                    generatorInfo.building,
                    generatorInfo.address_info,
                ]
                    .filter(Boolean)
                    .join(', '),
            },
            {
                name: 'Координаты (широта)',
                value: generatorInfo.geo_lat,
            },
            {
                name: 'Координаты (долгота)',
                value: generatorInfo.geo_lon,
            },
            {
                name: 'Признак ГО',
                value: fullGenCodeOptions.get(generatorInfo.renewable_energy_type_id),
            },
            {
                name: 'Показатель степени локализации',
                value: generatorInfo.localization_degree
                    ? generatorInfo.localization_degree + '%'
                    : undefined,
            },
            {
                name: 'Квалификационная категория ГО (вид источника энергии)',
                value: fullRenewableEnergyTypesMap.get(generatorInfo.renewable_energy_type_id),
            },
            {
                name: 'Типы топлива',
                value: generatorInfo.fuel_ids?.map((fuel_id) => fuelMap.get(fuel_id)).join('; '),
            },
            {
                name: 'Установленная мощность',
                value: (generatorInfo.power / 1000).toLocaleString() + ' МВт',
            },
            {
                name: 'Режим производства',
                value: productionModesMap.get(generatorInfo.production_mode_id),
            },
            {
                name: 'Основания владения',
                value: ownershipsMap.get(generatorInfo.ownership_id),
            },
            {
                name: 'Владелец ГО',
                value: goOwnerName,
                href: `/owner/${uid}`,
            },
            {
                name: 'Программа поддержки',
                value: aidsMap.get(generatorInfo.aid_id),
            },
        ],
        hiddenInfo: [
            {
                name: '№ квалификационного свидетельства',
                value: generatorInfo.qualification_number,
                noMargin: true,
            },
            {
                name: 'Дата квалификации',
                value: generatorInfo.qualification_date
                    ? moment(generatorInfo.qualification_date).format('DD.MM.YYYY')
                    : undefined,
            },
            {
                name: 'Отметка о выдаче дубликата квалификационного свидетельства',
                value: generatorInfo.qualification_point,
            },
            {
                name: 'Дата последней проверки',
                value: generatorInfo.last_check_date
                    ? moment(generatorInfo.last_check_date).format('DD.MM.YYYY')
                    : undefined,
            },
        ],
    }
}

export const ownerInfoConfig = {
    visibleInfo: [
        {
            name: 'Полное наименование',
            value: 'Общество с ограниченной ответственностью «Зета Солар»',
        },
        {
            name: 'Юридический адрес',
            value: '295017, Республика Крым, г. Симферополь, ул. Рубцова, д. 44А, офис 5',
        },
        {
            name: 'Почтовый адрес',
            value: '295017, Республика Крым, г. Симферополь, ул. Рубцова, д. 44А, офис 5',
        },
        {
            name: 'Телефон, факс',
            value: '8(3652)-714-010',
        },
        {
            name: 'E-mail, сайт',
            value: 'v.zolotarev@powerservices.ru',
        },
        {
            name: 'ИНН',
            value: '9102064730',
        },
    ],
    hiddenInfo: [
        {
            name: 'Сведения о должностном лице НП "Совет рынка", внесшим запись в реестр',
            value: 'А.В. Чечуров, начальник Департамента аудита и сертификации электрических станций Управления аудита и технологической экспертизы',
            noMargin: true,
        },
        {
            name: 'Основание для внесения записи в реестр',
            value: 'Решение Наблюдательного Совета Ассоциации "НП Совет рынка" о внесении записи в Реестр квалифицированных генерирующих объектов от 22.06.2017 (протокол № 14/2017 от 22 июня 2017 года)',
        },
    ],
}

export const detailedOwnerInfoConfig = {
    visibleInfo: [
        {
            name: 'Полное наименование',
            value: 'Общество с ограниченной ответственностью «Зета Солар»',
        },
        {
            name: 'Юридический адрес',
            value: '295017, Республика Крым, г. Симферополь, ул. Рубцова, д. 44А, офис 5',
        },
        {
            name: 'Почтовый адрес',
            value: '295017, Республика Крым, г. Симферополь, ул. Рубцова, д. 44А, офис 5',
        },
        {
            name: 'Телефон, факс',
            value: '8(3652)-714-010',
        },
        {
            name: 'E-mail, сайт',
            value: 'v.zolotarev@powerservices.ru',
        },
        {
            name: 'Руководитель',
            value: 'Генеральный директор Семеновская Ольга Сергеевна',
        },
        {
            name: 'Наименование гос. органа, осуществившего регистрацию ЮЛ в ЕГРЮЛ',
            value: 'Инспекция Федеральной налоговой службы по г. Симферополю',
        },
        {
            name: 'ОГРН',
            value: '1149102172581',
        },
        {
            name: 'Дата регистрации ЮЛ в ЕГРЮЛ',
            value: '24.12.2014',
        },
        {
            name: 'ИНН',
            value: '9102064730',
        },
        {
            isDivider: true,
            noMargin: true,
        },
        ...ownerInfoConfig.hiddenInfo,
    ],
}
