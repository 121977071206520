import { useParams } from 'react-router-dom'
import { Tabs } from 'antd'
import { BookSaved } from '../../assets/icons'
import { getUserName } from '../../utils/formatter'
import PageTitle from '../ui/PageElements/PageTitle/PageTitle'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import { BackArrow, IconButtonSmall, LoadingContainer, LockBadge } from '../ui'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { GeneralInfo, PaymentAccount, PersonalAccount } from '../Profile/components'
import { getTabs } from './helpers'

import style from './style.m.less'
import { useEffect, useState } from 'react'
import { isUserAdmin } from '../../constants/approval'

const { TabPane } = Tabs

type TabName = 'Профиль' | 'Платежный счет' | 'Лицевой счет'
// | 'ЗИ пользователя' | 'ГО пользователя'

const UserCard = () => {
    const { id } = useParams()
    const [currentTab, setCurrentTab] = useState<TabName>('Профиль')

    const { fetch, result, errorMessage, isLoading } = useRequest({
        link: `/api/users/v1/profile-admin?uid=${id}`,
        method: RequestTypes.get,
    })

    const { fetch: fetchBalances, result: balances } = useRequest({
        link: `/api/billing/v1/account-balances`,
        method: RequestTypes.get,
    })

    useMount(() => {
        fetch({})
    })

    useEffect(() => {
        if (result && result?.profile_status !== 0 && !isUserAdmin(result?.user_role)) {
            fetchBalances({
                getParams: {
                    uid: id,
                },
            })
        }
    }, [result])

    const updateUser = () => {
        fetch({})
    }

    const onTabClick = (tab: string) => setCurrentTab(tab as TabName)

    const tabs = getTabs(result?.user_role)

    const tabsContent: Record<TabName, JSX.Element> = {
        Профиль: (
            <GeneralInfo
                user={{
                    data: result,
                    isLoading,
                    error: errorMessage,
                    actualData: result,
                }}
                updateUser={updateUser}
            />
        ),
        'Платежный счет': <PaymentAccount userId={id} userBalances={balances} />,
        'Лицевой счет': <PersonalAccount userId={id} />,
        // 'ЗИ пользователя': <>ЗИ пользователя</>,
        // 'ГО пользователя': <UserGOs />,
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <PageContainer>
                <PageTitle
                    title={
                        <div className={style.title}>
                            <BackArrow />
                            {getUserName(result)}
                            {result?.is_blocked && <LockBadge />}
                        </div>
                    }
                    extra={
                        currentTab === 'Профиль' && (
                            <IconButtonSmall
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<BookSaved />}
                            >
                                История изменений
                            </IconButtonSmall>
                        )
                    }
                />
                <Tabs defaultActiveKey="0" onTabClick={onTabClick} className={style.tabs}>
                    {tabs?.map((tab) => (
                        <TabPane key={tab} tab={tab}>
                            {tabsContent[tab]}
                        </TabPane>
                    ))}
                </Tabs>
            </PageContainer>
        </LoadingContainer>
    )
}

export default UserCard
