import { FC, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import debounce from 'lodash/debounce'
import FileSaver from 'file-saver'
import { Spin, Tabs } from 'antd'
import { AllOperationsDictConfig } from '../../../../services/dictionaries'
import { EHistoryType } from '../../../ui/OperationHistoryMapper/types'
import useMount from '../../../hooks/useMount'
import { EOperationTypeID, requestConfig } from './configurations'
import OperationFilters from './OperationFilters'
import { Import } from '../../../../assets/icons'
import useRequest from '../../../hooks/useRequest'

import {
    CenteredSpin,
    HeaderBlock,
    IconButton,
    OperationHistoryMapper,
    Pagination,
} from '../../../ui'
import style from './style.m.less'
import { Paginated } from 'src/api/common/types'
import { HistoryPaymentAccEvent, HistoryPaymentAccEventOperation } from 'src/api/audit/types'

interface ILoadPaymentHistory {
    page: number
    pageSize: number
    eventDateFilter: [moment.Moment, moment.Moment] | null
    typeEventIDsFilter: number[]
    amountMinFilter: number | null
    amountMaxFilter: number | null
    operationTypeIDFilter: EOperationTypeID | undefined
}

const { TabPane } = Tabs

const paidOnly = false
const PAGE_SIZE = 10
const debounceWaitMs = 300

interface IAllOperations {
    paymentAccoundId?: string
}

interface IEventOption {
    id: number
    text: string
}

const pendingOperationsTypesIds = [60, 58, 72, 83]

const AllOperations: FC<IAllOperations> = ({ paymentAccoundId }) => {
    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const { t } = useTranslation()

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [eventDateFilter, setEventDateFilter] = useState<[moment.Moment, moment.Moment] | null>(
        null,
    )
    const [typeEventIDsFilter, setTypeEventIDsFilter] = useState<number[]>([])
    const [amountMinFilter, setAmountMinFilter] = useState(0)
    const [amountMaxFilter, setAmountMaxFilter] = useState(0)
    const [operationTypeIDFilter, setOperationTypeIDFilter] = useState<
        EOperationTypeID | undefined
    >(EOperationTypeID.OpTypeAll)

    const {
        fetch: fetchPaymentHistory,
        result: historyResult,
        isLoading: isLoadingHistory,
    } = useRequest<Paginated<HistoryPaymentAccEvent, 'events'>>(requestConfig)

    const { fetch: fetchFile, isLoading: isLoadingFile } = useRequest(requestConfig)
    const { fetch: fetchDictionaries, result: dictionariesResult } =
        useRequest(AllOperationsDictConfig)

    const typeEvents = useMemo(() => {
        if (!dictionariesResult) {
            return {
                options: [],
                dictionary: {},
            }
        }

        const eventOptions = dictionariesResult?.map((item: { id: string; name: string }) => {
            return {
                id: item.id,
                value: item.id,
                text: item.name,
            }
        })

        return {
            dictionary:
                eventOptions?.reduce((acc: IEventOption[], cur: IEventOption) => ({
                    ...acc,
                    [cur.id]: cur.text,
                })) ?? {},
            options: [...eventOptions],
        }
    }, [dictionariesResult])

    useMount(() => {
        fetchDictionaries({})
    })

    const today = moment().startOf(dayUnit)

    const loadPaymentHistory = ({
        page,
        pageSize,
        eventDateFilter,
        typeEventIDsFilter,
        amountMinFilter,
        amountMaxFilter,
        operationTypeIDFilter,
    }: ILoadPaymentHistory) => {
        fetchPaymentHistory({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    event_date_from: eventDateFilter
                        ? moment(eventDateFilter[0]).startOf(dayUnit)
                        : null,
                    event_date_to: eventDateFilter
                        ? moment(eventDateFilter[1]).endOf(dayUnit)
                        : null,
                    type_event_ids: typeEventIDsFilter.length > 0 ? typeEventIDsFilter : null,
                    amount_min: amountMinFilter,
                    amount_max: amountMaxFilter,
                    operation_type_id: operationTypeIDFilter,
                    paid_only: paidOnly,
                },
            },
            getParams: paymentAccoundId && { id: paymentAccoundId },
        })
    }

    const [doneOperationsHistory, pendingOperationsHistory] = useMemo(() => {
        if (!historyResult?.total) {
            return [[], []]
        }

        const doneOperationsHistory: any[] = []
        const pendingOperationsHistory: any[] = []

        historyResult.events.forEach((event) => {
            const eventDay = moment(String(event.date), shortFormat)

            let title = eventDay.format(extraFormat)

            if (eventDay.isSame(today)) {
                title = `${t('history.today')}, ${title}`
            } else if (eventDay.isSame(today.subtract(1, dayUnit))) {
                title = `${t('history.yesterday')}, ${title}`
            }

            const doneOperations: HistoryPaymentAccEventOperation[] = []
            const pendingOperations: HistoryPaymentAccEventOperation[] = []
            event.operations.forEach((operation) => {
                if (pendingOperationsTypesIds.includes(operation.id)) {
                    pendingOperations.push(operation)
                } else {
                    doneOperations.push(operation)
                }
            })

            if (doneOperations.length) {
                doneOperationsHistory.push({
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: doneOperations,
                })
            }

            if (pendingOperations.length) {
                pendingOperationsHistory.push({
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: pendingOperations,
                })
            }
        })

        return [doneOperationsHistory, pendingOperationsHistory]
    }, [historyResult])

    const loadHistoryDebounced = debounce(loadPaymentHistory, debounceWaitMs)

    useEffect(() => {
        loadHistoryDebounced({
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            amountMinFilter,
            amountMaxFilter,
            operationTypeIDFilter,
        })
    }, [
        page,
        pageSize,
        eventDateFilter,
        typeEventIDsFilter,
        amountMinFilter,
        amountMaxFilter,
        operationTypeIDFilter,
    ])

    const tabsList = [
        {
            label: 'Исполненные операции',
            key: 1,
            content: (
                <div className={style.editFrame}>
                    <OperationFilters
                        settings={{ historyType: EHistoryType.Payment }}
                        typeEventIDsOptions={typeEvents.options}
                        typeEventIDsFilter={typeEventIDsFilter}
                        onChangeTypeEventIDs={(values) => {
                            setTypeEventIDsFilter(values)
                            setOperationTypeIDFilter(undefined)
                        }}
                        onChangeAmountMin={(value) => {
                            setPage(1)
                            setAmountMinFilter(value)
                        }}
                        onChangeAmountMax={(value) => {
                            setPage(1)
                            setAmountMaxFilter(value)
                        }}
                        onChangeOperationTypeID={(value) => {
                            setPage(1)
                            setOperationTypeIDFilter(value)
                        }}
                        onChangeDate={(range) => {
                            setPage(1)
                            setEventDateFilter(range)
                        }}
                    />

                    <OperationHistoryMapper
                        settings={{ historyType: EHistoryType.Payment }}
                        config={doneOperationsHistory}
                    />

                    <Pagination
                        showSizeChanger
                        customStyle={{ padding: 0, marginTop: '32px', boxShadow: 'none' }}
                        current={page}
                        paginationChange={(receivedPage: number, receivedPageSize?: number) => {
                            setPage(receivedPage)
                            setPageSize(receivedPageSize ?? PAGE_SIZE)
                        }}
                        pageSize={pageSize}
                        total={historyResult?.total}
                    />
                </div>
            ),
        },
        {
            label: 'Ожидающие исполнения',
            key: 2,
            content: (
                <div className={style.editFrame}>
                    <OperationHistoryMapper
                        settings={{ historyType: EHistoryType.Payment }}
                        config={pendingOperationsHistory}
                    />

                    <Pagination
                        showSizeChanger
                        customStyle={{ padding: 0, marginTop: '32px', boxShadow: 'none' }}
                        current={page}
                        paginationChange={(receivedPage: number, receivedPageSize?: number) => {
                            setPage(receivedPage)
                            setPageSize(receivedPageSize ?? PAGE_SIZE)
                        }}
                        pageSize={pageSize}
                        total={historyResult?.total}
                    />
                </div>
            ),
        },
    ]

    const handleDownload = () => {
        const body = {
            filters: {
                event_date_from: eventDateFilter
                    ? moment(eventDateFilter[0]).startOf(dayUnit)
                    : null,
                event_date_to: eventDateFilter ? moment(eventDateFilter[1]).endOf(dayUnit) : null,
                type_event_ids: typeEventIDsFilter.length > 0 ? typeEventIDsFilter : null,
                amount_min: amountMinFilter,
                amount_max: amountMaxFilter,
                operation_type_id: operationTypeIDFilter,
                paid_only: paidOnly,
            },
        }
        fetchFile({
            body,
            getParams: paymentAccoundId && { id: paymentAccoundId },
            shortParams: ['download'],
            onSuccess: (data) => {
                const fileName = `Истории операций платежного счета ${paymentAccoundId || ''}.csv`
                const linkSource = `data:text/csv;charset=utf-8,${data}`

                FileSaver.saveAs(linkSource, fileName)
            },
        })
    }

    return (
        <div className={style.editMain}>
            <div className={style.headerWrapper}>
                <HeaderBlock label={'История операций'} />
                <Spin spinning={isLoadingFile}>
                    <IconButton
                        icon={<Import />}
                        customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                        onClick={handleDownload}
                    >
                        Выгрузить данные
                    </IconButton>
                </Spin>
            </div>
            {isLoadingHistory && <CenteredSpin />}
            <Tabs destroyInactiveTabPane defaultActiveKey="1" className={style.tabs}>
                {tabsList.map(({ label, key, content }) => (
                    <TabPane tab={label} key={key}>
                        {content}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    )
}

export default AllOperations
