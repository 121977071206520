interface IData {
    key: string
    id: string
    date: Date
    eventId: number
    eventType: string
    creatorRole: string
    creatorName: string
    recipientName: string
    recipientRole: string
}

//добавляем переход только для уже добавленных типов событий
export const isAddedType = (typeId: number) =>
    [
        1, 2, 3, 65, 35, 37, 10, 50, 14, 15, 29, 30, 20, 21, 23, 70, 56, 74, 22, 24, 58, 59, 72, 73,
        83, 84, 79, 80, 81, 82, 66, 67, 18, 45, 41, 32, 46, 85,
    ].includes(typeId)

export type { IData }
