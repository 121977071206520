export const errorHandler = (errCode?: string, errAttributes?: any): string => {
    switch (errCode) {
        case 'ERR_REG_1':
            return 'Такой логин уже используется другим пользователем'
        case 'ERR_REG_2':
            return 'Длина логина должна быть от 5 до 15 символов'
        case 'ERR_REG_3':
            return 'Логин может содержать латинские буквы, цифры и подчеркивания'
        case 'ERR_REG_4':
            return 'Введите корректный адрес электронной почты'
        case 'ERR_REG_6':
            return 'Укажите фамилию, используя буквы русского или латинского алфавита'
        case 'ERR_REG_7':
            return 'Укажите имя, используя буквы русского или латинского алфавита'
        case 'ERR_REG_8':
            return 'Укажите отчество, используя буквы русского или латинского алфавита'
        case 'ERR_REG_9':
            return 'Укажите полное название организации (до 100 символов)'
        case 'ERR_REG_10':
            return 'Укажите ИНН: • 10 цифр для юридических лиц; • 12 цифр для ИП'
        case 'ERR_REG_11':
            return 'Укажите ОГРН (ОГРНИП): • 13 цифр для юридических лиц; • 15 цифр для ИП'
        case 'ERR_REG_12':
            return 'Укажите должность (до 50 символов)'
        case 'ERR_REG_13':
            return 'Некорректное значение. Допускаются использование цифр от 0 до 9, символы пробел, плюс, запятая, дефис, скобки; длина поля должна быть от 10 до 50 символов'
        case 'ERR_REG_14':
            return 'Указанный вами e-mail уже зарегистрирован в АС “СКИЗИ“, используйте другой е-mail'

        case 'ERR_PSWCR_1':
            return 'Введите корректный пароль'
        case 'ERR_PSWCH_4':
            return 'Старый пароль указан неверно. Повторите попытку'
        case 'ERR_PSWRV_1':
            return 'Введите корректный адрес электронной почты'
        case 'ERR_PSWRV_2':
            return 'Пользователь с такими логином и адресом электронной почты не найден в Системе. Повторите попытку'
        case 'ERR_NOTIFIER_1':
            return 'Дублируется ID уведомления.'

        case 'ERR_DOCS_1':
            return 'Документ не найден.'
        case 'ERR_DOCS_2':
            return 'В запросе отсутствует файл.'
        case 'ERR_DOCS_3':
            return 'Сервис не существует.'
        case 'ERR_DOCS_4':
            return 'Валидация подписи по сертификату не пройдена.'
        case 'ERR_DOCS_5':
            return 'Валидация подписи хэшей файлов не пройдена.'
        case 'ERR_DOCS_6':
            return 'Сертификат не валидный.'
        case 'ERR_DOCS_7':
            return 'Отказано в доступе к файлу.'
        case 'ERR_DOCS_8':
            return 'Неверный формат файла.'
        case 'ERR_DOCS_9':
            return 'Подпись файла не валидна.'

        case 'ERR_AUT_1':
            return 'Логин или пароль указаны неверно. Повторите попытку'
        case 'ERR_AUT_2':
            return 'Ваша учетная запись заблокирована.'
        case 'ERR_AUT_3':
            return 'Некорректная ЭП. Повторите попытку'
        case 'ERR_AUT_4':
            return 'УКЭП не добавлен'
        case 'ERR_AUT_5':
            return 'Некорректный токен'
        case 'ERR_AUT_6':
            return 'Невалидный сертификат'

        case 'ERR_ED_1':
            return 'Выберите тип пользователя'
        case 'ERR_ED_2':
            return 'Укажите фамилию, используя буквы русского или латинского алфавита'
        case 'ERR_ED_3':
            return 'Укажите имя, используя буквы русского или латинского алфавита'
        case 'ERR_ED_4':
            return 'Укажите отчество, используя буквы русского или латинского алфавита'
        case 'ERR_ED_6':
            return 'Укажите полное название организации (до 100 символов)'
        case 'ERR_ED_7':
            return 'Укажите ИНН: • 10 цифр для юридических лиц; • 12 цифр для ИП'
        case 'ERR_ED_8':
            return 'Укажите ОГРН (ОГРНИП): • 13 цифр для юридических лиц; • 15 цифр для ИП'
        case 'ERR_ED_9':
            return 'Укажите должность (до 50 символов)'
        case 'ERR_ED_10':
            return 'Некорректное значение. Допускаются использование цифр от 0 до 9, символы пробел, плюс, запятая, дефис, скобки; длина поля должна быть от 10 до 50 символов'

        case 'ERR_APL_1':
            return 'Выберите роль в системе'
        case 'ERR_APL_2':
            return 'Выберите тип пользователя'
        case 'ERR_APL_3':
            return 'Укажите фамилию, используя буквы русского или английского алфавита'
        case 'ERR_APL_4':
            return 'Укажите имя, используя буквы русского или английского алфавита'
        case 'ERR_APL_5':
            return 'Укажите отчество, используя буквы русского или английского алфавита'
        case 'ERR_APL_6':
            return 'Укажите полное название организации (до 100 символов)'
        case 'ERR_APL_7':
            return 'Укажите сокращенное название организации (до 30 символов)'
        case 'ERR_APL_8':
            return 'Укажите должность (до 50 символов)'
        case 'ERR_APL_9':
            return 'Укажите ИНН (10 цифр)'
        case 'ERR_APL_10':
            return 'Укажите КПП (9 цифр)'
        case 'ERR_APL_11':
            return 'Укажите ОГРН (13 цифр)'
        case 'ERR_APL_12':
            return 'Укажите наименование гос. органа, осуществившего регистрацию в ЕГРЮЛ (до 100 символов)'
        case 'ERR_APL_13':
            return 'Укажите корректную дату в формате “ДД.ММ.ГГГГ”'
        case 'ERR_APL_14':
            return 'Вы используете неразрешенные символы (допускаются: 0-9, пробел, плюс, запятая, дефис, скобки)'
        case 'ERR_APL_15':
            return 'Укажите юридический адрес организации (до 150 символов)'
        case 'ERR_APL_16':
            return 'Укажите почтовый адрес организации (до 150 символов)'
        case 'ERR_APL_17':
            return 'Укажите гражданство'
        case 'ERR_APL_18':
            return 'Выберите документ, удостоверяющий личность'
        case 'ERR_APL_19':
            return 'Укажите серию паспорта (4 цифры)'
        case 'ERR_APL_20':
            return 'Укажите номер паспорта (6 цифр)'
        case 'ERR_APL_21':
            return 'Укажите код подразделения (6 цифр)'
        case 'ERR_APL_22':
            return 'Укажите орган, выдавший паспорт'
        case 'ERR_APL_23':
            return 'Укажите почтовый адрес (до 150 символов)'
        case 'ERR_APL_24':
            return 'Укажите ИНН (12 цифр)'
        case 'ERR_APL_25':
            return 'Укажите СНИЛС (11 цифр)'
        case 'ERR_APL_26':
            return 'Укажите ОГРНИП (15 цифр)'
        case 'ERR_APL_27':
            return 'Укажите наименование органа, осуществившего регистрацию'
        case 'ERR_APL_29':
            return 'В АС “СКИЗИ“ уже зарегистрирован пользователь с таким ОГРН'
        case 'ERR_APL_30':
            return 'Укажите ИНН организации: • 10 цифр для юридических лиц; • 12 цифр для ИП'
        case 'ERR_APL_31':
            return 'Укажите адрес регистрации (до 150 символов)'
        case 'ERR_APL_32':
            return 'Выберите статус гражданина'
        case 'ERR_APL_33':
            return 'Укажите наименование документа, удостоверяющего личность'
        case 'ERR_APL_34':
            return 'Укажите номер документа, удостоверяющего личность'
        case 'ERR_APL_35':
            return 'Добавьте электронную подпись'
        case 'ERR_APL_36':
            return 'Для подписания анкеты необходимо выбрать сертификат ЭП, указанный в Вашем профиле'
        case 'ERR_APL_37':
            return 'Укажите ОГРН (13 цифр) или ОГРНИП (15 цифр)'
        case 'ERR_APL_38':
            return 'В АС “СКИЗИ” уже зарегистрирован пользователь с такими номером и серией паспорта'
        case 'ERR_APL_39':
            return 'В АС “СКИЗИ” уже зарегистрирован пользователь с таким ИНН'
        case 'ERR_APL_40':
            return 'В АС “СКИЗИ” уже зарегистрирован пользователь с таким СНИЛС'
        case 'ERR_APL_41':
            return 'В АС “СКИЗИ” уже зарегистрирован пользователь с таким ОГРНИП'
        case 'ERR_APL_42':
            return 'Электронная подпись невалидна'
        case 'ERR_APL_43':
            return 'Укажите роль административного пользователя в Системе'
        case 'ERR_APL_44':
            return 'Укажите наименование документа, подтверждающего полномочия административного пользователя'
        case 'ERR_APL_45':
            return 'Укажите номер документа, подтверждающего полномочия административного пользователя'
        case 'ERR_APL_46':
            return 'Укажите наименование органа, выдавшего документ, подтверждающий полномочия административного пользователя'
        case 'ERR_APL_47':
            return 'В АС “СКИЗИ“ уже используется ЭП с таким сертификатом'
        case 'ERR_APL_48':
            return 'Недопустимый формат файла. Загрузите файл с расширением *.jpg, *.jpeg, *.doc, *docx, *.png, *.pdf'
        case 'ERR_APL_49':
            return 'Количество загружаемых документов не должно превышать 10'

        case 'ERR_ADM_1':
            return 'Введите корректный адрес электронной почты'
        case 'ERR_ADM_2':
            return 'Укажите основание для блокировки'
        case 'ERR_ADM_3':
            return 'Укажите условия снятия блокировки'
        case 'ERR_ADM_4':
            return 'Пользователем не выполнены условия для смены роли'
        case 'ERR_ADM_5':
            return 'Неверный тип файла'
        case 'ERR_ADM_6':
            return 'Превышен размер файла'
        case 'ERR_ADM_7':
            return 'Невозможно согласовать анкету, т.к. отправивший её пользователь заблокирован'

        case 'ERR_GT_1':
            return 'Ошибка валидации подписи.'
        case 'ERR_GT_2':
            return 'Счет с таким номером не зарезервирован.'
        case 'ERR_GT_3':
            return 'Счет не найден.'
        case 'ERR_GT_4':
            return 'ГО не найден.'
        case 'ERR_GT_5':
            return 'Отсутствуют свободные АГ.'
        case 'ERR_GT_6':
            return 'Счет не принадлежит пользователю.'
        case 'ERR_GT_7':
            return 'Сертификат не найден.'
        case 'ERR_GT_8':
            return 'Нет прав для операций над сертификатом.'
        case 'ERR_GT_9':
            return 'Гашение возможно только в полном объеме.'
        case 'ERR_GT_10':
            return 'Не подходящий статус сертификата.'
        case 'ERR_GT_11':
            return 'Договор с таким номером существует в системе.'
        case 'ERR_GT_12':
            return 'Договор не найден в системе'
        case 'ERR_GT_13':
            return 'Пользователь не может подавать данные по чужому договору'
        case 'ERR_GT_14':
            return 'Потребление АГ превышает объем указанный по договору'
        case 'ERR_GT_15':
            return 'Счет закрыт.'
        case 'ERR_GT_16':
            return 'На вашем платежном счете недостаточно денежных средств для выполнения данной операции. Пожалуйста, пополните платежный счет и повторите попытку'
        case 'ERR_GT_17':
            return 'Счет не оплачен'
        case 'ERR_GT_18':
            return 'Сумма дочерних сертификатов должна составлять объем делимого сертификата.'
        case 'ERR_GT_19':
            return 'Разделить сертификат можно только 5 раз.'
        case 'ERR_GT_20':
            return 'Истек срок действия СП'
        case 'ERR_GT_21':
            return 'Файл не соответствует структуре'
        case 'ERR_GT_22':
            return 'Не заполнено поле “Номер ЗИ“'
        case 'ERR_GT_23':
            return 'Вы ввели больше 100 символов'
        case 'ERR_GT_24':
            return 'Не заполнено поле “ИНН- СНИЛС или ОГРН“'
        case 'ERR_GT_25':
            return 'Вы ввели больше 20 символов'
        case 'ERR_GT_26':
            return 'Номер ЗИ не соответсвует пользователю'
        case 'ERR_GT_27':
            return 'Неправильный тип ЗИ'
        case 'ERR_GT_28':
            return 'Даты файла "begin-date", "month" и "year" не соответствует текущему периоду'
        case 'ERR_GT_29':
            return 'Вы не можете оплатить комиссию т.к. у вас нет оплаченного Лицевого счета'
        case 'ERR_GT_30':
            return 'Вы не можете указать потребителей т.к. у вас нет оплаченного Лицевого счета'
        case 'ERR_GT_31':
            return 'Файл слишком большой (> 50МБ)'
        case 'ERR_GT_32':
            return 'Нет свободного объёма АГ на указанный период'
        case 'ERR_GT_33':
            return 'Неверный номер лицевого счета'
        case 'ERR_GT_34':
            return 'Неверно указан Генерирующий объект'
        case 'ERR_GT_35':
            return 'Неверно указана дата создания'
        case 'ERR_GT_36':
            return 'Неверно указана дата срока действия'
        case 'ERR_GT_37':
            return 'Сторонний инструмент уже заведен в систему'

        case 'ER-FIN-001':
            return 'Укажите пользователя'
        case 'ER-FIN-002':
            return 'Укажите сумму пополнения'
        case 'ER-FIN-003':
            return 'Опишите основание для пополнения платежного счета пользователя'

        case 'ER-PAYSET-001':
            return 'Укажите фиксированную стоимость услуги'
        case 'ER-PAYSET-002':
            return 'Укажите стоимость услуги за 1 ед. АГ'
        case 'ER-PAYSET-003':
            return 'Максимальная сумма меньше суммы за 1 ед. АГ'

        case 'ER-PAY-001':
            return 'На вашем платежном счете недостаточно денежных средств для выполнения данной операции. Пожалуйста, пополните платежный счет и повторите попытку'
        case 'ER-PAY-002':
            return 'На платежном счете пользователя недостаточно денежных средств для выполнения данной операции'

        case 'ERR_SYS_0':
            return 'Внутренняя ошибка'
        case 'ERR_SYS_1':
            return 'Bad request'
        case 'ERR_SYS_2':
            return 'Forbidden'

        case 'ERR_CONTENT_1':
            return 'Новость не найдена'
        case 'ERR_CONTENT_2':
            return 'Дата меньше текущей даты'
        case 'ERR_CONTENT_3':
            return 'Текстовое поле длиной больше 100 символов'
        case 'ERR_CONTENT_4':
            return 'Текстовое поле длиной больше 1000 символов'
        case 'ERR_CONTENT_5':
            return 'Документ не найден'
        case 'ERR_CONTENT_6':
            return 'Существует вопрос с таким номером'
        case 'ERR_CONTENT_7':
            return 'Превышено количество документов или их общий объем'
        case 'ERR_CONTENT_8':
            return 'Вопрос не найден'

        case 'ERR_GA_1':
            return 'Запись не найдена'
        case 'ERR_GA_2':
            return 'Документ не найден'
        case 'ERR_GA_3':
            return 'Объём не указан'
        case 'ERR_GA_4':
            return 'Причина не указана'
        case 'ERR_GA_5':
            return 'Документ слишком большой'
        case 'ERR_GA_6':
            return 'Период не указан'
        case 'ERR_GA_7':
            return 'ГО не указан'
        case 'ERR_GA_8':
            return 'Итоговый объём меньше нуля'
        case 'ERR_GA_11':
            return 'Изменение объёма истёкших АГ'
        case 'ERR_GA_12':
            return 'Зелёный сертификат уже существует'
        case 'ERR_GA_13':
            return 'Недостаточно свободных АГ'
        case 'ERR_GA_14':
            return 'Зелёный сертификат не найден'
        case 'ERR_GA_15':
            return 'Зелёный сертификат уже разделён или удалён'

        case 'ERR_BILL_1':
            return 'Нет ПС'
        case 'ERR_BILL_2':
            return 'Ошибка при получении данных документа'
        case 'ERR_BILL_3':
            return 'Пользователь не найден'
        case 'ERR_BILL_4':
            return 'Пользователь с ПС не найден'
        case 'ERR_BILL_5':
            return 'Ошибка при получении данных документа'
        case 'ERR_BILL_6':
            return 'Пользователь не найден'
        case 'ERR_BILL_7':
            return 'Пользователь с ПС не найден'

        case 'ERR_GO_1':
            return 'Укажите код ГТП используя: цифры, латинские буквы'
        case 'ERR_GO_2':
            return 'Указанный вами код ГТП уже используется в АС “СКИЗИ“'
        case 'ERR_GO_3':
            return 'ЭП невалидна'
        case 'ERR_GO_4':
            return 'не достаточно свободных АГ на счету'
        case 'ERR_GO_5':
            return 'нет данных по периоду (например период еще не посчитан или период из слишком далекого прошлого)'
        case 'ERR_GO_6':
            return 'выпуск ЗИ заблокирован'
        case 'ERR_GO_7':
            return 'АГ истекли по Сроку жизни'
        case 'ERR_GO_8':
            return 'Невозможно выполнить операцию, у вас нет оплаченного лицевого счета. Пожалуйста, оплатите лицевой счет и повторите попытку'
        case 'ERR_GO_9':
            return 'Невозможно выполнить операцию, у вас нет открытого лицевого счета. Пожалуйста, откройте лицевой счет и повторите попытку'
        case 'ERR_GO_10':
            return 'На вашем платежном счете недостаточно денежных средств для выполнения данной операции. Пожалуйста, пополните платежный счет и повторите попытку'
        case 'ERR_GO_11':
            return 'Невозможно согласовать т.к. указанный владелец ГО больше не в роли “Владелец ГО“'

        case 'ERR_FL_1':
            return 'ЭП невалидна'
        case 'ERR_FL_2':
            return 'Переподача невозможна. Уже есть согласованная подача.'
        case 'ERR_FL_3':
            return 'Время для согласования еще не наступило'
        case 'ERR_FL_4':
            return 'Время для Согласования уже прошло'
        case 'ERR_FL_5':
            return 'Переподача невозможна. Срок для переподачи истек.'
        case 'ERR_FL_6':
            return 'Подача невозможна. Срок для подачи истек.'
        case 'ERR_FL_7':
            return 'Подача невозможна. Обязательно наличие хотя бы одного файла.'
        case 'ERR_FL_8':
            return 'Невозможно согласовать, т.к. пользователь, подавший данные, заблокирован'

        case 'ERR_CA_1':
            return 'ЭП невалидна'
        case 'ERR_CA_2':
            return 'Не найдено'
        case 'ERR_CA_3':
            return 'Месяц, указанный в xml файле, не соответствует периоду подачи данных по КУ'
        case 'ERR_CA_4':
            return `Отправка данных по КУ доступна с 1 по ${errAttributes?.last_submit_day} число`
        case 'ERR_CA_5':
            return `Уже поданное значение ${errAttributes?.submitted_volume} День, час ${errAttributes?.date_hour} пересекается в ${errAttributes?.code} с новым значением ${errAttributes?.volume}`
        case 'ERR_CA_6':
            return `Суммарный объем прикрепленных файлов ${errAttributes?.size} не должен превышать размер ${errAttributes?.max_size}`
        case 'ERR_CA_7':
            return `Данные не приняты. Переподача данных возможна до ${errAttributes?.date}`
        case 'ERR_CA_8':
            return `Значение “date”, полученное из xml файла, не соответствует периоду подачи данных по КУ`
        case 'ERR_CA_9':
            return 'Период для согласования КУ еще не наступил'
        case 'ERR_CA_10':
            return 'Период для согласования уже прошел'
        case 'ERR_CA_11':
            return 'Неверная операция для текущего статуса'
        case 'ERR_CA_12':
            return 'Операция не выполнена, тк формат данных не соответствует регламенту'
        case 'ERR_CA_13':
            return 'Не добавлен дополнительный файл'
        case 'ERR_CA_14':
            return 'Не заполнено поле "Комментарий"'
        case 'ERR_CA_15':
            return 'Укажите комментарий длиной [1 -500] символов.'
        case 'ERR_CA_16':
            return 'Невозможно согласовать, т.к. пользователь, подавший пакет КУ, заблокирован'

        default:
            return 'Произошла ошибка, попробуйте повторить позднее'
    }
}
