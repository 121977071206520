import { Progress } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card, { CardSection, ESize } from '../../../ui/Card/Card'
import useRequest from '../../../hooks/useRequest'
import useMount from '../../../hooks/useMount'
import { currencyFormat } from '../../../helpers'
import { getGraphData } from './config'
import style from './style.m.less'

interface IProgressItem {
    label: string
    value: string
    count: string | number
    percent: number
    color: string
}

const ProgressItem: FC<IProgressItem> = ({ label, value, count, percent, color }) => {
    const { t } = useTranslation()

    return (
        <div className={style.progressItem}>
            <span className={style.label}>{label}</span>
            <div className={style.info}>
                <span className={style.value}>{value}</span>
                <span className={style.count}>
                    ({count.toLocaleString()} {t('main.pc')})
                </span>
            </div>
            <div className={style.progressWrap}>
                <Progress
                    percent={percent}
                    showInfo={false}
                    strokeColor={color}
                    className={style.progress}
                />
            </div>
        </div>
    )
}

const GreenToolProgress: FC = () => {
    const { t } = useTranslation()
    const [total, setTotal] = useState(0)
    const [percents, setPercents] = useState<any>()
    const { fetch, result } = useRequest(getGraphData)

    useMount(() => {
        fetch({
            onSuccess: (data) => {
                const totalCount =
                    data.release_cert_volume + data.green_tools_volume + data.external_tools_volume
                const certPercentage = Math.round((data.release_cert_volume / totalCount) * 100)
                const contractPercentage = Math.round((data.green_tools_volume / totalCount) * 100)
                const othersPercentage = Math.round((data.external_tools_volume / totalCount) * 100)
                setPercents({ certPercentage, contractPercentage, othersPercentage })
                setTotal(totalCount)
            },
        })
    })

    return (
        <Card size={ESize.N}>
            <CardSection size={ESize.L}>
                <span className={style.title}>{t('main.greenToolsCount')}</span>
            </CardSection>
            <CardSection size={ESize.L}>
                <ProgressItem
                    label={t('main.originCert')}
                    value={`${currencyFormat(result?.release_cert_volume)} ${t('main.scale')}`}
                    count={currencyFormat(result?.release_cert_count)}
                    percent={percents?.certPercentage}
                    color="#51D2A0"
                />
            </CardSection>
            <CardSection size={ESize.L}>
                <ProgressItem
                    label={t('main.greenDocs')}
                    value={`${currencyFormat(result?.green_tools_volume)} ${t('main.scale')}`}
                    count={currencyFormat(result?.green_tools_count)}
                    percent={percents?.contractPercentage}
                    color="#EFDBFF"
                />
            </CardSection>
            <CardSection size={ESize.L}>
                <ProgressItem
                    label={t('main.otherGreenTools')}
                    value={`${currencyFormat(result?.external_tools_volume)} ${t('main.scale')}`}
                    count={currencyFormat(result?.external_tools_count)}
                    percent={percents?.othersPercentage}
                    color="#EAFF8F"
                />
            </CardSection>
            <CardSection size={ESize.L}>
                <div className={style.total}>
                    <span className={style.totalLabel}>{t('main.total')}</span>
                    <span className={style.totalValue}>
                        {currencyFormat(total)} {t('main.scale')}
                    </span>
                </div>
            </CardSection>
        </Card>
    )
}

export default GreenToolProgress
